export const TableEnum = {
  SUMMARY_TABLE: "table_summary_table",
  AMOUNT_RECEIVABLE: "table_amount_receivable",
  CREDIT_LIMIT: "table_credit_limit",
  RISKY_CUSTOMER: "table_risky_customer",
  COLLECTION_TABLE: "table_collection_table",
  PROSPECT_SUMMARY_TABLE: "prospect_summary_table",
  PROSPECT_CREDIT_LIMIT: "prospect_credit_limit",
  PROSPECT_RISKY_CUSTOMER: "prospect_risky_customer",
  ALL_SUMMARY_TABLE: "all_summary_table",
  ALL_CREDIT_LIMIT: "all_credit_limit",
  ALL_RISKY_CUSTOMER: "all_risky_customer",
  COLLECTION_AT_RISK: "table_collection_at_risk",
  COLLECTION_TABLE_V2: "table_collection_table_v2",
};

export const AmountReceivableColumnModal = {
  "Recommended Limit": {
    metric: true,
    customer: true,
    total: true,
    customerRL0: true,
    totalRL0: true,
    customerRL50k: true,
    totalRL50k: true,
    customerRL100k: true,
    totalRL100k: true,
    customerRL150k: true,
    totalRL150k: true,
    customerRL200k: true,
    totalRL200k: true,
    customerRL250k: true,
    totalRL250k: true,
    customerRL300k: true,
    totalRL300k: true,
  },
};

export const CreditLimitColumnModal = {
  "Recommended Limit": {
    metric: true,
    total: true,
    totalRL0: true,
    totalRL50k: true,
    totalRL100k: true,
    totalRL150k: true,
    totalRL200k: true,
    totalRL250k: true,
    totalRL300k: true,
  },
};

export const RiskyCustomerColumnModal = {
  "Recommended Limit": {
    "Rank": true,
    "Company Name": true,
    "Total AR Balance": true,
    "Current AR Balance": true,
    "Recommended Limit": true,
    "Requested Limit": true,
    "AR Balance Aging 30": true,
    "AR Balance Aging 60": true,
    "AR Balance Aging 90": true,
    "AR Balance Aging 90+": true,
    "Risk Segment": true,
    "Failure Score": true,
    "Delinquency Score": true,
    "Alternative Score": true,
  },
};

export const CollectionTableColumnModal = {
  __tree_data_group__: true,
  totalAR: true,
  currentAR: true,
  aging30AR: true,
  aging60AR: true,
  aging90AR: true,
  aging90PlusAR: true,
}


