function formatNumber(value) {
  return isNaN(value) || !isFinite(value)
    ? "0"
    : Math.round(value).toLocaleString();
}

function checkFormat(value) {
  const numericValue = parseFloat(value);
  return !isNaN(numericValue) ? numericValue : 0;
}

export const getTotalForAmountReceivableRS = (data) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    aging30: 0,
    customerWithAging30: 0,
    aging60: 0,
    customerWithAging60: 0,
    aging90: 0,
    customerWithAging90: 0,
    aging90Plus: 0,
    customerWithAging90Plus: 0,

    totalRS2Point5: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRS5: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRS7Point5: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRS7Point5Greater: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
  };

  data.forEach((item) => {
    if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;
    }

    if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
      totals.aging30 += item["AR Balance Aging 30"];
      totals.customerWithAging30 += 1;
    }

    if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
      totals.aging60 += item["AR Balance Aging 60"];
      totals.customerWithAging60 += 1;
    }

    if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
      totals.aging90 += item["AR Balance Aging 90"];
      totals.customerWithAging90 += 1;
    }

    if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
      totals.aging90Plus += item["AR Balance Aging 90+"];
      totals.customerWithAging90Plus += 1;
    }

    if (item["Risk Segment"] <= 2.5 && item["Risk Segment"] >= 0) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRS2Point5.totalARBalance += item["Total AR Balance"];
        totals.totalRS2Point5.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalRS2Point5.currentARBalance += item["Current AR Balance"];
        totals.totalRS2Point5.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRS2Point5.aging30 += item["AR Balance Aging 30"];
        totals.totalRS2Point5.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRS2Point5.aging60 += item["AR Balance Aging 60"];
        totals.totalRS2Point5.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRS2Point5.aging90 += item["AR Balance Aging 90"];
        totals.totalRS2Point5.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRS2Point5.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRS2Point5.customerWithAging90Plus += 1;
      }
    }

    if (item["Risk Segment"] <= 5 && item["Risk Segment"] > 2.5) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRS5.totalARBalance += item["Total AR Balance"];
        totals.totalRS5.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalRS5.currentARBalance += item["Current AR Balance"];
        totals.totalRS5.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRS5.aging30 += item["AR Balance Aging 30"];
        totals.totalRS5.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRS5.aging60 += item["AR Balance Aging 60"];
        totals.totalRS5.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRS5.aging90 += item["AR Balance Aging 90"];
        totals.totalRS5.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRS5.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRS5.customerWithAging90Plus += 1;
      }
    }

    if (item["Risk Segment"] <= 7.5 && item["Risk Segment"] > 5) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRS7Point5.totalARBalance += item["Total AR Balance"];
        totals.totalRS7Point5.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalRS7Point5.currentARBalance += item["Current AR Balance"];
        totals.totalRS7Point5.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRS7Point5.aging30 += item["AR Balance Aging 30"];
        totals.totalRS7Point5.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRS7Point5.aging60 += item["AR Balance Aging 60"];
        totals.totalRS7Point5.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRS7Point5.aging90 += item["AR Balance Aging 90"];
        totals.totalRS7Point5.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRS7Point5.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRS7Point5.customerWithAging90Plus += 1;
      }
    }

    if (item["Risk Segment"] > 7.5) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRS7Point5Greater.totalARBalance += item["Total AR Balance"];
        totals.totalRS7Point5Greater.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalRS7Point5Greater.currentARBalance +=
          item["Current AR Balance"];
        totals.totalRS7Point5Greater.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRS7Point5Greater.aging30 += item["AR Balance Aging 30"];
        totals.totalRS7Point5Greater.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRS7Point5Greater.aging60 += item["AR Balance Aging 60"];
        totals.totalRS7Point5Greater.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRS7Point5Greater.aging90 += item["AR Balance Aging 90"];
        totals.totalRS7Point5Greater.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRS7Point5Greater.aging90Plus +=
          item["AR Balance Aging 90+"];
        totals.totalRS7Point5Greater.customerWithAging90Plus += 1;
      }
    }
  });

  const rowDataAR = [
    {
      id: 1,
      metric: "Total AR Balance",
      customer: formatNumber(totals.customerWithTotalAR),
      total: formatNumber(totals.totalARBalance),
      totalRS2Point5: formatNumber(totals.totalRS2Point5.totalARBalance),
      customerRS2Point5: formatNumber(totals.totalRS2Point5.count),
      totalRS5: formatNumber(totals.totalRS5.totalARBalance),
      customerRS5: formatNumber(totals.totalRS5.count),
      totalRS7Point5: formatNumber(totals.totalRS7Point5.totalARBalance),
      customerRS7Point5: formatNumber(totals.totalRS7Point5.count),
      totalRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.totalARBalance
      ),
      customerRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.count
      ),
    },
    {
      id: 2,
      metric: "Current AR Balance",
      customer: formatNumber(totals.customerWithCurrentAR),
      total: formatNumber(totals.currentARBalance),
      totalRS2Point5: formatNumber(totals.totalRS2Point5.currentARBalance),
      customerRS2Point5: formatNumber(
        totals.totalRS2Point5.customerWithCurrentAR
      ),
      totalRS5: formatNumber(totals.totalRS5.currentARBalance),
      customerRS5: formatNumber(totals.totalRS5.customerWithCurrentAR),
      totalRS7Point5: formatNumber(totals.totalRS7Point5.currentARBalance),
      customerRS7Point5: formatNumber(
        totals.totalRS7Point5.customerWithCurrentAR
      ),
      totalRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.currentARBalance
      ),
      customerRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.customerWithCurrentAR
      ),
    },
    {
      id: 3,
      metric: "AR Balance Aging 30",
      customer: formatNumber(totals.customerWithAging30),
      total: formatNumber(totals.aging30),
      totalRS2Point5: formatNumber(totals.totalRS2Point5.aging30),
      customerRS2Point5: formatNumber(
        totals.totalRS2Point5.customerWithAging30
      ),
      totalRS5: formatNumber(totals.totalRS5.aging30),
      customerRS5: formatNumber(totals.totalRS5.customerWithAging30),
      totalRS7Point5: formatNumber(totals.totalRS7Point5.aging30),
      customerRS7Point5: formatNumber(
        totals.totalRS7Point5.customerWithAging30
      ),
      totalRS7Point5Greater: formatNumber(totals.totalRS7Point5Greater.aging30),
      customerRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.customerWithAging30
      ),
    },
    {
      id: 4,
      metric: "AR Balance Aging 60",
      customer: formatNumber(totals.customerWithAging60),
      total: formatNumber(totals.aging60),
      totalRS2Point5: formatNumber(totals.totalRS2Point5.aging60),
      customerRS2Point5: formatNumber(
        totals.totalRS2Point5.customerWithAging60
      ),
      totalRS5: formatNumber(totals.totalRS5.aging60),
      customerRS5: formatNumber(totals.totalRS5.customerWithAging60),
      totalRS7Point5: formatNumber(totals.totalRS7Point5.aging60),
      customerRS7Point5: formatNumber(
        totals.totalRS7Point5.customerWithAging60
      ),
      totalRS7Point5Greater: formatNumber(totals.totalRS7Point5Greater.aging60),
      customerRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.customerWithAging60
      ),
    },
    {
      id: 5,
      metric: "AR Balance Aging 90",
      customer: formatNumber(totals.customerWithAging90),
      total: formatNumber(totals.aging90),
      totalRS2Point5: formatNumber(totals.totalRS2Point5.aging90),
      customerRS2Point5: formatNumber(
        totals.totalRS2Point5.customerWithAging90
      ),
      totalRS5: formatNumber(totals.totalRS5.aging90),
      customerRS5: formatNumber(totals.totalRS5.customerWithAging90),
      totalRS7Point5: formatNumber(totals.totalRS7Point5.aging90),
      customerRS7Point5: formatNumber(
        totals.totalRS7Point5.customerWithAging90
      ),
      totalRS7Point5Greater: formatNumber(totals.totalRS7Point5Greater.aging90),
      customerRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.customerWithAging90
      ),
    },
    {
      id: 6,
      metric: "AR Balance Aging 90+",
      customer: formatNumber(totals.customerWithAging90Plus),
      total: formatNumber(totals.aging90Plus),
      totalRS2Point5: formatNumber(totals.totalRS2Point5.aging90Plus),
      customerRS2Point5: formatNumber(
        totals.totalRS2Point5.customerWithAging90Plus
      ),
      totalRS5: formatNumber(totals.totalRS5.aging90Plus),
      customerRS5: formatNumber(totals.totalRS5.customerWithAging90Plus),
      totalRS7Point5: formatNumber(totals.totalRS7Point5.aging90Plus),
      customerRS7Point5: formatNumber(
        totals.totalRS7Point5.customerWithAging90Plus
      ),
      totalRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.aging90Plus
      ),
      customerRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.customerWithAging90Plus
      ),
    },
  ];

  return rowDataAR;
};

export const getTotalForAmountReceivableCA = (data, segment) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    aging30: 0,
    customerWithAging30: 0,
    aging60: 0,
    customerWithAging60: 0,
    aging90: 0,
    customerWithAging90: 0,
    aging90Plus: 0,
    customerWithAging90Plus: 0,

    lessThanEqualZero: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    greaterThanZero: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
  };

  data.forEach((item) => {
    if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;
    }

    if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
      totals.aging30 += item["AR Balance Aging 30"];
      totals.customerWithAging30 += 1;
    }

    if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
      totals.aging60 += item["AR Balance Aging 60"];
      totals.customerWithAging60 += 1;
    }

    if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
      totals.aging90 += item["AR Balance Aging 90"];
      totals.customerWithAging90 += 1;
    }

    if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
      totals.aging90Plus += item["AR Balance Aging 90+"];
      totals.customerWithAging90Plus += 1;
    }

    let comparisonVal;

    if (segment === "combined") {
      comparisonVal =
        checkFormat(item["AR Balance Aging 30"]) +
        checkFormat(item["AR Balance Aging 60"]) +
        checkFormat(item["AR Balance Aging 90"]) +
        checkFormat(item["AR Balance Aging 90+"]);
    } else {
      comparisonVal = item[segment];
    }

    if (comparisonVal <= 0) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.lessThanEqualZero.totalARBalance += item["Total AR Balance"];
        totals.lessThanEqualZero.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.lessThanEqualZero.currentARBalance += item["Current AR Balance"];
        totals.lessThanEqualZero.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.lessThanEqualZero.aging30 += item["AR Balance Aging 30"];
        totals.lessThanEqualZero.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.lessThanEqualZero.aging60 += item["AR Balance Aging 60"];
        totals.lessThanEqualZero.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.lessThanEqualZero.aging90 += item["AR Balance Aging 90"];
        totals.lessThanEqualZero.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.lessThanEqualZero.aging90Plus += item["AR Balance Aging 90+"];
        totals.lessThanEqualZero.customerWithAging90Plus += 1;
      }
    }

    if (comparisonVal > 0) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.greaterThanZero.totalARBalance += item["Total AR Balance"];
        totals.greaterThanZero.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.greaterThanZero.currentARBalance += item["Current AR Balance"];
        totals.greaterThanZero.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.greaterThanZero.aging30 += item["AR Balance Aging 30"];
        totals.greaterThanZero.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.greaterThanZero.aging60 += item["AR Balance Aging 60"];
        totals.greaterThanZero.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.greaterThanZero.aging90 += item["AR Balance Aging 90"];
        totals.greaterThanZero.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.greaterThanZero.aging90Plus += item["AR Balance Aging 90+"];
        totals.greaterThanZero.customerWithAging90Plus += 1;
      }
    }
  });

  const rowDataAR = [
    {
      id: 1,
      metric: "Total AR Balance",
      customer: formatNumber(totals.customerWithTotalAR),
      total: formatNumber(Math.round(totals.totalARBalance)),
      totalCALessThanEqualZero: formatNumber(
        Math.round(totals.lessThanEqualZero.totalARBalance)
      ),
      customerCALessThanEqualZero: formatNumber(
        Math.round(totals.lessThanEqualZero.count)
      ),
      totalCAGreaterThanZero: formatNumber(
        Math.round(totals.greaterThanZero.totalARBalance)
      ),
      customerCAGreaterThanZero: formatNumber(
        Math.round(totals.greaterThanZero.count)
      ),
    },
    {
      id: 2,
      metric: "Current AR Balance",
      customer: formatNumber(Math.round(totals.customerWithCurrentAR)),
      total: formatNumber(Math.round(totals.currentARBalance)),
      totalCALessThanEqualZero: formatNumber(
        Math.round(totals.lessThanEqualZero.currentARBalance)
      ),
      customerCALessThanEqualZero: formatNumber(
        Math.round(totals.lessThanEqualZero.customerWithCurrentAR)
      ),
      totalCAGreaterThanZero: formatNumber(
        Math.round(totals.greaterThanZero.currentARBalance)
      ),
      customerCAGreaterThanZero: formatNumber(
        Math.round(totals.greaterThanZero.customerWithCurrentAR)
      ),
    },
    {
      id: 3,
      metric: "AR Balance Aging 30",
      customer: formatNumber(Math.round(totals.customerWithAging30)),
      total: formatNumber(Math.round(totals.aging30)),
      totalCALessThanEqualZero: formatNumber(
        Math.round(totals.lessThanEqualZero.aging30)
      ),
      customerCALessThanEqualZero: formatNumber(
        Math.round(totals.lessThanEqualZero.customerWithAging30)
      ),
      totalCAGreaterThanZero: formatNumber(
        Math.round(totals.greaterThanZero.aging30)
      ),
      customerCAGreaterThanZero: formatNumber(
        Math.round(totals.greaterThanZero.customerWithAging30)
      ),
    },
    {
      id: 4,
      metric: "AR Balance Aging 60",
      customer: formatNumber(Math.round(totals.customerWithAging60)),
      total: formatNumber(Math.round(totals.aging60)),
      totalCALessThanEqualZero: formatNumber(
        Math.round(totals.lessThanEqualZero.aging60)
      ),
      customerCALessThanEqualZero: formatNumber(
        Math.round(totals.lessThanEqualZero.customerWithAging60)
      ),
      totalCAGreaterThanZero: formatNumber(
        Math.round(totals.greaterThanZero.aging60)
      ),
      customerCAGreaterThanZero: formatNumber(
        Math.round(totals.greaterThanZero.customerWithAging60)
      ),
    },
    {
      id: 5,
      metric: "AR Balance Aging 90",
      customer: formatNumber(Math.round(totals.customerWithAging90)),
      total: formatNumber(Math.round(totals.aging90)),
      totalCALessThanEqualZero: formatNumber(
        Math.round(totals.lessThanEqualZero.aging90)
      ),
      customerCALessThanEqualZero: formatNumber(
        Math.round(totals.lessThanEqualZero.customerWithAging90)
      ),
      totalCAGreaterThanZero: formatNumber(
        Math.round(totals.greaterThanZero.aging90)
      ),
      customerCAGreaterThanZero: formatNumber(
        Math.round(totals.greaterThanZero.customerWithAging90)
      ),
    },
    {
      id: 6,
      metric: "AR Balance Aging 90+",
      customer: formatNumber(Math.round(totals.customerWithAging90Plus)),
      total: formatNumber(Math.round(totals.aging90Plus)),
      totalCALessThanEqualZero: formatNumber(
        Math.round(totals.lessThanEqualZero.aging90Plus)
      ),
      customerCALessThanEqualZero: formatNumber(
        Math.round(totals.lessThanEqualZero.customerWithAging90Plus)
      ),
      totalCAGreaterThanZero: formatNumber(
        Math.round(totals.greaterThanZero.aging90Plus)
      ),
      customerCAGreaterThanZero: formatNumber(
        Math.round(totals.greaterThanZero.customerWithAging90Plus)
      ),
    },
  ];

  return rowDataAR;
};

export const getTotalForAmountReceivableRL = (data) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    aging30: 0,
    customerWithAging30: 0,
    aging60: 0,
    customerWithAging60: 0,
    aging90: 0,
    customerWithAging90: 0,
    aging90Plus: 0,
    customerWithAging90Plus: 0,

    totalRL0: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRL50k: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRL100k: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRL150k: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRL200k: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRL250k: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRL300k: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
  };

  data.forEach((item) => {
    if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;
    }

    if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0 ) {
      totals.aging30 += item["AR Balance Aging 30"];
      totals.customerWithAging30 += 1;
    }

    if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
      totals.aging60 += item["AR Balance Aging 60"];
      totals.customerWithAging60 += 1;
    }

    if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
      totals.aging90 += item["AR Balance Aging 90"];
      totals.customerWithAging90 += 1;
    }

    if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
      totals.aging90Plus += item["AR Balance Aging 90+"];
      totals.customerWithAging90Plus += 1;
    }

    if (item["Recommended Limit"] < 50000 && item["Recommended Limit"] >= 0) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0 ) {
        totals.totalRL0.totalARBalance += item["Total AR Balance"];
        totals.totalRL0.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalRL0.currentARBalance += item["Current AR Balance"];
        totals.totalRL0.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0 ) {
        totals.totalRL0.aging30 += item["AR Balance Aging 30"];
        totals.totalRL0.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRL0.aging60 += item["AR Balance Aging 60"];
        totals.totalRL0.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRL0.aging90 += item["AR Balance Aging 90"];
        totals.totalRL0.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRL0.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRL0.customerWithAging90Plus += 1;
      }
    }

    if (
      item["Recommended Limit"] < 100000 &&
      item["Recommended Limit"] >= 50000
    ) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRL50k.totalARBalance += item["Total AR Balance"];
        totals.totalRL50k.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalRL50k.currentARBalance += item["Current AR Balance"];
        totals.totalRL50k.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRL50k.aging30 += item["AR Balance Aging 30"];
        totals.totalRL50k.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRL50k.aging60 += item["AR Balance Aging 60"];
        totals.totalRL50k.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRL50k.aging90 += item["AR Balance Aging 90"];
        totals.totalRL50k.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRL50k.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRL50k.customerWithAging90Plus += 1;
      }
    }

    if (
      item["Recommended Limit"] < 150000 &&
      item["Recommended Limit"] >= 100000
    ) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRL100k.totalARBalance += item["Total AR Balance"];
        totals.totalRL100k.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalRL100k.currentARBalance += item["Current AR Balance"];
        totals.totalRL100k.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRL100k.aging30 += item["AR Balance Aging 30"];
        totals.totalRL100k.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRL100k.aging60 += item["AR Balance Aging 60"];
        totals.totalRL100k.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRL100k.aging90 += item["AR Balance Aging 90"];
        totals.totalRL100k.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRL100k.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRL100k.customerWithAging90Plus += 1;
      }
    }

    if (
      item["Recommended Limit"] < 200000 &&
      item["Recommended Limit"] >= 150000
    ) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRL150k.totalARBalance += item["Total AR Balance"];
        totals.totalRL150k.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalRL150k.currentARBalance += item["Current AR Balance"];
        totals.totalRL150k.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRL150k.aging30 += item["AR Balance Aging 30"];
        totals.totalRL150k.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRL150k.aging60 += item["AR Balance Aging 60"];
        totals.totalRL150k.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRL150k.aging90 += item["AR Balance Aging 90"];
        totals.totalRL150k.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRL150k.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRL150k.customerWithAging90Plus += 1;
      }
    }

    if (
      item["Recommended Limit"] < 250000 &&
      item["Recommended Limit"] >= 200000
    ) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRL200k.totalARBalance += item["Total AR Balance"];
        totals.totalRL200k.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalRL200k.currentARBalance += item["Current AR Balance"];
        totals.totalRL200k.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRL200k.aging30 += item["AR Balance Aging 30"];
        totals.totalRL200k.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRL200k.aging60 += item["AR Balance Aging 60"];
        totals.totalRL200k.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRL200k.aging90 += item["AR Balance Aging 90"];
        totals.totalRL200k.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRL200k.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRL200k.customerWithAging90Plus += 1;
      }
    }

    if (
      item["Recommended Limit"] < 300000 &&
      item["Recommended Limit"] >= 250000
    ) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRL250k.totalARBalance += item["Total AR Balance"];
        totals.totalRL250k.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalRL250k.currentARBalance += item["Current AR Balance"];
        totals.totalRL250k.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRL250k.aging30 += item["AR Balance Aging 30"];
        totals.totalRL250k.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRL250k.aging60 += item["AR Balance Aging 60"];
        totals.totalRL250k.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRL250k.aging90 += item["AR Balance Aging 90"];
        totals.totalRL250k.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRL250k.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRL250k.customerWithAging90Plus += 1;
      }
    }

    if (item["Recommended Limit"] >= 300000) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRL300k.totalARBalance += item["Total AR Balance"];
        totals.totalRL300k.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalRL300k.currentARBalance += item["Current AR Balance"];
        totals.totalRL300k.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRL300k.aging30 += item["AR Balance Aging 30"];
        totals.totalRL300k.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRL300k.aging60 += item["AR Balance Aging 60"];
        totals.totalRL300k.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRL300k.aging90 += item["AR Balance Aging 90"];
        totals.totalRL300k.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRL300k.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRL300k.customerWithAging90Plus += 1;
      }
    }
  });

  const rowDataAR = [
    {
      id: 1,
      metric: "Total AR Balance",
      customer: formatNumber(Math.round(totals.customerWithTotalAR)),
      total: formatNumber(Math.round(totals.totalARBalance)),
      totalRL0: formatNumber(Math.round(totals.totalRL0.totalARBalance)),
      customerRL0: formatNumber(Math.round(totals.totalRL0.count)),
      totalRL50k: formatNumber(Math.round(totals.totalRL50k.totalARBalance)),
      customerRL50k: formatNumber(Math.round(totals.totalRL50k.count)),
      totalRL100k: formatNumber(Math.round(totals.totalRL100k.totalARBalance)),
      customerRL100k: formatNumber(Math.round(totals.totalRL100k.count)),
      totalRL150k: formatNumber(Math.round(totals.totalRL150k.totalARBalance)),
      customerRL150k: formatNumber(Math.round(totals.totalRL150k.count)),
      totalRL200k: formatNumber(Math.round(totals.totalRL200k.totalARBalance)),
      customerRL200k: formatNumber(Math.round(totals.totalRL200k.count)),
      totalRL250k: formatNumber(Math.round(totals.totalRL250k.totalARBalance)),
      customerRL250k: formatNumber(Math.round(totals.totalRL250k.count)),
      totalRL300k: formatNumber(Math.round(totals.totalRL300k.totalARBalance)),
      customerRL300k: formatNumber(Math.round(totals.totalRL300k.count)),
    },
    {
      id: 2,
      metric: "Current AR Balance",
      customer: formatNumber(Math.round(totals.customerWithCurrentAR)),
      total: formatNumber(Math.round(totals.currentARBalance)),
      totalRL0: formatNumber(Math.round(totals.totalRL0.currentARBalance)),
      customerRL0: formatNumber(
        Math.round(totals.totalRL0.customerWithCurrentAR)
      ),
      totalRL50k: formatNumber(Math.round(totals.totalRL50k.currentARBalance)),
      customerRL50k: formatNumber(
        Math.round(totals.totalRL50k.customerWithCurrentAR)
      ),
      totalRL100k: formatNumber(
        Math.round(totals.totalRL100k.currentARBalance)
      ),
      customerRL100k: formatNumber(
        Math.round(totals.totalRL100k.customerWithCurrentAR)
      ),
      totalRL150k: formatNumber(
        Math.round(totals.totalRL150k.currentARBalance)
      ),
      customerRL150k: formatNumber(
        Math.round(totals.totalRL150k.customerWithCurrentAR)
      ),
      totalRL200k: formatNumber(
        Math.round(totals.totalRL200k.currentARBalance)
      ),
      customerRL200k: formatNumber(
        Math.round(totals.totalRL200k.customerWithCurrentAR)
      ),
      totalRL250k: formatNumber(
        Math.round(totals.totalRL250k.currentARBalance)
      ),
      customerRL250k: formatNumber(
        Math.round(totals.totalRL250k.customerWithCurrentAR)
      ),
      totalRL300k: formatNumber(
        Math.round(totals.totalRL300k.currentARBalance)
      ),
      customerRL300k: formatNumber(
        Math.round(totals.totalRL300k.customerWithCurrentAR)
      ),
    },
    {
      id: 3,
      metric: "AR Balance Aging 30",
      customer: formatNumber(Math.round(totals.customerWithAging30)),
      total: formatNumber(Math.round(totals.aging30)),
      totalRL0: formatNumber(Math.round(totals.totalRL0.aging30)),
      customerRL0: formatNumber(
        Math.round(totals.totalRL0.customerWithAging30)
      ),
      totalRL50k: formatNumber(Math.round(totals.totalRL50k.aging30)),
      customerRL50k: formatNumber(
        Math.round(totals.totalRL50k.customerWithAging30)
      ),
      totalRL100k: formatNumber(Math.round(totals.totalRL100k.aging30)),
      customerRL100k: formatNumber(
        Math.round(totals.totalRL100k.customerWithAging30)
      ),
      totalRL150k: formatNumber(Math.round(totals.totalRL150k.aging30)),
      customerRL150k: formatNumber(
        Math.round(totals.totalRL150k.customerWithAging30)
      ),
      totalRL200k: formatNumber(Math.round(totals.totalRL200k.aging30)),
      customerRL200k: formatNumber(
        Math.round(totals.totalRL200k.customerWithAging30)
      ),
      totalRL250k: formatNumber(Math.round(totals.totalRL250k.aging30)),
      customerRL250k: formatNumber(
        Math.round(totals.totalRL250k.customerWithAging30)
      ),
      totalRL300k: formatNumber(Math.round(totals.totalRL300k.aging30)),
      customerRL300k: formatNumber(
        Math.round(totals.totalRL300k.customerWithAging30)
      ),
    },
    {
      id: 4,
      metric: "AR Balance Aging 60",
      customer: formatNumber(Math.round(totals.customerWithAging60)),
      total: formatNumber(Math.round(totals.aging60)),
      totalRL0: formatNumber(Math.round(totals.totalRL0.aging60)),
      customerRL0: formatNumber(
        Math.round(totals.totalRL0.customerWithAging60)
      ),
      totalRL50k: formatNumber(Math.round(totals.totalRL50k.aging60)),
      customerRL50k: formatNumber(
        Math.round(totals.totalRL50k.customerWithAging60)
      ),
      totalRL100k: formatNumber(Math.round(totals.totalRL100k.aging60)),
      customerRL100k: formatNumber(
        Math.round(totals.totalRL100k.customerWithAging60)
      ),
      totalRL150k: formatNumber(Math.round(totals.totalRL150k.aging60)),
      customerRL150k: formatNumber(
        Math.round(totals.totalRL150k.customerWithAging60)
      ),
      totalRL200k: formatNumber(Math.round(totals.totalRL200k.aging60)),
      customerRL200k: formatNumber(
        Math.round(totals.totalRL200k.customerWithAging60)
      ),
      totalRL250k: formatNumber(Math.round(totals.totalRL250k.aging60)),
      customerRL250k: formatNumber(
        Math.round(totals.totalRL250k.customerWithAging60)
      ),
      totalRL300k: formatNumber(Math.round(totals.totalRL300k.aging60)),
      customerRL300k: formatNumber(
        Math.round(totals.totalRL300k.customerWithAging60)
      ),
    },
    {
      id: 5,
      metric: "AR Balance Aging 90",
      customer: formatNumber(Math.round(totals.customerWithAging90)),
      total: formatNumber(Math.round(totals.aging90)),
      totalRL0: formatNumber(Math.round(totals.totalRL0.aging90)),
      customerRL0: formatNumber(
        Math.round(totals.totalRL0.customerWithAging90)
      ),
      totalRL50k: formatNumber(Math.round(totals.totalRL50k.aging90)),
      customerRL50k: formatNumber(
        Math.round(totals.totalRL50k.customerWithAging90)
      ),
      totalRL100k: formatNumber(Math.round(totals.totalRL100k.aging90)),
      customerRL100k: formatNumber(
        Math.round(totals.totalRL100k.customerWithAging90)
      ),
      totalRL150k: formatNumber(Math.round(totals.totalRL150k.aging90)),
      customerRL150k: formatNumber(
        Math.round(totals.totalRL150k.customerWithAging90)
      ),
      totalRL200k: formatNumber(Math.round(totals.totalRL200k.aging90)),
      customerRL200k: formatNumber(
        Math.round(totals.totalRL200k.customerWithAging90)
      ),
      totalRL250k: formatNumber(Math.round(totals.totalRL250k.aging90)),
      customerRL250k: formatNumber(
        Math.round(totals.totalRL250k.customerWithAging90)
      ),
      totalRL300k: formatNumber(Math.round(totals.totalRL300k.aging90)),
      customerRL300k: formatNumber(
        Math.round(totals.totalRL300k.customerWithAging90)
      ),
    },
    {
      id: 6,
      metric: "AR Balance Aging 90+",
      customer: formatNumber(Math.round(totals.customerWithAging90Plus)),
      total: formatNumber(Math.round(totals.aging90Plus)),
      totalRL0: formatNumber(Math.round(totals.totalRL0.aging90Plus)),
      customerRL0: formatNumber(
        Math.round(totals.totalRL0.customerWithAging90Plus)
      ),
      totalRL50k: formatNumber(Math.round(totals.totalRL50k.aging90Plus)),
      customerRL50k: formatNumber(
        Math.round(totals.totalRL50k.customerWithAging90Plus)
      ),
      totalRL100k: formatNumber(Math.round(totals.totalRL100k.aging90Plus)),
      customerRL100k: formatNumber(
        Math.round(totals.totalRL100k.customerWithAging90Plus)
      ),
      totalRL150k: formatNumber(Math.round(totals.totalRL150k.aging90Plus)),
      customerRL150k: formatNumber(
        Math.round(totals.totalRL150k.customerWithAging90Plus)
      ),
      totalRL200k: formatNumber(Math.round(totals.totalRL200k.aging90Plus)),
      customerRL200k: formatNumber(
        Math.round(totals.totalRL200k.customerWithAging90Plus)
      ),
      totalRL250k: formatNumber(Math.round(totals.totalRL250k.aging90Plus)),
      customerRL250k: formatNumber(
        Math.round(totals.totalRL250k.customerWithAging90Plus)
      ),
      totalRL300k: formatNumber(Math.round(totals.totalRL300k.aging90Plus)),
      customerRL300k: formatNumber(
        Math.round(totals.totalRL300k.customerWithAging90Plus)
      ),
    },
  ];

  return rowDataAR;
};

export const getTotalForAmountReceivableCARBOrCL = (data, segment) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    aging30: 0,
    customerWithAging30: 0,
    aging60: 0,
    customerWithAging60: 0,
    aging90: 0,
    customerWithAging90: 0,
    aging90Plus: 0,
    customerWithAging90Plus: 0,

    totalCARB20K: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalCARB50K: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalCARB150K: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalCARB250K: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalCARB500K: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalCARB500KGreater: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
  };

  data.forEach((item) => {
    if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;
    }

    if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
      totals.aging30 += item["AR Balance Aging 30"];
      totals.customerWithAging30 += 1;
    }

    if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
      totals.aging60 += item["AR Balance Aging 60"];
      totals.customerWithAging60 += 1;
    }

    if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
      totals.aging90 += item["AR Balance Aging 90"];
      totals.customerWithAging90 += 1;
    }

    if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
      totals.aging90Plus += item["AR Balance Aging 90+"];
      totals.customerWithAging90Plus += 1;
    }

    if (item[segment] < 20000 && item[segment] >= 0) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalCARB20K.totalARBalance += item["Total AR Balance"];
        totals.totalCARB20K.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalCARB20K.currentARBalance += item["Current AR Balance"];
        totals.totalCARB20K.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalCARB20K.aging30 += item["AR Balance Aging 30"];
        totals.totalCARB20K.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalCARB20K.aging60 += item["AR Balance Aging 60"];
        totals.totalCARB20K.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalCARB20K.aging90 += item["AR Balance Aging 90"];
        totals.totalCARB20K.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalCARB20K.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalCARB20K.customerWithAging90Plus += 1;
      }
    }

    if (item[segment] < 50000 && item[segment] >= 20000) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalCARB50K.totalARBalance += item["Total AR Balance"];
        totals.totalCARB50K.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalCARB50K.currentARBalance += item["Current AR Balance"];
        totals.totalCARB50K.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalCARB50K.aging30 += item["AR Balance Aging 30"];
        totals.totalCARB50K.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalCARB50K.aging60 += item["AR Balance Aging 60"];
        totals.totalCARB50K.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalCARB50K.aging90 += item["AR Balance Aging 90"];
        totals.totalCARB50K.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalCARB50K.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalCARB50K.customerWithAging90Plus += 1;
      }
    }

    if (item[segment] < 150000 && item[segment] >= 50000) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalCARB150K.totalARBalance += item["Total AR Balance"];
        totals.totalCARB150K.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalCARB150K.currentARBalance += item["Current AR Balance"];
        totals.totalCARB150K.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalCARB150K.aging30 += item["AR Balance Aging 30"];
        totals.totalCARB150K.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalCARB150K.aging60 += item["AR Balance Aging 60"];
        totals.totalCARB150K.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalCARB150K.aging90 += item["AR Balance Aging 90"];
        totals.totalCARB150K.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalCARB150K.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalCARB150K.customerWithAging90Plus += 1;
      }
    }

    if (item[segment] < 250000 && item[segment] >= 150000) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalCARB250K.totalARBalance += item["Total AR Balance"];
        totals.totalCARB250K.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalCARB250K.currentARBalance += item["Current AR Balance"];
        totals.totalCARB250K.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalCARB250K.aging30 += item["AR Balance Aging 30"];
        totals.totalCARB250K.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalCARB250K.aging60 += item["AR Balance Aging 60"];
        totals.totalCARB250K.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalCARB250K.aging90 += item["AR Balance Aging 90"];
        totals.totalCARB250K.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalCARB250K.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalCARB250K.customerWithAging90Plus += 1;
      }
    }

    if (item[segment] < 500000 && item[segment] >= 250000) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalCARB500K.totalARBalance += item["Total AR Balance"];
        totals.totalCARB500K.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalCARB500K.currentARBalance += item["Current AR Balance"];
        totals.totalCARB500K.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalCARB500K.aging30 += item["AR Balance Aging 30"];
        totals.totalCARB500K.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalCARB500K.aging60 += item["AR Balance Aging 60"];
        totals.totalCARB500K.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalCARB500K.aging90 += item["AR Balance Aging 90"];
        totals.totalCARB500K.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalCARB500K.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalCARB500K.customerWithAging90Plus += 1;
      }
    }

    if (item[segment] > 500000) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalCARB500KGreater.totalARBalance += item["Total AR Balance"];
        totals.totalCARB500KGreater.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalCARB500KGreater.currentARBalance +=
          item["Current AR Balance"];
        totals.totalCARB500KGreater.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalCARB500KGreater.aging30 += item["AR Balance Aging 30"];
        totals.totalCARB500KGreater.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalCARB500KGreater.aging60 += item["AR Balance Aging 60"];
        totals.totalCARB500KGreater.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalCARB500KGreater.aging90 += item["AR Balance Aging 90"];
        totals.totalCARB500KGreater.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalCARB500KGreater.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalCARB500KGreater.customerWithAging90Plus += 1;
      }
    }
  });

  const rowDataAR = [
    {
      id: 1,
      metric: "Total AR Balance",
      customer: formatNumber(Math.round(totals.customerWithTotalAR)),
      total: formatNumber(Math.round(totals.totalARBalance)),
      totalCARB20K: formatNumber(
        Math.round(totals.totalCARB20K.totalARBalance)
      ),
      customerCARB20K: formatNumber(Math.round(totals.totalCARB20K.count)),
      totalCARB50K: formatNumber(
        Math.round(totals.totalCARB50K.totalARBalance)
      ),
      customerCARB50K: formatNumber(Math.round(totals.totalCARB50K.count)),
      totalCARB150K: formatNumber(
        Math.round(totals.totalCARB150K.totalARBalance)
      ),
      customerCARB150K: formatNumber(Math.round(totals.totalCARB150K.count)),
      totalCARB250K: formatNumber(
        Math.round(totals.totalCARB250K.totalARBalance)
      ),
      customerCARB250K: formatNumber(Math.round(totals.totalCARB250K.count)),
      totalCARB500K: formatNumber(
        Math.round(totals.totalCARB500K.totalARBalance)
      ),
      customerCARB500K: formatNumber(Math.round(totals.totalCARB500K.count)),
      totalCARB500KGreater: formatNumber(
        Math.round(totals.totalCARB500KGreater.totalARBalance)
      ),
      customerCARB500KGreater: formatNumber(
        Math.round(totals.totalCARB500KGreater.count)
      ),
    },
    {
      id: 2,
      metric: "Current AR Balance",
      customer: formatNumber(Math.round(totals.customerWithCurrentAR)),
      total: formatNumber(Math.round(totals.currentARBalance)),
      totalCARB20K: formatNumber(
        Math.round(totals.totalCARB20K.currentARBalance)
      ),
      customerCARB20K: formatNumber(
        Math.round(totals.totalCARB20K.customerWithCurrentAR)
      ),
      totalCARB50K: formatNumber(
        Math.round(totals.totalCARB50K.currentARBalance)
      ),
      customerCARB50K: formatNumber(
        Math.round(totals.totalCARB50K.customerWithCurrentAR)
      ),
      totalCARB150K: formatNumber(
        Math.round(totals.totalCARB150K.currentARBalance)
      ),
      customerCARB150K: formatNumber(
        Math.round(totals.totalCARB150K.customerWithCurrentAR)
      ),
      totalCARB250K: formatNumber(
        Math.round(totals.totalCARB250K.currentARBalance)
      ),
      customerCARB250K: formatNumber(
        Math.round(totals.totalCARB250K.customerWithCurrentAR)
      ),
      totalCARB500K: formatNumber(
        Math.round(totals.totalCARB500K.currentARBalance)
      ),
      customerCARB500K: formatNumber(
        Math.round(totals.totalCARB500K.customerWithCurrentAR)
      ),
      totalCARB500KGreater: formatNumber(
        Math.round(totals.totalCARB500KGreater.currentARBalance)
      ),
      customerCARB500KGreater: formatNumber(
        Math.round(totals.totalCARB500KGreater.customerWithCurrentAR)
      ),
    },
    {
      id: 3,
      metric: "AR Balance Aging 30",
      customer: formatNumber(Math.round(totals.customerWithAging30)),
      total: formatNumber(Math.round(totals.aging30)),
      totalCARB20K: formatNumber(Math.round(totals.totalCARB20K.aging30)),
      customerCARB20K: formatNumber(
        Math.round(totals.totalCARB20K.customerWithAging30)
      ),
      totalCARB50K: formatNumber(Math.round(totals.totalCARB50K.aging30)),
      customerCARB50K: formatNumber(
        Math.round(totals.totalCARB50K.customerWithAging30)
      ),
      totalCARB150K: formatNumber(Math.round(totals.totalCARB150K.aging30)),
      customerCARB150K: formatNumber(
        Math.round(totals.totalCARB150K.customerWithAging30)
      ),
      totalCARB250K: formatNumber(Math.round(totals.totalCARB250K.aging30)),
      customerCARB250K: formatNumber(
        Math.round(totals.totalCARB250K.customerWithAging30)
      ),
      totalCARB500K: formatNumber(Math.round(totals.totalCARB500K.aging30)),
      customerCARB500K: formatNumber(
        Math.round(totals.totalCARB500K.customerWithAging30)
      ),
      totalCARB500KGreater: formatNumber(
        Math.round(totals.totalCARB500KGreater.aging30)
      ),
      customerCARB500KGreater: formatNumber(
        Math.round(totals.totalCARB500KGreater.customerWithAging30)
      ),
    },
    {
      id: 4,
      metric: "AR Balance Aging 60",
      customer: formatNumber(Math.round(totals.customerWithAging60)),
      total: formatNumber(Math.round(totals.aging60)),
      totalCARB20K: formatNumber(Math.round(totals.totalCARB20K.aging60)),
      customerCARB20K: formatNumber(
        Math.round(totals.totalCARB20K.customerWithAging60)
      ),
      totalCARB50K: formatNumber(Math.round(totals.totalCARB50K.aging60)),
      customerCARB50K: formatNumber(
        Math.round(totals.totalCARB50K.customerWithAging60)
      ),
      totalCARB150K: formatNumber(Math.round(totals.totalCARB150K.aging60)),
      customerCARB150K: formatNumber(
        Math.round(totals.totalCARB150K.customerWithAging60)
      ),
      totalCARB250K: formatNumber(Math.round(totals.totalCARB250K.aging60)),
      customerCARB250K: formatNumber(
        Math.round(totals.totalCARB250K.customerWithAging60)
      ),
      totalCARB500K: formatNumber(Math.round(totals.totalCARB500K.aging60)),
      customerCARB500K: formatNumber(
        Math.round(totals.totalCARB500K.customerWithAging60)
      ),
      totalCARB500KGreater: formatNumber(
        Math.round(totals.totalCARB500KGreater.aging60)
      ),
      customerCARB500KGreater: formatNumber(
        Math.round(totals.totalCARB500KGreater.customerWithAging60)
      ),
    },
    {
      id: 5,
      metric: "AR Balance Aging 90",
      customer: formatNumber(Math.round(totals.customerWithAging90)),
      total: formatNumber(Math.round(totals.aging90)),
      totalCARB20K: formatNumber(Math.round(totals.totalCARB20K.aging90)),
      customerCARB20K: formatNumber(
        Math.round(totals.totalCARB20K.customerWithAging90)
      ),
      totalCARB50K: formatNumber(Math.round(totals.totalCARB50K.aging90)),
      customerCARB50K: formatNumber(
        Math.round(totals.totalCARB50K.customerWithAging90)
      ),
      totalCARB150K: formatNumber(Math.round(totals.totalCARB150K.aging90)),
      customerCARB150K: formatNumber(
        Math.round(totals.totalCARB150K.customerWithAging90)
      ),
      totalCARB250K: formatNumber(Math.round(totals.totalCARB250K.aging90)),
      customerCARB250K: formatNumber(
        Math.round(totals.totalCARB250K.customerWithAging90)
      ),
      totalCARB500K: formatNumber(Math.round(totals.totalCARB500K.aging90)),
      customerCARB500K: formatNumber(
        Math.round(totals.totalCARB500K.customerWithAging90)
      ),
      totalCARB500KGreater: formatNumber(
        Math.round(totals.totalCARB500KGreater.aging90)
      ),
      customerCARB500KGreater: formatNumber(
        Math.round(totals.totalCARB500KGreater.customerWithAging90)
      ),
    },
    {
      id: 6,
      metric: "AR Balance Aging 90+",
      customer: formatNumber(Math.round(totals.customerWithAging90Plus)),
      total: formatNumber(Math.round(totals.aging90Plus)),
      totalCARB20K: formatNumber(Math.round(totals.totalCARB20K.aging90Plus)),
      customerCARB20K: formatNumber(
        Math.round(totals.totalCARB20K.customerWithAging90Plus)
      ),
      totalCARB50K: formatNumber(Math.round(totals.totalCARB50K.aging90Plus)),
      customerCARB50K: formatNumber(
        Math.round(totals.totalCARB50K.customerWithAging90Plus)
      ),
      totalCARB150K: formatNumber(Math.round(totals.totalCARB150K.aging90Plus)),
      customerCARB150K: formatNumber(
        Math.round(totals.totalCARB150K.customerWithAging90Plus)
      ),
      totalCARB250K: formatNumber(Math.round(totals.totalCARB250K.aging90Plus)),
      customerCARB250K: formatNumber(
        Math.round(totals.totalCARB250K.customerWithAging90Plus)
      ),
      totalCARB500K: formatNumber(Math.round(totals.totalCARB500K.aging90Plus)),
      customerCARB500K: formatNumber(
        Math.round(totals.totalCARB500K.customerWithAging90Plus)
      ),
      totalCARB500KGreater: formatNumber(
        Math.round(totals.totalCARB500KGreater.aging90Plus)
      ),
      customerCARB500KGreater: formatNumber(
        Math.round(totals.totalCARB500KGreater.customerWithAging90Plus)
      ),
    },
  ];

  return rowDataAR;
};

export const getTotalForAmountReceivableUnderwriting = (data) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    aging30: 0,
    customerWithAging30: 0,
    aging60: 0,
    customerWithAging60: 0,
    aging90: 0,
    customerWithAging90: 0,
    aging90Plus: 0,
    customerWithAging90Plus: 0,

    totalManual: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRejected: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalApproved: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    other: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
  };

  data.forEach((item) => {
    if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;
    }

    if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
      totals.aging30 += item["AR Balance Aging 30"];
      totals.customerWithAging30 += 1;
    }

    if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
      totals.aging60 += item["AR Balance Aging 60"];
      totals.customerWithAging60 += 1;
    }

    if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
      totals.aging90 += item["AR Balance Aging 90"];
      totals.customerWithAging90 += 1;
    }

    if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
      totals.aging90Plus += item["AR Balance Aging 90+"];
      totals.customerWithAging90Plus += 1;
    }

    if (item["Underwriting"] === "Manual") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalManual.totalARBalance += item["Total AR Balance"];
        totals.totalManual.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalManual.currentARBalance += item["Current AR Balance"];
        totals.totalManual.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalManual.aging30 += item["AR Balance Aging 30"];
        totals.totalManual.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalManual.aging60 += item["AR Balance Aging 60"];
        totals.totalManual.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalManual.aging90 += item["AR Balance Aging 90"];
        totals.totalManual.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalManual.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalManual.customerWithAging90Plus += 1;
      }
    } else if (item["Underwriting"] === "Rejected") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRejected.totalARBalance += item["Total AR Balance"];
        totals.totalRejected.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalRejected.currentARBalance += item["Current AR Balance"];
        totals.totalRejected.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRejected.aging30 += item["AR Balance Aging 30"];
        totals.totalRejected.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRejected.aging60 += item["AR Balance Aging 60"];
        totals.totalRejected.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRejected.aging90 += item["AR Balance Aging 90"];
        totals.totalRejected.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRejected.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRejected.customerWithAging90Plus += 1;
      }
    } else if (item["Underwriting"] === "Approved") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalApproved.totalARBalance += item["Total AR Balance"];
        totals.totalApproved.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalApproved.currentARBalance += item["Current AR Balance"];
        totals.totalApproved.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalApproved.aging30 += item["AR Balance Aging 30"];
        totals.totalApproved.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalApproved.aging60 += item["AR Balance Aging 60"];
        totals.totalApproved.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalApproved.aging90 += item["AR Balance Aging 90"];
        totals.totalApproved.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalApproved.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalApproved.customerWithAging90Plus += 1;
      }
    } else {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.other.totalARBalance += item["Total AR Balance"];
        totals.other.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.other.currentARBalance += item["Current AR Balance"];
        totals.other.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.other.aging30 += item["AR Balance Aging 30"];
        totals.other.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.other.aging60 += item["AR Balance Aging 60"];
        totals.other.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.other.aging90 += item["AR Balance Aging 90"];
        totals.other.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.other.aging90Plus += item["AR Balance Aging 90+"];
        totals.other.customerWithAging90Plus += 1;
      }
    }
  });

  const rowDataAR = [
    {
      id: 1,
      metric: "Total AR Balance",
      customer: formatNumber(totals.customerWithTotalAR),
      total: formatNumber(totals.totalARBalance),
      totalUnderwritingManual: formatNumber(totals.totalManual.totalARBalance),
      customerUnderwritingManual: formatNumber(totals.totalManual.count),
      totalUnderwritingRejected: formatNumber(
        totals.totalRejected.totalARBalance
      ),
      customerUnderwritingRejected: formatNumber(totals.totalRejected.count),
      totalUnderwritingApproved: formatNumber(
        totals.totalApproved.totalARBalance
      ),
      customerUnderwritingApproved: formatNumber(totals.totalApproved.count),
      totalUnderwritingOther: formatNumber(totals.other.totalARBalance),
      customerUnderwritingOther: formatNumber(totals.other.count),
    },
    {
      id: 2,
      metric: "Current AR Balance",
      customer: formatNumber(totals.customerWithCurrentAR),
      total: formatNumber(totals.currentARBalance),
      totalUnderwritingManual: formatNumber(
        totals.totalManual.currentARBalance
      ),
      customerUnderwritingManual: formatNumber(
        totals.totalManual.customerWithCurrentAR
      ),
      totalUnderwritingRejected: formatNumber(
        totals.totalRejected.currentARBalance
      ),
      customerUnderwritingRejected: formatNumber(
        totals.totalRejected.customerWithCurrentAR
      ),
      totalUnderwritingApproved: formatNumber(
        totals.totalApproved.currentARBalance
      ),
      customerUnderwritingApproved: formatNumber(
        totals.totalApproved.customerWithCurrentAR
      ),
      totalUnderwritingOther: formatNumber(totals.other.currentARBalance),
      customerUnderwritingOther: formatNumber(
        totals.other.customerWithCurrentAR
      ),
    },
    {
      id: 3,
      metric: "AR Balance Aging 30",
      customer: formatNumber(totals.customerWithAging30),
      total: formatNumber(totals.aging30),
      totalUnderwritingManual: formatNumber(totals.totalManual.aging30),
      customerUnderwritingManual: formatNumber(
        totals.totalManual.customerWithAging30
      ),
      totalUnderwritingRejected: formatNumber(totals.totalRejected.aging30),
      customerUnderwritingRejected: formatNumber(
        totals.totalRejected.customerWithAging30
      ),
      totalUnderwritingApproved: formatNumber(totals.totalApproved.aging30),
      customerUnderwritingApproved: formatNumber(
        totals.totalApproved.customerWithAging30
      ),
      totalUnderwritingOther: formatNumber(totals.other.aging30),
      customerUnderwritingOther: formatNumber(totals.other.customerWithAging30),
    },
    {
      id: 4,
      metric: "AR Balance Aging 60",
      customer: formatNumber(totals.customerWithAging60),
      total: formatNumber(totals.aging60),
      totalUnderwritingManual: formatNumber(totals.totalManual.aging60),
      customerUnderwritingManual: formatNumber(
        totals.totalManual.customerWithAging60
      ),
      totalUnderwritingRejected: formatNumber(totals.totalRejected.aging60),
      customerUnderwritingRejected: formatNumber(
        totals.totalRejected.customerWithAging60
      ),
      totalUnderwritingApproved: formatNumber(totals.totalApproved.aging60),
      customerUnderwritingApproved: formatNumber(
        totals.totalApproved.customerWithAging60
      ),
      totalUnderwritingOther: formatNumber(totals.other.aging60),
      customerUnderwritingOther: formatNumber(totals.other.customerWithAging60),
    },
    {
      id: 5,
      metric: "AR Balance Aging 90",
      customer: formatNumber(totals.customerWithAging90),
      total: formatNumber(totals.aging90),
      totalUnderwritingManual: formatNumber(totals.totalManual.aging90),
      customerUnderwritingManual: formatNumber(
        totals.totalManual.customerWithAging90
      ),
      totalUnderwritingRejected: formatNumber(totals.totalRejected.aging90),
      customerUnderwritingRejected: formatNumber(
        totals.totalRejected.customerWithAging90
      ),
      totalUnderwritingApproved: formatNumber(totals.totalApproved.aging90),
      customerUnderwritingApproved: formatNumber(
        totals.totalApproved.customerWithAging90
      ),
      totalUnderwritingOther: formatNumber(totals.other.aging90),
      customerUnderwritingOther: formatNumber(totals.other.customerWithAging90),
    },
    {
      id: 6,
      metric: "AR Balance Aging 90+",
      customer: formatNumber(totals.customerWithAging90Plus),
      total: formatNumber(totals.aging90Plus),
      totalUnderwritingManual: formatNumber(totals.totalManual.aging90Plus),
      customerUnderwritingManual: formatNumber(
        totals.totalManual.customerWithAging90Plus
      ),
      totalUnderwritingRejected: formatNumber(totals.totalRejected.aging90Plus),
      customerUnderwritingRejected: formatNumber(
        totals.totalRejected.customerWithAging90Plus
      ),
      totalUnderwritingApproved: formatNumber(totals.totalApproved.aging90Plus),
      customerUnderwritingApproved: formatNumber(
        totals.totalApproved.customerWithAging90Plus
      ),
      totalUnderwritingOther: formatNumber(totals.other.aging90Plus),
      customerUnderwritingOther: formatNumber(
        totals.other.customerWithAging90Plus
      ),
    },
  ];

  return rowDataAR;
};

export const getTotalForAmountReceivableRNOrCN = (data, segment) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    aging30: 0,
    customerWithAging30: 0,
    aging60: 0,
    customerWithAging60: 0,
    aging90: 0,
    customerWithAging90: 0,
    aging90Plus: 0,
    customerWithAging90Plus: 0,

    totalRN30: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRN45: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRN60: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRN90: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRN120: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRN180: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalOther: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
  };

  data.forEach((item) => {
    if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;
    }

    if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
      totals.aging30 += item["AR Balance Aging 30"];
      totals.customerWithAging30 += 1;
    }

    if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
      totals.aging60 += item["AR Balance Aging 60"];
      totals.customerWithAging60 += 1;
    }

    if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
      totals.aging90 += item["AR Balance Aging 90"];
      totals.customerWithAging90 += 1;
    }

    if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
      totals.aging90Plus += item["AR Balance Aging 90+"];
      totals.customerWithAging90Plus += 1;
    }

    if (item[segment] === "Net 30") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRN30.totalARBalance += item["Total AR Balance"];
        totals.totalRN30.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalRN30.currentARBalance += item["Current AR Balance"];
        totals.totalRN30.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRN30.aging30 += item["AR Balance Aging 30"];
        totals.totalRN30.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRN30.aging60 += item["AR Balance Aging 60"];
        totals.totalRN30.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRN30.aging90 += item["AR Balance Aging 90"];
        totals.totalRN30.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRN30.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRN30.customerWithAging90Plus += 1;
      }
    } else if (item[segment] === "Net 45") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRN45.totalARBalance += item["Total AR Balance"];
        totals.totalRN45.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalRN45.currentARBalance += item["Current AR Balance"];
        totals.totalRN45.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRN45.aging30 += item["AR Balance Aging 30"];
        totals.totalRN45.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRN45.aging60 += item["AR Balance Aging 60"];
        totals.totalRN45.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRN45.aging90 += item["AR Balance Aging 90"];
        totals.totalRN45.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRN45.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRN45.customerWithAging90Plus += 1;
      }
    } else if (item[segment] === "Net 60") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRN60.totalARBalance += item["Total AR Balance"];
        totals.totalRN60.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalRN60.currentARBalance += item["Current AR Balance"];
        totals.totalRN60.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRN60.aging30 += item["AR Balance Aging 30"];
        totals.totalRN60.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRN60.aging60 += item["AR Balance Aging 60"];
        totals.totalRN60.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRN60.aging90 += item["AR Balance Aging 90"];
        totals.totalRN60.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRN60.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRN60.customerWithAging90Plus += 1;
      }
    } else if (item[segment] === "Net 90") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRN90.totalARBalance += item["Total AR Balance"];
        totals.totalRN90.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalRN90.currentARBalance += item["Current AR Balance"];
        totals.totalRN90.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRN90.aging30 += item["AR Balance Aging 30"];
        totals.totalRN90.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRN90.aging60 += item["AR Balance Aging 60"];
        totals.totalRN90.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRN90.aging90 += item["AR Balance Aging 90"];
        totals.totalRN90.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRN90.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRN90.customerWithAging90Plus += 1;
      }
    } else if (item[segment] === "Net 120") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRN120.totalARBalance += item["Total AR Balance"];
        totals.totalRN120.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalRN120.currentARBalance += item["Current AR Balance"];
        totals.totalRN120.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRN120.aging30 += item["AR Balance Aging 30"];
        totals.totalRN120.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRN120.aging60 += item["AR Balance Aging 60"];
        totals.totalRN120.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRN120.aging90 += item["AR Balance Aging 90"];
        totals.totalRN120.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRN120.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRN120.customerWithAging90Plus += 1;
      }
    } else if (item[segment] === "Net 180") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRN180.totalARBalance += item["Total AR Balance"];
        totals.totalRN180.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalRN180.currentARBalance += item["Current AR Balance"];
        totals.totalRN180.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRN180.aging30 += item["AR Balance Aging 30"];
        totals.totalRN180.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRN180.aging60 += item["AR Balance Aging 60"];
        totals.totalRN180.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRN180.aging90 += item["AR Balance Aging 90"];
        totals.totalRN180.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalRN180.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRN180.customerWithAging90Plus += 1;
      }
    } else {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalOther.totalARBalance += item["Total AR Balance"];
        totals.totalOther.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalOther.currentARBalance += item["Current AR Balance"];
        totals.totalOther.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalOther.aging30 += item["AR Balance Aging 30"];
        totals.totalOther.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalOther.aging60 += item["AR Balance Aging 60"];
        totals.totalOther.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalOther.aging90 += item["AR Balance Aging 90"];
        totals.totalOther.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalOther.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalOther.customerWithAging90Plus += 1;
      }
    }
  });

  const rowDataAR = [
    {
      id: 1,
      metric: "Total AR Balance",
      customer: formatNumber(Math.round(totals.customerWithTotalAR)),
      total: formatNumber(Math.round(totals.totalARBalance)),
      totalRN30: formatNumber(Math.round(totals.totalRN30.totalARBalance)),
      customerRN30: formatNumber(Math.round(totals.totalRN30.count)),
      totalRN45: formatNumber(Math.round(totals.totalRN45.totalARBalance)),
      customerRN45: formatNumber(Math.round(totals.totalRN45.count)),
      totalRN60: formatNumber(Math.round(totals.totalRN60.totalARBalance)),
      customerRN60: formatNumber(Math.round(totals.totalRN60.count)),
      totalRN90: formatNumber(Math.round(totals.totalRN90.totalARBalance)),
      customerRN90: formatNumber(Math.round(totals.totalRN90.count)),
      totalRN120: formatNumber(Math.round(totals.totalRN120.totalARBalance)),
      customerRN120: formatNumber(Math.round(totals.totalRN120.count)),
      totalRN180: formatNumber(Math.round(totals.totalRN180.totalARBalance)),
      customerRN180: formatNumber(Math.round(totals.totalRN180.count)),
      totalOther: formatNumber(Math.round(totals.totalOther.totalARBalance)),
      customerOther: formatNumber(Math.round(totals.totalOther.count)),
    },
    {
      id: 2,
      metric: "Current AR Balance",
      customer: formatNumber(Math.round(totals.customerWithCurrentAR)),
      total: formatNumber(Math.round(totals.currentARBalance)),
      totalRN30: formatNumber(Math.round(totals.totalRN30.currentARBalance)),
      customerRN30: formatNumber(
        Math.round(totals.totalRN30.customerWithCurrentAR)
      ),
      totalRN45: formatNumber(Math.round(totals.totalRN45.currentARBalance)),
      customerRN45: formatNumber(
        Math.round(totals.totalRN45.customerWithCurrentAR)
      ),
      totalRN60: formatNumber(Math.round(totals.totalRN60.currentARBalance)),
      customerRN60: formatNumber(
        Math.round(totals.totalRN60.customerWithCurrentAR)
      ),
      totalRN90: formatNumber(Math.round(totals.totalRN90.currentARBalance)),
      customerRN90: formatNumber(
        Math.round(totals.totalRN90.customerWithCurrentAR)
      ),
      totalRN120: formatNumber(Math.round(totals.totalRN120.currentARBalance)),
      customerRN120: formatNumber(
        Math.round(totals.totalRN120.customerWithCurrentAR)
      ),
      totalRN180: formatNumber(Math.round(totals.totalRN180.currentARBalance)),
      customerRN180: formatNumber(
        Math.round(totals.totalRN180.customerWithCurrentAR)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.currentARBalance)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithCurrentAR)
      ),
    },
    {
      id: 3,
      metric: "AR Balance Aging 30",
      customer: formatNumber(Math.round(totals.customerWithAging30)),
      total: formatNumber(Math.round(totals.aging30)),
      totalRN30: formatNumber(Math.round(totals.totalRN30.aging30)),
      customerRN30: formatNumber(
        Math.round(totals.totalRN30.customerWithAging30)
      ),
      totalRN45: formatNumber(Math.round(totals.totalRN45.aging30)),
      customerRN45: formatNumber(
        Math.round(totals.totalRN45.customerWithAging30)
      ),
      totalRN60: formatNumber(Math.round(totals.totalRN60.aging30)),
      customerRN60: formatNumber(
        Math.round(totals.totalRN60.customerWithAging30)
      ),
      totalRN90: formatNumber(Math.round(totals.totalRN90.aging30)),
      customerRN90: formatNumber(
        Math.round(totals.totalRN90.customerWithAging30)
      ),
      totalRN120: formatNumber(Math.round(totals.totalRN120.aging30)),
      customerRN120: formatNumber(
        Math.round(totals.totalRN120.customerWithAging30)
      ),
      totalRN180: formatNumber(Math.round(totals.totalRN180.aging30)),
      customerRN180: formatNumber(
        Math.round(totals.totalRN180.customerWithAging30)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging30)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging30)
      ),
    },
    {
      id: 4,
      metric: "AR Balance Aging 60",
      customer: formatNumber(Math.round(totals.customerWithAging60)),
      total: formatNumber(Math.round(totals.aging60)),
      totalRN30: formatNumber(Math.round(totals.totalRN30.aging60)),
      customerRN30: formatNumber(
        Math.round(totals.totalRN30.customerWithAging60)
      ),
      totalRN45: formatNumber(Math.round(totals.totalRN45.aging60)),
      customerRN45: formatNumber(
        Math.round(totals.totalRN45.customerWithAging60)
      ),
      totalRN60: formatNumber(Math.round(totals.totalRN60.aging60)),
      customerRN60: formatNumber(
        Math.round(totals.totalRN60.customerWithAging60)
      ),
      totalRN90: formatNumber(Math.round(totals.totalRN90.aging60)),
      customerRN90: formatNumber(
        Math.round(totals.totalRN90.customerWithAging60)
      ),
      totalRN120: formatNumber(Math.round(totals.totalRN120.aging60)),
      customerRN120: formatNumber(
        Math.round(totals.totalRN120.customerWithAging60)
      ),
      totalRN180: formatNumber(Math.round(totals.totalRN180.aging60)),
      customerRN180: formatNumber(
        Math.round(totals.totalRN180.customerWithAging60)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging60)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging60)
      ),
    },
    {
      id: 5,
      metric: "AR Balance Aging 90",
      customer: formatNumber(Math.round(totals.customerWithAging90)),
      total: formatNumber(Math.round(totals.aging90)),
      totalRN30: formatNumber(Math.round(totals.totalRN30.aging90)),
      customerRN30: formatNumber(
        Math.round(totals.totalRN30.customerWithAging90)
      ),
      totalRN45: formatNumber(Math.round(totals.totalRN45.aging90)),
      customerRN45: formatNumber(
        Math.round(totals.totalRN45.customerWithAging90)
      ),
      totalRN60: formatNumber(Math.round(totals.totalRN60.aging90)),
      customerRN60: formatNumber(
        Math.round(totals.totalRN60.customerWithAging90)
      ),
      totalRN90: formatNumber(Math.round(totals.totalRN90.aging90)),
      customerRN90: formatNumber(
        Math.round(totals.totalRN90.customerWithAging90)
      ),
      totalRN120: formatNumber(Math.round(totals.totalRN120.aging90)),
      customerRN120: formatNumber(
        Math.round(totals.totalRN120.customerWithAging90)
      ),
      totalRN180: formatNumber(Math.round(totals.totalRN180.aging90)),
      customerRN180: formatNumber(
        Math.round(totals.totalRN180.customerWithAging90)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging90)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging90)
      ),
    },
    {
      id: 6,
      metric: "AR Balance Aging 90+",
      customer: formatNumber(Math.round(totals.customerWithAging90Plus)),
      total: formatNumber(Math.round(totals.aging90Plus)),
      totalRN30: formatNumber(Math.round(totals.totalRN30.aging90Plus)),
      customerRN30: formatNumber(
        Math.round(totals.totalRN30.customerWithAging90Plus)
      ),
      totalRN45: formatNumber(Math.round(totals.totalRN45.aging90Plus)),
      customerRN45: formatNumber(
        Math.round(totals.totalRN45.customerWithAging90Plus)
      ),
      totalRN60: formatNumber(Math.round(totals.totalRN60.aging90Plus)),
      customerRN60: formatNumber(
        Math.round(totals.totalRN60.customerWithAging90Plus)
      ),
      totalRN90: formatNumber(Math.round(totals.totalRN90.aging90Plus)),
      customerRN90: formatNumber(
        Math.round(totals.totalRN90.customerWithAging90Plus)
      ),
      totalRN120: formatNumber(Math.round(totals.totalRN120.aging90Plus)),
      customerRN120: formatNumber(
        Math.round(totals.totalRN120.customerWithAging90Plus)
      ),
      totalRN180: formatNumber(Math.round(totals.totalRN180.aging90Plus)),
      customerRN180: formatNumber(
        Math.round(totals.totalRN180.customerWithAging90Plus)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging90Plus)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging90Plus)
      ),
    },
  ];

  return rowDataAR;
};

export const getTotalForAmountReceivableScore = (data, segment) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    aging30: 0,
    customerWithAging30: 0,
    aging60: 0,
    customerWithAging60: 0,
    aging90: 0,
    customerWithAging90: 0,
    aging90Plus: 0,
    customerWithAging90Plus: 0,

    totalScoreLTE1: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalScoreLTE2: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalScoreLTE3: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalScoreLTE4: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalScoreLTE5: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalScoreLTE6: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalScoreLTE7: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalScoreGT7: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalOther: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
  };

  data.forEach((item) => {
    if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;
    }

    if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
      totals.aging30 += item["AR Balance Aging 30"];
      totals.customerWithAging30 += 1;
    }

    if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
      totals.aging60 += item["AR Balance Aging 60"];
      totals.customerWithAging60 += 1;
    }

    if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
      totals.aging90 += item["AR Balance Aging 90"];
      totals.customerWithAging90 += 1;
    }

    if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
      totals.aging90Plus += item["AR Balance Aging 90+"];
      totals.customerWithAging90Plus += 1;
    }

    if (item[segment] <= 1 && item[segment] > 0) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalScoreLTE1.totalARBalance += item["Total AR Balance"];
        totals.totalScoreLTE1.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalScoreLTE1.currentARBalance += item["Current AR Balance"];
        totals.totalScoreLTE1.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalScoreLTE1.aging30 += item["AR Balance Aging 30"];
        totals.totalScoreLTE1.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalScoreLTE1.aging60 += item["AR Balance Aging 60"];
        totals.totalScoreLTE1.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalScoreLTE1.aging90 += item["AR Balance Aging 90"];
        totals.totalScoreLTE1.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalScoreLTE1.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalScoreLTE1.customerWithAging90Plus += 1;
      }
    } else if (item[segment] <= 2 && item[segment] > 1) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalScoreLTE2.totalARBalance += item["Total AR Balance"];
        totals.totalScoreLTE2.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalScoreLTE2.currentARBalance += item["Current AR Balance"];
        totals.totalScoreLTE2.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalScoreLTE2.aging30 += item["AR Balance Aging 30"];
        totals.totalScoreLTE2.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalScoreLTE2.aging60 += item["AR Balance Aging 60"];
        totals.totalScoreLTE2.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalScoreLTE2.aging90 += item["AR Balance Aging 90"];
        totals.totalScoreLTE2.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalScoreLTE2.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalScoreLTE2.customerWithAging90Plus += 1;
      }
    } else if (item[segment] <= 3 && item[segment] > 2) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalScoreLTE3.totalARBalance += item["Total AR Balance"];
        totals.totalScoreLTE3.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalScoreLTE3.currentARBalance += item["Current AR Balance"];
        totals.totalScoreLTE3.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalScoreLTE3.aging30 += item["AR Balance Aging 30"];
        totals.totalScoreLTE3.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalScoreLTE3.aging60 += item["AR Balance Aging 60"];
        totals.totalScoreLTE3.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalScoreLTE3.aging90 += item["AR Balance Aging 90"];
        totals.totalScoreLTE3.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalScoreLTE3.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalScoreLTE3.customerWithAging90Plus += 1;
      }
    } else if (item[segment] <= 4 && item[segment] > 3) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalScoreLTE4.totalARBalance += item["Total AR Balance"];
        totals.totalScoreLTE4.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalScoreLTE4.currentARBalance += item["Current AR Balance"];
        totals.totalScoreLTE4.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalScoreLTE4.aging30 += item["AR Balance Aging 30"];
        totals.totalScoreLTE4.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalScoreLTE4.aging60 += item["AR Balance Aging 60"];
        totals.totalScoreLTE4.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalScoreLTE4.aging90 += item["AR Balance Aging 90"];
        totals.totalScoreLTE4.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalScoreLTE4.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalScoreLTE4.customerWithAging90Plus += 1;
      }
    } else if (item[segment] <= 5 && item[segment] > 4) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalScoreLTE5.totalARBalance += item["Total AR Balance"];
        totals.totalScoreLTE5.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalScoreLTE5.currentARBalance += item["Current AR Balance"];
        totals.totalScoreLTE5.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalScoreLTE5.aging30 += item["AR Balance Aging 30"];
        totals.totalScoreLTE5.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalScoreLTE5.aging60 += item["AR Balance Aging 60"];
        totals.totalScoreLTE5.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalScoreLTE5.aging90 += item["AR Balance Aging 90"];
        totals.totalScoreLTE5.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalScoreLTE5.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalScoreLTE5.customerWithAging90Plus += 1;
      }
    } else if (item[segment] <= 6 && item[segment] > 5) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalScoreLTE6.totalARBalance += item["Total AR Balance"];
        totals.totalScoreLTE6.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalScoreLTE6.currentARBalance += item["Current AR Balance"];
        totals.totalScoreLTE6.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalScoreLTE6.aging30 += item["AR Balance Aging 30"];
        totals.totalScoreLTE6.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalScoreLTE6.aging60 += item["AR Balance Aging 60"];
        totals.totalScoreLTE6.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalScoreLTE6.aging90 += item["AR Balance Aging 90"];
        totals.totalScoreLTE6.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalScoreLTE6.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalScoreLTE6.customerWithAging90Plus += 1;
      }
    } else if (item[segment] <= 7 && item[segment] > 6) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalScoreLTE7.totalARBalance += item["Total AR Balance"];
        totals.totalScoreLTE7.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalScoreLTE7.currentARBalance += item["Current AR Balance"];
        totals.totalScoreLTE7.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalScoreLTE7.aging30 += item["AR Balance Aging 30"];
        totals.totalScoreLTE7.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalScoreLTE7.aging60 += item["AR Balance Aging 60"];
        totals.totalScoreLTE7.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalScoreLTE7.aging90 += item["AR Balance Aging 90"];
        totals.totalScoreLTE7.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalScoreLTE7.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalScoreLTE7.customerWithAging90Plus += 1;
      }
    } else if (item[segment] > 7) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalScoreGT7.totalARBalance += item["Total AR Balance"];
        totals.totalScoreGT7.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalScoreGT7.currentARBalance += item["Current AR Balance"];
        totals.totalScoreGT7.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalScoreGT7.aging30 += item["AR Balance Aging 30"];
        totals.totalScoreGT7.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalScoreGT7.aging60 += item["AR Balance Aging 60"];
        totals.totalScoreGT7.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalScoreGT7.aging90 += item["AR Balance Aging 90"];
        totals.totalScoreGT7.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalScoreGT7.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalScoreGT7.customerWithAging90Plus += 1;
      }
    } else {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalOther.totalARBalance += item["Total AR Balance"];
        totals.totalOther.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.totalOther.currentARBalance += item["Current AR Balance"];
        totals.totalOther.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalOther.aging30 += item["AR Balance Aging 30"];
        totals.totalOther.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalOther.aging60 += item["AR Balance Aging 60"];
        totals.totalOther.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalOther.aging90 += item["AR Balance Aging 90"];
        totals.totalOther.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.totalOther.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalOther.customerWithAging90Plus += 1;
      }
    }
  });

  const rowDataAR = [
    {
      id: 1,
      metric: "Total AR Balance",
      customer: formatNumber(Math.round(totals.customerWithTotalAR)),
      total: formatNumber(Math.round(totals.totalARBalance)),
      totalScoreLTE1: formatNumber(
        Math.round(totals.totalScoreLTE1.totalARBalance)
      ),
      customerScoreLTE1: formatNumber(Math.round(totals.totalScoreLTE1.count)),
      totalScoreLTE2: formatNumber(
        Math.round(totals.totalScoreLTE2.totalARBalance)
      ),
      customerScoreLTE2: formatNumber(Math.round(totals.totalScoreLTE2.count)),
      totalScoreLTE3: formatNumber(
        Math.round(totals.totalScoreLTE3.totalARBalance)
      ),
      customerScoreLTE3: formatNumber(Math.round(totals.totalScoreLTE3.count)),
      totalScoreLTE4: formatNumber(
        Math.round(totals.totalScoreLTE4.totalARBalance)
      ),
      customerScoreLTE4: formatNumber(Math.round(totals.totalScoreLTE4.count)),
      totalScoreLTE5: formatNumber(
        Math.round(totals.totalScoreLTE5.totalARBalance)
      ),
      customerScoreLTE5: formatNumber(Math.round(totals.totalScoreLTE5.count)),
      totalScoreLTE6: formatNumber(
        Math.round(totals.totalScoreLTE6.totalARBalance)
      ),
      customerScoreLTE6: formatNumber(Math.round(totals.totalScoreLTE6.count)),
      totalScoreLTE7: formatNumber(
        Math.round(totals.totalScoreLTE7.totalARBalance)
      ),
      customerScoreLTE7: formatNumber(Math.round(totals.totalScoreLTE7.count)),
      totalScoreGT7: formatNumber(
        Math.round(totals.totalScoreGT7.totalARBalance)
      ),
      customerScoreGT7: formatNumber(Math.round(totals.totalScoreGT7.count)),
      totalOther: formatNumber(Math.round(totals.totalOther.totalARBalance)),
      customerOther: formatNumber(Math.round(totals.totalOther.count)),
    },
    {
      id: 2,
      metric: "Current AR Balance",
      customer: formatNumber(Math.round(totals.customerWithCurrentAR)),
      total: formatNumber(Math.round(totals.currentARBalance)),
      totalScoreLTE1: formatNumber(
        Math.round(totals.totalScoreLTE1.currentARBalance)
      ),
      customerScoreLTE1: formatNumber(
        Math.round(totals.totalScoreLTE1.customerWithCurrentAR)
      ),
      totalScoreLTE2: formatNumber(
        Math.round(totals.totalScoreLTE2.currentARBalance)
      ),
      customerScoreLTE2: formatNumber(
        Math.round(totals.totalScoreLTE2.customerWithCurrentAR)
      ),
      totalScoreLTE3: formatNumber(
        Math.round(totals.totalScoreLTE3.currentARBalance)
      ),
      customerScoreLTE3: formatNumber(
        Math.round(totals.totalScoreLTE3.customerWithCurrentAR)
      ),
      totalScoreLTE4: formatNumber(
        Math.round(totals.totalScoreLTE4.currentARBalance)
      ),
      customerScoreLTE4: formatNumber(
        Math.round(totals.totalScoreLTE4.customerWithCurrentAR)
      ),
      totalScoreLTE5: formatNumber(
        Math.round(totals.totalScoreLTE5.currentARBalance)
      ),
      customerScoreLTE5: formatNumber(
        Math.round(totals.totalScoreLTE5.customerWithCurrentAR)
      ),
      totalScoreLTE6: formatNumber(
        Math.round(totals.totalScoreLTE6.currentARBalance)
      ),
      customerScoreLTE6: formatNumber(
        Math.round(totals.totalScoreLTE6.customerWithCurrentAR)
      ),
      totalScoreLTE7: formatNumber(
        Math.round(totals.totalScoreLTE7.currentARBalance)
      ),
      customerScoreLTE7: formatNumber(
        Math.round(totals.totalScoreLTE7.customerWithCurrentAR)
      ),
      totalScoreGT7: formatNumber(
        Math.round(totals.totalScoreGT7.currentARBalance)
      ),
      customerScoreGT7: formatNumber(
        Math.round(totals.totalScoreGT7.customerWithCurrentAR)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.currentARBalance)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithCurrentAR)
      ),
    },
    {
      id: 3,
      metric: "AR Balance Aging 30",
      customer: formatNumber(Math.round(totals.customerWithAging30)),
      total: formatNumber(Math.round(totals.aging30)),
      totalScoreLTE1: formatNumber(Math.round(totals.totalScoreLTE1.aging30)),
      customerScoreLTE1: formatNumber(
        Math.round(totals.totalScoreLTE1.customerWithAging30)
      ),
      totalScoreLTE2: formatNumber(Math.round(totals.totalScoreLTE2.aging30)),
      customerScoreLTE2: formatNumber(
        Math.round(totals.totalScoreLTE2.customerWithAging30)
      ),
      totalScoreLTE3: formatNumber(Math.round(totals.totalScoreLTE3.aging30)),
      customerScoreLTE3: formatNumber(
        Math.round(totals.totalScoreLTE3.customerWithAging30)
      ),
      totalScoreLTE4: formatNumber(Math.round(totals.totalScoreLTE4.aging30)),
      customerScoreLTE4: formatNumber(
        Math.round(totals.totalScoreLTE4.customerWithAging30)
      ),
      totalScoreLTE5: formatNumber(Math.round(totals.totalScoreLTE5.aging30)),
      customerScoreLTE5: formatNumber(
        Math.round(totals.totalScoreLTE5.customerWithAging30)
      ),
      totalScoreLTE6: formatNumber(Math.round(totals.totalScoreLTE6.aging30)),
      customerScoreLTE6: formatNumber(
        Math.round(totals.totalScoreLTE6.customerWithAging30)
      ),
      totalScoreLTE7: formatNumber(Math.round(totals.totalScoreLTE7.aging30)),
      customerScoreLTE7: formatNumber(
        Math.round(totals.totalScoreLTE7.customerWithAging30)
      ),
      totalScoreGT7: formatNumber(Math.round(totals.totalScoreGT7.aging30)),
      customerScoreGT7: formatNumber(
        Math.round(totals.totalScoreGT7.customerWithAging30)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging30)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging30)
      ),
    },
    {
      id: 4,
      metric: "AR Balance Aging 60",
      customer: formatNumber(Math.round(totals.customerWithAging60)),
      total: formatNumber(Math.round(totals.aging60)),
      totalScoreLTE1: formatNumber(Math.round(totals.totalScoreLTE1.aging60)),
      customerScoreLTE1: formatNumber(
        Math.round(totals.totalScoreLTE1.customerWithAging60)
      ),
      totalScoreLTE2: formatNumber(Math.round(totals.totalScoreLTE2.aging60)),
      customerScoreLTE2: formatNumber(
        Math.round(totals.totalScoreLTE2.customerWithAging60)
      ),
      totalScoreLTE3: formatNumber(Math.round(totals.totalScoreLTE3.aging60)),
      customerScoreLTE3: formatNumber(
        Math.round(totals.totalScoreLTE3.customerWithAging60)
      ),
      totalScoreLTE4: formatNumber(Math.round(totals.totalScoreLTE4.aging60)),
      customerScoreLTE4: formatNumber(
        Math.round(totals.totalScoreLTE4.customerWithAging60)
      ),
      totalScoreLTE5: formatNumber(Math.round(totals.totalScoreLTE5.aging60)),
      customerScoreLTE5: formatNumber(
        Math.round(totals.totalScoreLTE5.customerWithAging60)
      ),
      totalScoreLTE6: formatNumber(Math.round(totals.totalScoreLTE6.aging60)),
      customerScoreLTE6: formatNumber(
        Math.round(totals.totalScoreLTE6.customerWithAging60)
      ),
      totalScoreLTE7: formatNumber(Math.round(totals.totalScoreLTE7.aging60)),
      customerScoreLTE7: formatNumber(
        Math.round(totals.totalScoreLTE7.customerWithAging60)
      ),
      totalScoreGT7: formatNumber(Math.round(totals.totalScoreGT7.aging60)),
      customerScoreGT7: formatNumber(
        Math.round(totals.totalScoreGT7.customerWithAging60)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging60)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging60)
      ),
    },
    {
      id: 5,
      metric: "AR Balance Aging 90",
      customer: formatNumber(Math.round(totals.customerWithAging90)),
      total: formatNumber(Math.round(totals.aging90)),
      totalScoreLTE1: formatNumber(Math.round(totals.totalScoreLTE1.aging90)),
      customerScoreLTE1: formatNumber(
        Math.round(totals.totalScoreLTE1.customerWithAging90)
      ),
      totalScoreLTE2: formatNumber(Math.round(totals.totalScoreLTE2.aging90)),
      customerScoreLTE2: formatNumber(
        Math.round(totals.totalScoreLTE2.customerWithAging90)
      ),
      totalScoreLTE3: formatNumber(Math.round(totals.totalScoreLTE3.aging90)),
      customerScoreLTE3: formatNumber(
        Math.round(totals.totalScoreLTE3.customerWithAging90)
      ),
      totalScoreLTE4: formatNumber(Math.round(totals.totalScoreLTE4.aging90)),
      customerScoreLTE4: formatNumber(
        Math.round(totals.totalScoreLTE4.customerWithAging90)
      ),
      totalScoreLTE5: formatNumber(Math.round(totals.totalScoreLTE5.aging90)),
      customerScoreLTE5: formatNumber(
        Math.round(totals.totalScoreLTE5.customerWithAging90)
      ),
      totalScoreLTE6: formatNumber(Math.round(totals.totalScoreLTE6.aging90)),
      customerScoreLTE6: formatNumber(
        Math.round(totals.totalScoreLTE6.customerWithAging90)
      ),
      totalScoreLTE7: formatNumber(Math.round(totals.totalScoreLTE7.aging90)),
      customerScoreLTE7: formatNumber(
        Math.round(totals.totalScoreLTE7.customerWithAging90)
      ),
      totalScoreGT7: formatNumber(Math.round(totals.totalScoreGT7.aging90)),
      customerScoreGT7: formatNumber(
        Math.round(totals.totalScoreGT7.customerWithAging90)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging90)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging90)
      ),
    },
    {
      id: 6,
      metric: "AR Balance Aging 90+",
      customer: formatNumber(Math.round(totals.customerWithAging90Plus)),
      total: formatNumber(Math.round(totals.aging90Plus)),
      totalScoreLTE1: formatNumber(
        Math.round(totals.totalScoreLTE1.aging90Plus)
      ),
      customerScoreLTE1: formatNumber(
        Math.round(totals.totalScoreLTE1.customerWithAging90Plus)
      ),
      totalScoreLTE2: formatNumber(
        Math.round(totals.totalScoreLTE2.aging90Plus)
      ),
      customerScoreLTE2: formatNumber(
        Math.round(totals.totalScoreLTE2.customerWithAging90Plus)
      ),
      totalScoreLTE3: formatNumber(
        Math.round(totals.totalScoreLTE3.aging90Plus)
      ),
      customerScoreLTE3: formatNumber(
        Math.round(totals.totalScoreLTE3.customerWithAging90Plus)
      ),
      totalScoreLTE4: formatNumber(
        Math.round(totals.totalScoreLTE4.aging90Plus)
      ),
      customerScoreLTE4: formatNumber(
        Math.round(totals.totalScoreLTE4.customerWithAging90Plus)
      ),
      totalScoreLTE5: formatNumber(
        Math.round(totals.totalScoreLTE5.aging90Plus)
      ),
      customerScoreLTE5: formatNumber(
        Math.round(totals.totalScoreLTE5.customerWithAging90Plus)
      ),
      totalScoreLTE6: formatNumber(
        Math.round(totals.totalScoreLTE6.aging90Plus)
      ),
      customerScoreLTE6: formatNumber(
        Math.round(totals.totalScoreLTE6.customerWithAging90Plus)
      ),
      totalScoreLTE7: formatNumber(
        Math.round(totals.totalScoreLTE7.aging90Plus)
      ),
      customerScoreLTE7: formatNumber(
        Math.round(totals.totalScoreLTE7.customerWithAging90Plus)
      ),
      totalScoreGT7: formatNumber(Math.round(totals.totalScoreGT7.aging90Plus)),
      customerScoreGT7: formatNumber(
        Math.round(totals.totalScoreGT7.customerWithAging90Plus)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging90Plus)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging90Plus)
      ),
    },
  ];

  return rowDataAR;
};

export const getTotalForAmountReceivableUWSOrCS = (
  data,
  segment,
  comparisonVal
) => {
  const totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    aging30: 0,
    customerWithAging30: 0,
    aging60: 0,
    customerWithAging60: 0,
    aging90: 0,
    customerWithAging90: 0,
    aging90Plus: 0,
    customerWithAging90Plus: 0,

    // fields for segmentation based on Country Score or Underwriting Reason
    total1: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },

    total2: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    total3: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    total4: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    total5: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    total6: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    total7: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    total8: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
  };

  data.forEach((item) => {
    if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;
    }

    if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
      totals.aging30 += item["AR Balance Aging 30"];
      totals.customerWithAging30 += 1;
    }

    if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
      totals.aging60 += item["AR Balance Aging 60"];
      totals.customerWithAging60 += 1;
    }

    if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
      totals.aging90 += item["AR Balance Aging 90"];
      totals.customerWithAging90 += 1;
    }

    if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
      totals.aging90Plus += item["AR Balance Aging 90+"];
      totals.customerWithAging90Plus += 1;
    }

    if (item[segment] === comparisonVal.val1) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.total1.totalARBalance += item["Total AR Balance"];
        totals.total1.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.total1.currentARBalance += item["Current AR Balance"];
        totals.total1.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.total1.aging30 += item["AR Balance Aging 30"];
        totals.total1.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.total1.aging60 += item["AR Balance Aging 60"];
        totals.total1.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.total1.aging90 += item["AR Balance Aging 90"];
        totals.total1.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.total1.aging90Plus += item["AR Balance Aging 90+"];
        totals.total1.customerWithAging90Plus += 1;
      }
    } else if (item[segment] === comparisonVal.val2) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.total2.totalARBalance += item["Total AR Balance"];
        totals.total2.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.total2.currentARBalance += item["Current AR Balance"];
        totals.total2.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.total2.aging30 += item["AR Balance Aging 30"];
        totals.total2.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.total2.aging60 += item["AR Balance Aging 60"];
        totals.total2.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.total2.aging90 += item["AR Balance Aging 90"];
        totals.total2.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.total2.aging90Plus += item["AR Balance Aging 90+"];
        totals.total2.customerWithAging90Plus += 1;
      }
    } else if (item[segment] === comparisonVal.val3) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.total3.totalARBalance += item["Total AR Balance"];
        totals.total3.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.total3.currentARBalance += item["Current AR Balance"];
        totals.total3.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.total3.aging30 += item["AR Balance Aging 30"];
        totals.total3.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.total3.aging60 += item["AR Balance Aging 60"];
        totals.total3.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.total3.aging90 += item["AR Balance Aging 90"];
        totals.total3.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.total3.aging90Plus += item["AR Balance Aging 90+"];
        totals.total3.customerWithAging90Plus += 1;
      }
    } else if (item[segment] === comparisonVal.val4) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.total4.totalARBalance += item["Total AR Balance"];
        totals.total4.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.total4.currentARBalance += item["Current AR Balance"];
        totals.total4.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.total4.aging30 += item["AR Balance Aging 30"];
        totals.total4.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.total4.aging60 += item["AR Balance Aging 60"];
        totals.total4.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.total4.aging90 += item["AR Balance Aging 90"];
        totals.total4.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.total4.aging90Plus += item["AR Balance Aging 90+"];
        totals.total4.customerWithAging90Plus += 1;
      }
    } else if (item[segment] === comparisonVal.val5) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.total5.totalARBalance += item["Total AR Balance"];
        totals.total5.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.total5.currentARBalance += item["Current AR Balance"];
        totals.total5.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.total5.aging30 += item["AR Balance Aging 30"];
        totals.total5.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.total5.aging60 += item["AR Balance Aging 60"];
        totals.total5.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.total5.aging90 += item["AR Balance Aging 90"];
        totals.total5.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.total5.aging90Plus += item["AR Balance Aging 90+"];
        totals.total5.customerWithAging90Plus += 1;
      }
    } else if (item[segment] === comparisonVal.val6) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.total6.totalARBalance += item["Total AR Balance"];
        totals.total6.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.total6.currentARBalance += item["Current AR Balance"];
        totals.total6.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.total6.aging30 += item["AR Balance Aging 30"];
        totals.total6.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.total6.aging60 += item["AR Balance Aging 60"];
        totals.total6.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.total6.aging90 += item["AR Balance Aging 90"];
        totals.total6.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.total6.aging90Plus += item["AR Balance Aging 90+"];
        totals.total6.customerWithAging90Plus += 1;
      }
    } else if (item[segment] === comparisonVal.val7) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.total7.totalARBalance += item["Total AR Balance"];
        totals.total7.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.total7.currentARBalance += item["Current AR Balance"];
        totals.total7.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.total7.aging30 += item["AR Balance Aging 30"];
        totals.total7.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.total7.aging60 += item["AR Balance Aging 60"];
        totals.total7.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.total7.aging90 += item["AR Balance Aging 90"];
        totals.total7.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.total7.aging90Plus += item["AR Balance Aging 90+"];
        totals.total7.customerWithAging90Plus += 1;
      }
    } else if (
      (segment === "Country Score" && item[segment] > 7) ||
      segment === "Underwriting Reason"
    ) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.total8.totalARBalance += item["Total AR Balance"];
        totals.total8.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0){
        totals.total8.currentARBalance += item["Current AR Balance"];
        totals.total8.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.total8.aging30 += item["AR Balance Aging 30"];
        totals.total8.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.total8.aging60 += item["AR Balance Aging 60"];
        totals.total8.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.total8.aging90 += item["AR Balance Aging 90"];
        totals.total8.customerWithAging90 += 1;
      }

      if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
        totals.total8.aging90Plus += item["AR Balance Aging 90+"];
        totals.total8.customerWithAging90Plus += 1;
      }
    }
  });

  const rowDataAR = [
    {
      id: 1,
      metric: "Total AR Balance",
      customer: formatNumber(Math.round(totals.customerWithTotalAR)),
      total: formatNumber(Math.round(totals.totalARBalance)),
      total1: formatNumber(Math.round(totals.total1.totalARBalance)),
      customer1: formatNumber(Math.round(totals.total1.count)),
      total2: formatNumber(Math.round(totals.total2.totalARBalance)),
      customer2: formatNumber(Math.round(totals.total2.count)),
      total3: formatNumber(Math.round(totals.total3.totalARBalance)),
      customer3: formatNumber(Math.round(totals.total3.count)),
      total4: formatNumber(Math.round(totals.total4.totalARBalance)),
      customer4: formatNumber(Math.round(totals.total4.count)),
      total5: formatNumber(Math.round(totals.total5.totalARBalance)),
      customer5: formatNumber(Math.round(totals.total5.count)),
      total6: formatNumber(Math.round(totals.total6.totalARBalance)),
      customer6: formatNumber(Math.round(totals.total6.count)),
      total7: formatNumber(Math.round(totals.total7.totalARBalance)),
      customer7: formatNumber(Math.round(totals.total7.count)),
      total8: formatNumber(Math.round(totals.total8.totalARBalance)),
      customer8: formatNumber(Math.round(totals.total8.count)),
    },
    {
      id: 2,
      metric: "Current AR Balance",
      customer: formatNumber(Math.round(totals.customerWithCurrentAR)),
      total: formatNumber(Math.round(totals.currentARBalance)),
      total1: formatNumber(Math.round(totals.total1.currentARBalance)),
      customer1: formatNumber(Math.round(totals.total1.customerWithCurrentAR)),
      total2: formatNumber(Math.round(totals.total2.currentARBalance)),
      customer2: formatNumber(Math.round(totals.total2.customerWithCurrentAR)),
      total3: formatNumber(Math.round(totals.total3.currentARBalance)),
      customer3: formatNumber(Math.round(totals.total3.customerWithCurrentAR)),
      total4: formatNumber(Math.round(totals.total4.currentARBalance)),
      customer4: formatNumber(Math.round(totals.total4.customerWithCurrentAR)),
      total5: formatNumber(Math.round(totals.total5.currentARBalance)),
      customer5: formatNumber(Math.round(totals.total5.customerWithCurrentAR)),
      total6: formatNumber(Math.round(totals.total6.currentARBalance)),
      customer6: formatNumber(Math.round(totals.total6.customerWithCurrentAR)),
      total7: formatNumber(Math.round(totals.total7.currentARBalance)),
      customer7: formatNumber(Math.round(totals.total7.customerWithCurrentAR)),
      total8: formatNumber(Math.round(totals.total8.currentARBalance)),
      customer8: formatNumber(Math.round(totals.total8.customerWithCurrentAR)),
    },
    {
      id: 3,
      metric: "AR Balance Aging 30",
      customer: formatNumber(Math.round(totals.customerWithAging30)),
      total: formatNumber(Math.round(totals.aging30)),
      total1: formatNumber(Math.round(totals.total1.aging30)),
      customer1: formatNumber(Math.round(totals.total1.customerWithAging30)),
      total2: formatNumber(Math.round(totals.total2.aging30)),
      customer2: formatNumber(Math.round(totals.total2.customerWithAging30)),
      total3: formatNumber(Math.round(totals.total3.aging30)),
      customer3: formatNumber(Math.round(totals.total3.customerWithAging30)),
      total4: formatNumber(Math.round(totals.total4.aging30)),
      customer4: formatNumber(Math.round(totals.total4.customerWithAging30)),
      total5: formatNumber(Math.round(totals.total5.aging30)),
      customer5: formatNumber(Math.round(totals.total5.customerWithAging30)),
      total6: formatNumber(Math.round(totals.total6.aging30)),
      customer6: formatNumber(Math.round(totals.total6.customerWithAging30)),
      total7: formatNumber(Math.round(totals.total7.aging30)),
      customer7: formatNumber(Math.round(totals.total7.customerWithAging30)),
      total8: formatNumber(Math.round(totals.total8.aging30)),
      customer8: formatNumber(Math.round(totals.total8.customerWithAging30)),
    },
    {
      id: 4,
      metric: "AR Balance Aging 60",
      customer: formatNumber(Math.round(totals.customerWithAging60)),
      total: formatNumber(Math.round(totals.aging60)),
      total1: formatNumber(Math.round(totals.total1.aging60)),
      customer1: formatNumber(Math.round(totals.total1.customerWithAging60)),
      total2: formatNumber(Math.round(totals.total2.aging60)),
      customer2: formatNumber(Math.round(totals.total2.customerWithAging60)),
      total3: formatNumber(Math.round(totals.total3.aging60)),
      customer3: formatNumber(Math.round(totals.total3.customerWithAging60)),
      total4: formatNumber(Math.round(totals.total4.aging60)),
      customer4: formatNumber(Math.round(totals.total4.customerWithAging60)),
      total5: formatNumber(Math.round(totals.total5.aging60)),
      customer5: formatNumber(Math.round(totals.total5.customerWithAging60)),
      total6: formatNumber(Math.round(totals.total6.aging60)),
      customer6: formatNumber(Math.round(totals.total6.customerWithAging60)),
      total7: formatNumber(Math.round(totals.total7.aging60)),
      customer7: formatNumber(Math.round(totals.total7.customerWithAging60)),
      total8: formatNumber(Math.round(totals.total8.aging60)),
      customer8: formatNumber(Math.round(totals.total8.customerWithAging60)),
    },
    {
      id: 5,
      metric: "AR Balance Aging 90",
      customer: formatNumber(Math.round(totals.customerWithAging90)),
      total: formatNumber(Math.round(totals.aging90)),
      total1: formatNumber(Math.round(totals.total1.aging90)),
      customer1: formatNumber(Math.round(totals.total1.customerWithAging90)),
      total2: formatNumber(Math.round(totals.total2.aging90)),
      customer2: formatNumber(Math.round(totals.total2.customerWithAging90)),
      total3: formatNumber(Math.round(totals.total3.aging90)),
      customer3: formatNumber(Math.round(totals.total3.customerWithAging90)),
      total4: formatNumber(Math.round(totals.total4.aging90)),
      customer4: formatNumber(Math.round(totals.total4.customerWithAging90)),
      total5: formatNumber(Math.round(totals.total5.aging90)),
      customer5: formatNumber(Math.round(totals.total5.customerWithAging90)),
      total6: formatNumber(Math.round(totals.total6.aging90)),
      customer6: formatNumber(Math.round(totals.total6.customerWithAging90)),
      total7: formatNumber(Math.round(totals.total7.aging90)),
      customer7: formatNumber(Math.round(totals.total7.customerWithAging90)),
      total8: formatNumber(Math.round(totals.total8.aging90)),
      customer8: formatNumber(Math.round(totals.total8.customerWithAging90)),
    },
    {
      id: 6,
      metric: "AR Balance Aging 90+",
      customer: formatNumber(Math.round(totals.customerWithAging90Plus)),
      total: formatNumber(Math.round(totals.aging90Plus)),
      total1: formatNumber(Math.round(totals.total1.aging90Plus)),
      customer1: formatNumber(
        Math.round(totals.total1.customerWithAging90Plus)
      ),
      total2: formatNumber(Math.round(totals.total2.aging90Plus)),
      customer2: formatNumber(
        Math.round(totals.total2.customerWithAging90Plus)
      ),
      total3: formatNumber(Math.round(totals.total3.aging90Plus)),
      customer3: formatNumber(
        Math.round(totals.total3.customerWithAging90Plus)
      ),
      total4: formatNumber(Math.round(totals.total4.aging90Plus)),
      customer4: formatNumber(
        Math.round(totals.total4.customerWithAging90Plus)
      ),
      total5: formatNumber(Math.round(totals.total5.aging90Plus)),
      customer5: formatNumber(
        Math.round(totals.total5.customerWithAging90Plus)
      ),
      total6: formatNumber(Math.round(totals.total6.aging90Plus)),
      customer6: formatNumber(
        Math.round(totals.total6.customerWithAging90Plus)
      ),
      total7: formatNumber(Math.round(totals.total7.aging90Plus)),
      customer7: formatNumber(
        Math.round(totals.total7.customerWithAging90Plus)
      ),
      total8: formatNumber(Math.round(totals.total8.aging90Plus)),
      customer8: formatNumber(
        Math.round(totals.total8.customerWithAging90Plus)
      ),
    },
  ];

  return rowDataAR;
};
