import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { API, Amplify, graphqlOperation } from "aws-amplify";
import { createCompany } from "./graphql/mutations";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import { publish } from "./events";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { addCustomMetric } from "./graphql/queries";
import AWS from 'aws-sdk';
import awsExports from "./aws-exports";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useSelector } from "react-redux";
import { Padding } from "@mui/icons-material";

Amplify.configure(awsExports);

AWS.config.update(
  {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  }
);


function AddCompanyManually({
  isAddCompanyManuallyModelOpen,
  toggleAddCompanyManuallyModal,
  companyInfo,
  handleOptionsModalClose,
}) {
  const [companyNameValue, setCompanyNameValue] = useState("");
  const [parentCompanyNameValue, setParentCompanyNameValue] = useState("");
  const [requestedCreditLimit, setRequestedCreditLimit] = useState("");
  const [companyCountryValue, setCompanyCountryValue] = useState("");
  const [parentCompanyCountryValue, setParentCompanyCountryValue] = useState("");
  const [error, setError] = useState(false);
  const [errorTxt, setErrorTxt] = useState("This field is required");
  const [agingData, setAgingData] = useState([]);
  const [companySearchResults, setCompanySearchResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [tickerValue, setTickerValue] = useState("");
  const [parentTickerValue, setParentTickerValue] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [isParentPublic, setIsParentPublic] = useState(false);
  const [isParentCompany, setIsParentCompany] = useState(false);
  const [tenantId,setTenantId] = useState()
  const [loading,setLoading] = useState(false)
  const [parentCompanyDailog, setParentCompanyDailog] = useState(false);


  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenant  = useSelector(state => state.userInfo.tenantId);
  const selectTenantId =()=>{
    if(isAdmin) setTenantId(URLTenant)
    else setTenantId(currentTenant)
  }
  const tickerValueLength = tickerValue?.length === 0 && parentTickerValue?.length === 0;
  const isAddDisabledBasedOnTicker = isPublic ? tickerValueLength : false;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    bgcolor: "background.paper",
    borderRadius:"20px",
    boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"10px"
  };

  const handleAddCompanyClick = async () => {
    if (companyNameValue.trim() === "" || companyCountryValue.trim() === "") {
      setError(true);
      return;
    }
    setLoading(true)
    let company;
    const result = await API.graphql(
      graphqlOperation(createCompany, {
        input: {
          tenantId: tenantId,
          name: companyNameValue,
          country: companyCountryValue,
          requestedCreditLimit: parseFloat(requestedCreditLimit),
          ticker: tickerValue,
          parentCompanyName:parentCompanyNameValue,
          parentCountry:parentCompanyCountryValue,
          parentTicker:parentTickerValue,
          view: 1,
        },
      })
    );
    company = result?.data?.createCompany;

    console.log("Result --> ", result);

    if (company.name) {
      toast.success(`${company?.name} Added Successfully!`, {
        bodyStyle: {
          fontWeight: "bold",
        }
      });

      await API.graphql(
        graphqlOperation(addCustomMetric, {
          tenantId: tenantId,
        }))
    }

    publish("create_company", company);
    setError(false);
    toggleAddCompanyManuallyModal();
    setCompanyNameValue("");
    setTickerValue("");
    setRequestedCreditLimit("");
    setCompanyCountryValue("");
    setSelectedCompany(null);
    setShowSearchResults(false);
    setCompanySearchResults([]);
    // setCompanyInfoToNull();
    setIsPublic(false);
    setLoading(false)
  };

  const handleCompanyNameChange = (event) => {
    setCompanyNameValue(event.target.value);
  };
  const handleParentCompanyNameChange = (event) => {
    setParentCompanyNameValue(event.target.value);
  };

  const handleRequestedCreditLimitChange = (event) => {
    setRequestedCreditLimit(event.target.value);
  };

  const handleCompanyCountryValueChange = (event) => {
    setCompanyCountryValue(event.target.value);
  };
  const handleParentCompanyCountryValueChange = (event) => {
    setParentCompanyCountryValue(event.target.value);
  };

  const handleCloseClick = () => {
    publish("create_company", {});
    toggleAddCompanyManuallyModal();
    setCompanyNameValue("");
    setTickerValue("");
    setRequestedCreditLimit("");
    setCompanyCountryValue("");
    setSelectedCompany(null);
    setCompanySearchResults([]);
    setShowSearchResults(false);
    handleOptionsModalClose()
    setIsPublic(false);
  };

  const handleTickerValueChange = (event) => {
    setTickerValue(event.target.value);
  };
  const handleParentTickerValueChange = (event) => {
    setParentTickerValue(event.target.value);
  };

  const handleIsCompanyPublicChange = (event) => {
    setIsPublic(event.target.checked);
    if (!event.target.checked) setTickerValue("");
  };
  const handleIsParentCompanyPublicChange = (event) => {
    setIsParentPublic(event.target.checked);
    if (!event.target.checked) setParentTickerValue("");
  };
  const handleIsParentCompanyChange = (event) => {
    setIsParentCompany(event.target.checked);
    if (!event.target.checked) {
      setParentCompanyCountryValue("")
      setParentCompanyNameValue("")
      setParentTickerValue("")
    }
  };

  const getAgingFile = async () => {
    if(tenantId){
    try {
      const key = "public/aging_" + tenantId + ".json";
      const s3 = new AWS.S3();
      AWS.config.getCredentials((err) => {
        if (err) {
          console.log("Error retrieving AWS credentials:", err);
        } else {
          console.log("AWS credentials are valid and set up correctly.");
        }
      });
      const data = await s3.getObject({ Bucket: "crediarc-content01853-production", Key: key }).promise();
      const fileData = data.Body.toString('utf-8');
      const summary = JSON.parse(fileData);

      // summary is a list of objects. It can have strings which are formatted numbers (e.g. "1,000,000")
      // or numbers (e.g. 1000000). We need to convert the strings to numbers.
      for (let i = 0; i < summary.length; i++) {
        const row = summary[i];
        for (const key in row) {
          if (typeof row[key] === "string") {
            row[key] = isNaN(parseFloat(row[key].replace(/,/g, "")))
              ? row[key]
              : parseFloat(row[key].replace(/,/g, ""));
          }
        }
      }
      console.log("sumamry in create company manually",summary)
      setAgingData(summary);
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  };

  const selectCompany = (company) => {
    setSelectedCompany(company);
  };

  const searchCompany = () => {
    if (companyNameValue.trim() === "") {
      setError(true);
      return;
    }
    const results = agingData.filter((companyData) =>
      companyData?.name?.toString()?.includes(companyNameValue)
    );

    const searchResults = results.length === 0 ? undefined : results;
    setCompanySearchResults(searchResults);
    if (searchResults !== undefined) {
      setShowSearchResults(true);
    }
  };

  useEffect(() => {
      selectTenantId();
    
    getAgingFile();
  
  }, []);

  useEffect(() => {
    setCompanyNameValue(companyInfo?.companyName);
    setCompanyCountryValue(companyInfo?.companyCountry);
    setRequestedCreditLimit(companyInfo?.companyRequestedCreditLimit);
    setTickerValue(companyInfo?.tickerSymbol)

    if (companyInfo?.tickerSymbol) {
      setIsPublic(true);
    }

  }, [companyInfo]);
  const modalStyle = { 
    backdropFilter: "blur(2px)", 
    backgroundColor: "rgb(255, 255, 255, 0.5)" 
  }
  const inputStyle={
    width:"285px",
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px', 
      height: '48px',      
    },
    "& .MuiOutlinedInput-input":{
      borderRadius: '8px', 
      height: '12px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px', 
    },
    marginTop:"10px",
    
  }

  const buttonStyle = {
    width:"132px",
    height:"44px",
    borderRadius:"8px",
    backgroundColor:"#5186EC",
    marginRight:"4px",
    '&:disabled': {
                        backgroundColor: '#f0f0f0', 
                      },
  }

  return (
    <div>
      <Modal
        open={isAddCompanyManuallyModelOpen}
        onClose={toggleAddCompanyManuallyModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={modalStyle}
      >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                  textAlign: "center",
                  padding: 2,
                  color: "#2F3D63"
                }}>
              Add Company Manually
            </Typography>
            {!showSearchResults ? (
              <React.Fragment>
                <TextField
                  id="companyName"
                  helperText={error ? errorTxt : ""}
                  label="Company Name *"
                  variant="outlined"
                  defaultValue={companyInfo?.companyName}
                  value={
                    selectCompany
                      ? selectedCompany?.name?.toString()
                      : companyNameValue
                  }
                  sx={inputStyle}
                  onChange={handleCompanyNameChange}
                  error={error}
                />
                <TextField
                  id="companyCountry"
                  label="Company Country *"
                  variant="outlined"
                  helperText={error ? errorTxt : ""}
                  value={companyCountryValue}
                  onChange={handleCompanyCountryValueChange}
                  error={error}
                  sx={inputStyle}
                />
                <TextField
                  id="reqCredit"
                  label="Requested Credit Limit"
                  variant="outlined"
                  value={requestedCreditLimit}
                  onChange={handleRequestedCreditLimitChange}
                  sx={inputStyle}
                />
                <FormControl
                  sx={{display:"flex" ,flexDirection:"column"}}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "4px",
                      width:"270px",
                    }}
                  >
                    <Typography variant="body1" sx={{ marginTop: "9px",marginRight:"auto",}}>
                      Is Company Public?
                    </Typography>
                    <Checkbox
                      style={{ marginLeft: "10px" }}
                      checked={isPublic}
                      onChange={handleIsCompanyPublicChange}
                      color="primary"
                    />
                  </div>
                  {isPublic && (
                    <TextField
                      fullWidth
                      label="Ticker*"
                      variant="outlined"
                      value={tickerValue}
                      onChange={handleTickerValueChange}
                      sx={inputStyle}
                    />
                  )}
                </FormControl>
                <FormControl
                  sx={{display:"flex" ,flexDirection:"column"}}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "4px",
                      width:"270px",
                    }}
                  >
                    <Typography variant="body1" sx={{ marginTop: "9px",marginRight:"auto",}}>
                      Add a parent company?
                    </Typography>
                    <Checkbox
                      style={{ marginLeft: "10px" }}
                      checked={isParentCompany}
                      onChange={handleIsParentCompanyChange}
                      color="primary"
                    />
                  </div>
                  {isParentCompany && (
                    <>
                <TextField
                  id="companyName"
                  helperText={error ? errorTxt : ""}
                  label="Parent Company Name *"
                  variant="outlined"
                  defaultValue={companyInfo?.companyName}
                  value={parentCompanyNameValue}
                  sx={inputStyle}
                  onChange={handleParentCompanyNameChange}
                  error={error}
                />
                <TextField
                  id="companyCountry"
                  label="Parent Company Country *"
                  variant="outlined"
                  helperText={error ? errorTxt : ""}
                  value={parentCompanyCountryValue}
                  onChange={handleParentCompanyCountryValueChange}
                  error={error}
                  sx={inputStyle}
                />
                <FormControl
                  sx={{display:"flex" ,flexDirection:"column"}}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "4px",
                      width:"270px",
                    }}
                  >
                    <Typography variant="body1" sx={{ marginTop: "9px",marginRight:"auto",}}>
                      Is Parent Company Public?
                    </Typography>
                    <Checkbox
                      style={{ marginLeft: "10px" }}
                      checked={isParentPublic}
                      onChange={handleIsParentCompanyPublicChange}
                      color="primary"
                    />
                  </div>
                  {isParentPublic && (
                    <TextField
                      fullWidth
                      label="Parent Company Ticker*"
                      variant="outlined"
                      value={parentTickerValue}
                      onChange={handleParentTickerValueChange}
                      sx={inputStyle}
                    />
                  )}
                </FormControl>
                    </>
                  )}
                </FormControl>
              </React.Fragment>
            ) : (
              <List style={{ maxHeight: "400px", overflow: "auto" }}>
                {companySearchResults?.map((company, index) => {
                  return (
                    <ListItemButton
                      key={index}
                      style={{ border: 1 }}
                      selected={
                        selectedCompany?.name?.toString() ===
                        company?.name?.toString()
                      }
                      onClick={() => {
                        selectCompany(company);
                      }}
                    >
                      <ListItemText
                        primary={company?.name}
                        secondary={company?.country}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            )}
            <Stack direction="row"  my={1}>
              <Button variant="contained" sx={buttonStyle}  onClick={handleAddCompanyClick} disabled={(isParentPublic&&parentTickerValue?.length===0)||(isPublic&&tickerValue?.length===0)||loading}>
                Add
              </Button>
              <Button variant="contained" sx={buttonStyle}  onClick={handleCloseClick}>
                Close
              </Button>
            </Stack>
            <Box>
              {companySearchResults === undefined ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                >
                  No Results Found.
                </p>
              ) : null}
            </Box>
        </Box>
      </Modal>
      <Dialog
        open={parentCompanyDailog}
        // onClose={toggleAddCompanyManuallyModal}
      >
        <DialogTitle>{"Would you like to add this company without a parent company?"}</DialogTitle>
        <DialogContent>
          <Typography>
            Adding a company without a parent company will create a new record.
            Are you sure you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button  color="primary">
            Yes
          </Button>
          <Button color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddCompanyManually;
