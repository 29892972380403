import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';

export default function CompanySummaryEvents({ company }) {
  const getSignificantEvents = (summary) => {
    if (!summary) return [];
    if (
      summary.includes("No significant events were reported at this time.") ||
      summary.includes("No significant events or news were reported at this time.") ||
      summary.includes("No news available.") ||
      summary.includes("No significant events found.")
    ) {
      return [];
    }
    const lines = summary.split("\\n");
    const significantEventsIndex = lines.findIndex(line => line.trim().toUpperCase().includes("3. SIGNIFICANT EVENTS"));
  
    if (significantEventsIndex === -1) return [];
  
    const events = [];
    for (let i = significantEventsIndex + 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line.startsWith("1.") || line.startsWith("2.") || line.startsWith("4.")) {
        break;
      }
      // Remove leading dashes and extra spaces
      const cleanedLine = line.replace(/^\s*-\s*/, '');
      if (cleanedLine) {
        events.push(cleanedLine.replace(/\"$/, ''));
      }
    }
  
    return events;
  };
  
  

  const significantEvents = getSignificantEvents(company?.summary);

  const CustomIcon = () => (
    <Box
      sx={{
        width: 30,
        height: 30,
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.29)',
      }}
    >
      <ExpandMoreIcon style={{ color: "#5186EC" }} />
    </Box>
  );

  return (
    <div style={{ paddingLeft: "25px", paddingRight: "25px" }}>
      <Accordion
        defaultExpanded
        width="90%"
        style={{
          background: "#FAFAFC",
          boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
          borderRadius: "8px",
          opacity: "1",
        }}
      >
        <AccordionSummary
          expandIcon={<CustomIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{
              width: "290px",
              height: "24px",
              fontSize: "20px",
              fontWeight: "500",
              color: "#1A2A56",
            }}
          >
            Events
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {significantEvents.length > 0 ? (
            significantEvents.map((event, index) => (
              <Typography
                key={index}
                style={{
                  color: "#777A82",
                  textAlign: "left",
                  fontSize: "14px",
                  paddingLeft: "17.6px",
                  paddingTop: index === 0 ? "0px" : "15px",
                }}
              >
                <CircleIcon style={{ height: '11px' }} /> {event}
              </Typography>
            ))
          ) : (
            <Typography
              style={{
                color: "#777A82",
                textAlign: "left",
                fontSize: "14px",
                paddingLeft: "17.6px",
              }}
            >
              No significant events found.
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}