import {
  Autocomplete,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  getRiskyCustomerRiskSegment,
  getRiskyCustomerPaydex,
  getRiskyCustomerRiskScore,
} from "./utils/HelperRC";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import dollar from "./Assets/Images/dollar.svg";
import company from "./Assets/Images/company.svg";

function formatNumber(value) {
  return isNaN(value) || !isFinite(value)
    ? "0"
    : Math.round(value).toLocaleString();
}

const getTotalForAmountReceivableRiskSegment = (data) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    aging30: 0,
    customerWithAging30: 0,
    aging60: 0,
    customerWithAging60: 0,
    aging90: 0,
    customerWithAging90: 0,
    aging90Plus: 0,
    customerWithAging90Plus: 0,

    totalRS2Point5: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRS5: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRS7Point5: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalRS7Point5Greater: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
  };

  data.forEach((item) => {
    if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;
    }

    if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
      totals.aging30 += item["AR Balance Aging 30"];
      totals.customerWithAging30 += 1;
    }

    if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
      totals.aging60 += item["AR Balance Aging 60"];
      totals.customerWithAging60 += 1;
    }

    if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
      totals.aging90 += item["AR Balance Aging 90"];
      totals.customerWithAging90 += 1;
    }

    if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
      totals.aging90Plus += item["AR Balance Aging 90+"];
      totals.customerWithAging90Plus += 1;
    }

    if (item["Risk Segment"] <= 2.5 && item["Risk Segment"] >= 0) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRS2Point5.totalARBalance += item["Total AR Balance"];
        totals.totalRS2Point5.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalRS2Point5.currentARBalance += item["Current AR Balance"];
        totals.totalRS2Point5.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRS2Point5.aging30 += item["AR Balance Aging 30"];
        totals.totalRS2Point5.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRS2Point5.aging60 += item["AR Balance Aging 60"];
        totals.totalRS2Point5.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRS2Point5.aging90 += item["AR Balance Aging 90"];
        totals.totalRS2Point5.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 90+"] > 0 ||
        item["AR Balance Aging 90+"] < 0
      ) {
        totals.totalRS2Point5.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRS2Point5.customerWithAging90Plus += 1;
      }
    }

    if (item["Risk Segment"] <= 5 && item["Risk Segment"] > 2.5) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRS5.totalARBalance += item["Total AR Balance"];
        totals.totalRS5.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalRS5.currentARBalance += item["Current AR Balance"];
        totals.totalRS5.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRS5.aging30 += item["AR Balance Aging 30"];
        totals.totalRS5.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRS5.aging60 += item["AR Balance Aging 60"];
        totals.totalRS5.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRS5.aging90 += item["AR Balance Aging 90"];
        totals.totalRS5.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 90+"] > 0 ||
        item["AR Balance Aging 90+"] < 0
      ) {
        totals.totalRS5.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRS5.customerWithAging90Plus += 1;
      }
    }

    if (item["Risk Segment"] <= 7.5 && item["Risk Segment"] > 5) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRS7Point5.totalARBalance += item["Total AR Balance"];
        totals.totalRS7Point5.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalRS7Point5.currentARBalance += item["Current AR Balance"];
        totals.totalRS7Point5.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRS7Point5.aging30 += item["AR Balance Aging 30"];
        totals.totalRS7Point5.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRS7Point5.aging60 += item["AR Balance Aging 60"];
        totals.totalRS7Point5.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRS7Point5.aging90 += item["AR Balance Aging 90"];
        totals.totalRS7Point5.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 90+"] > 0 ||
        item["AR Balance Aging 90+"] < 0
      ) {
        totals.totalRS7Point5.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalRS7Point5.customerWithAging90Plus += 1;
      }
    }

    if (item["Risk Segment"] > 7.5) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalRS7Point5Greater.totalARBalance += item["Total AR Balance"];
        totals.totalRS7Point5Greater.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalRS7Point5Greater.currentARBalance +=
          item["Current AR Balance"];
        totals.totalRS7Point5Greater.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalRS7Point5Greater.aging30 += item["AR Balance Aging 30"];
        totals.totalRS7Point5Greater.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalRS7Point5Greater.aging60 += item["AR Balance Aging 60"];
        totals.totalRS7Point5Greater.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalRS7Point5Greater.aging90 += item["AR Balance Aging 90"];
        totals.totalRS7Point5Greater.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 90+"] > 0 ||
        item["AR Balance Aging 90+"] < 0
      ) {
        totals.totalRS7Point5Greater.aging90Plus +=
          item["AR Balance Aging 90+"];
        totals.totalRS7Point5Greater.customerWithAging90Plus += 1;
      }
    }
  });

  const rowDataAR = [
    {
      id: 1,
      metric: "Current AR Balance",
      customer: formatNumber(totals.customerWithCurrentAR),
      total: formatNumber(totals.currentARBalance),
      totalRS2Point5: formatNumber(totals.totalRS2Point5.currentARBalance),
      customerRS2Point5: formatNumber(
        totals.totalRS2Point5.customerWithCurrentAR
      ),
      totalRS5: formatNumber(totals.totalRS5.currentARBalance),
      customerRS5: formatNumber(totals.totalRS5.customerWithCurrentAR),
      totalRS7Point5: formatNumber(totals.totalRS7Point5.currentARBalance),
      customerRS7Point5: formatNumber(
        totals.totalRS7Point5.customerWithCurrentAR
      ),
      totalRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.currentARBalance
      ),
      customerRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.customerWithCurrentAR
      ),
    },
    {
      id: 2,
      metric: "AR Balance Aging 30",
      customer: formatNumber(totals.customerWithAging30),
      total: formatNumber(totals.aging30),
      totalRS2Point5: formatNumber(totals.totalRS2Point5.aging30),
      customerRS2Point5: formatNumber(
        totals.totalRS2Point5.customerWithAging30
      ),
      totalRS5: formatNumber(totals.totalRS5.aging30),
      customerRS5: formatNumber(totals.totalRS5.customerWithAging30),
      totalRS7Point5: formatNumber(totals.totalRS7Point5.aging30),
      customerRS7Point5: formatNumber(
        totals.totalRS7Point5.customerWithAging30
      ),
      totalRS7Point5Greater: formatNumber(totals.totalRS7Point5Greater.aging30),
      customerRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.customerWithAging30
      ),
    },
    {
      id: 3,
      metric: "AR Balance Aging 60",
      customer: formatNumber(totals.customerWithAging60),
      total: formatNumber(totals.aging60),
      totalRS2Point5: formatNumber(totals.totalRS2Point5.aging60),
      customerRS2Point5: formatNumber(
        totals.totalRS2Point5.customerWithAging60
      ),
      totalRS5: formatNumber(totals.totalRS5.aging60),
      customerRS5: formatNumber(totals.totalRS5.customerWithAging60),
      totalRS7Point5: formatNumber(totals.totalRS7Point5.aging60),
      customerRS7Point5: formatNumber(
        totals.totalRS7Point5.customerWithAging60
      ),
      totalRS7Point5Greater: formatNumber(totals.totalRS7Point5Greater.aging60),
      customerRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.customerWithAging60
      ),
    },
    {
      id: 4,
      metric: "AR Balance Aging 90",
      customer: formatNumber(totals.customerWithAging90),
      total: formatNumber(totals.aging90),
      totalRS2Point5: formatNumber(totals.totalRS2Point5.aging90),
      customerRS2Point5: formatNumber(
        totals.totalRS2Point5.customerWithAging90
      ),
      totalRS5: formatNumber(totals.totalRS5.aging90),
      customerRS5: formatNumber(totals.totalRS5.customerWithAging90),
      totalRS7Point5: formatNumber(totals.totalRS7Point5.aging90),
      customerRS7Point5: formatNumber(
        totals.totalRS7Point5.customerWithAging90
      ),
      totalRS7Point5Greater: formatNumber(totals.totalRS7Point5Greater.aging90),
      customerRS7Point5Greater: formatNumber(
        totals.totalRS7Point5Greater.customerWithAging90
      ),
    },
  ];

  return rowDataAR;
};

const getTotalForAmountReceivablePaydex = (data) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    aging30: 0,
    customerWithAging30: 0,
    aging60: 0,
    customerWithAging60: 0,
    aging90: 0,
    customerWithAging90: 0,
    aging90Plus: 0,
    customerWithAging90Plus: 0,

    totalLow: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalMedium: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalHigh: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
  };

  data.forEach((item) => {
    if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;
    }

    if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
      totals.aging30 += item["AR Balance Aging 30"];
      totals.customerWithAging30 += 1;
    }

    if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
      totals.aging60 += item["AR Balance Aging 60"];
      totals.customerWithAging60 += 1;
    }

    if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
      totals.aging90 += item["AR Balance Aging 90"];
      totals.customerWithAging90 += 1;
    }

    if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
      totals.aging90Plus += item["AR Balance Aging 90+"];
      totals.customerWithAging90Plus += 1;
    }

    // Paydex Ranges
    if (item["Paydex"] >= 1 && item["Paydex"] < 4) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalLow.totalARBalance += item["Total AR Balance"];
        totals.totalLow.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalLow.currentARBalance += item["Current AR Balance"];
        totals.totalLow.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalLow.aging30 += item["AR Balance Aging 30"];
        totals.totalLow.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalLow.aging60 += item["AR Balance Aging 60"];
        totals.totalLow.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalLow.aging90 += item["AR Balance Aging 90"];
        totals.totalLow.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 90+"] > 0 ||
        item["AR Balance Aging 90+"] < 0
      ) {
        totals.totalLow.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalLow.customerWithAging90Plus += 1;
      }
    }

    if (item["Paydex"] >= 4 && item["Paydex"] < 7) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalMedium.totalARBalance += item["Total AR Balance"];
        totals.totalMedium.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalMedium.currentARBalance += item["Current AR Balance"];
        totals.totalMedium.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalMedium.aging30 += item["AR Balance Aging 30"];
        totals.totalMedium.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalMedium.aging60 += item["AR Balance Aging 60"];
        totals.totalMedium.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalMedium.aging90 += item["AR Balance Aging 90"];
        totals.totalMedium.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 90+"] > 0 ||
        item["AR Balance Aging 90+"] < 0
      ) {
        totals.totalMedium.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalMedium.customerWithAging90Plus += 1;
      }
    }

    if (item["Paydex"] >= 7 && item["Paydex"] <= 10) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalHigh.totalARBalance += item["Total AR Balance"];
        totals.totalHigh.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalHigh.currentARBalance += item["Current AR Balance"];
        totals.totalHigh.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalHigh.aging30 += item["AR Balance Aging 30"];
        totals.totalHigh.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalHigh.aging60 += item["AR Balance Aging 60"];
        totals.totalHigh.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalHigh.aging90 += item["AR Balance Aging 90"];
        totals.totalHigh.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 90+"] > 0 ||
        item["AR Balance Aging 90+"] < 0
      ) {
        totals.totalHigh.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalHigh.customerWithAging90Plus += 1;
      }
    }
  });

  const rowDataPaydex = [
    {
      id: 1,
      metric: "Current AR Balance",
      customer: formatNumber(totals.customerWithCurrentAR),
      total: formatNumber(totals.currentARBalance),
      totalLow: formatNumber(totals.totalLow.currentARBalance),
      customerLow: formatNumber(totals.totalLow.customerWithCurrentAR),
      totalMedium: formatNumber(totals.totalMedium.currentARBalance),
      customerMedium: formatNumber(totals.totalMedium.customerWithCurrentAR),
      totalHigh: formatNumber(totals.totalHigh.currentARBalance),
      customerHigh: formatNumber(totals.totalHigh.customerWithCurrentAR),
    },
    {
      id: 2,
      metric: "AR Balance Aging 30",
      customer: formatNumber(totals.customerWithAging30),
      total: formatNumber(totals.aging30),
      totalLow: formatNumber(totals.totalLow.aging30),
      customerLow: formatNumber(totals.totalLow.customerWithAging30),
      totalMedium: formatNumber(totals.totalMedium.aging30),
      customerMedium: formatNumber(totals.totalMedium.customerWithAging30),
      totalHigh: formatNumber(totals.totalHigh.aging30),
      customerHigh: formatNumber(totals.totalHigh.customerWithAging30),
    },
    {
      id: 3,
      metric: "AR Balance Aging 60",
      customer: formatNumber(totals.customerWithAging60),
      total: formatNumber(totals.aging60),
      totalLow: formatNumber(totals.totalLow.aging60),
      customerLow: formatNumber(totals.totalLow.customerWithAging60),
      totalMedium: formatNumber(totals.totalMedium.aging60),
      customerMedium: formatNumber(totals.totalMedium.customerWithAging60),
      totalHigh: formatNumber(totals.totalHigh.aging60),
      customerHigh: formatNumber(totals.totalHigh.customerWithAging60),
    },
    {
      id: 4,
      metric: "AR Balance Aging 90",
      customer: formatNumber(totals.customerWithAging90),
      total: formatNumber(totals.aging90),
      totalLow: formatNumber(totals.totalLow.aging90),
      customerLow: formatNumber(totals.totalLow.customerWithAging90),
      totalMedium: formatNumber(totals.totalMedium.aging90),
      customerMedium: formatNumber(totals.totalMedium.customerWithAging90),
      totalHigh: formatNumber(totals.totalHigh.aging90),
      customerHigh: formatNumber(totals.totalHigh.customerWithAging90),
    },
  ];

  return rowDataPaydex;
};

const getTotalForAmountReceivableRiskScore = (data) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    aging30: 0,
    customerWithAging30: 0,
    aging60: 0,
    customerWithAging60: 0,
    aging90: 0,
    customerWithAging90: 0,
    aging90Plus: 0,
    customerWithAging90Plus: 0,

    totalLow: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalMedium: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
    totalHigh: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging90Plus: 0,
      customerWithAging90Plus: 0,
    },
  };

  data.forEach((item) => {
    if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;
    }

    if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
      totals.aging30 += item["AR Balance Aging 30"];
      totals.customerWithAging30 += 1;
    }

    if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
      totals.aging60 += item["AR Balance Aging 60"];
      totals.customerWithAging60 += 1;
    }

    if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
      totals.aging90 += item["AR Balance Aging 90"];
      totals.customerWithAging90 += 1;
    }

    if (item["AR Balance Aging 90+"] > 0 || item["AR Balance Aging 90+"] < 0) {
      totals.aging90Plus += item["AR Balance Aging 90+"];
      totals.customerWithAging90Plus += 1;
    }

    // General Risk Score Ranges
    if (item["General Risk Score"] >= 1 && item["General Risk Score"] < 4) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalLow.totalARBalance += item["Total AR Balance"];
        totals.totalLow.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalLow.currentARBalance += item["Current AR Balance"];
        totals.totalLow.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalLow.aging30 += item["AR Balance Aging 30"];
        totals.totalLow.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalLow.aging60 += item["AR Balance Aging 60"];
        totals.totalLow.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalLow.aging90 += item["AR Balance Aging 90"];
        totals.totalLow.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 90+"] > 0 ||
        item["AR Balance Aging 90+"] < 0
      ) {
        totals.totalLow.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalLow.customerWithAging90Plus += 1;
      }
    }

    if (item["General Risk Score"] >= 4 && item["General Risk Score"] < 7) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalMedium.totalARBalance += item["Total AR Balance"];
        totals.totalMedium.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalMedium.currentARBalance += item["Current AR Balance"];
        totals.totalMedium.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalMedium.aging30 += item["AR Balance Aging 30"];
        totals.totalMedium.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalMedium.aging60 += item["AR Balance Aging 60"];
        totals.totalMedium.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalMedium.aging90 += item["AR Balance Aging 90"];
        totals.totalMedium.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 90+"] > 0 ||
        item["AR Balance Aging 90+"] < 0
      ) {
        totals.totalMedium.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalMedium.customerWithAging90Plus += 1;
      }
    }

    if (item["General Risk Score"] >= 7 && item["General Risk Score"] <= 10) {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalHigh.totalARBalance += item["Total AR Balance"];
        totals.totalHigh.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalHigh.currentARBalance += item["Current AR Balance"];
        totals.totalHigh.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalHigh.aging30 += item["AR Balance Aging 30"];
        totals.totalHigh.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalHigh.aging60 += item["AR Balance Aging 60"];
        totals.totalHigh.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalHigh.aging90 += item["AR Balance Aging 90"];
        totals.totalHigh.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 90+"] > 0 ||
        item["AR Balance Aging 90+"] < 0
      ) {
        totals.totalHigh.aging90Plus += item["AR Balance Aging 90+"];
        totals.totalHigh.customerWithAging90Plus += 1;
      }
    }
  });

  const rowDataRiskScore = [
    {
      id: 1,
      metric: "Current AR Balance",
      customer: formatNumber(totals.customerWithCurrentAR),
      total: formatNumber(totals.currentARBalance),
      totalLow: formatNumber(totals.totalLow.currentARBalance),
      customerLow: formatNumber(totals.totalLow.customerWithCurrentAR),
      totalMedium: formatNumber(totals.totalMedium.currentARBalance),
      customerMedium: formatNumber(totals.totalMedium.customerWithCurrentAR),
      totalHigh: formatNumber(totals.totalHigh.currentARBalance),
      customerHigh: formatNumber(totals.totalHigh.customerWithCurrentAR),
    },
    {
      id: 2,
      metric: "AR Balance Aging 30",
      customer: formatNumber(totals.customerWithAging30),
      total: formatNumber(totals.aging30),
      totalLow: formatNumber(totals.totalLow.aging30),
      customerLow: formatNumber(totals.totalLow.customerWithAging30),
      totalMedium: formatNumber(totals.totalMedium.aging30),
      customerMedium: formatNumber(totals.totalMedium.customerWithAging30),
      totalHigh: formatNumber(totals.totalHigh.aging30),
      customerHigh: formatNumber(totals.totalHigh.customerWithAging30),
    },
    {
      id: 3,
      metric: "AR Balance Aging 60",
      customer: formatNumber(totals.customerWithAging60),
      total: formatNumber(totals.aging60),
      totalLow: formatNumber(totals.totalLow.aging60),
      customerLow: formatNumber(totals.totalLow.customerWithAging60),
      totalMedium: formatNumber(totals.totalMedium.aging60),
      customerMedium: formatNumber(totals.totalMedium.customerWithAging60),
      totalHigh: formatNumber(totals.totalHigh.aging60),
      customerHigh: formatNumber(totals.totalHigh.customerWithAging60),
    },
    {
      id: 4,
      metric: "AR Balance Aging 90",
      customer: formatNumber(totals.customerWithAging90),
      total: formatNumber(totals.aging90),
      totalLow: formatNumber(totals.totalLow.aging90),
      customerLow: formatNumber(totals.totalLow.customerWithAging90),
      totalMedium: formatNumber(totals.totalMedium.aging90),
      customerMedium: formatNumber(totals.totalMedium.customerWithAging90),
      totalHigh: formatNumber(totals.totalHigh.aging90),
      customerHigh: formatNumber(totals.totalHigh.customerWithAging90),
    },
  ];

  return rowDataRiskScore;
};

// Define dropdown options
const xAxisOptions = [
  { id: 1, label: "Risk Score" },
  { id: 2, label: "Paydex" },
  { id: 3, label: "Risk Segment" },
];

const ARHeatmapNew = ({ summary, adminAction }) => {
  const [selectedOption, setSelectedOption] = useState(xAxisOptions[0]);
  const handleDropdownChange = (event, newValue) => {
    if (newValue) {
      setSelectedOption(newValue);
    }
  };

  const getSegmentLabels = (type) => {
    switch (type) {
      case 1:
      case 2:
        return ["1-4 (Low)", "4-7 (Medium)", "7-10 (High)"];
      case 3:
        return [
          "0<=2.5 (Low)",
          "2.5-5 (Medium-Low)",
          "5-7.5 (Medium-High)",
          ">7.5 (High)",
        ];
      default:
        return [];
    }
  };

  const agingCategories = [
    "Current AR Balance",
    "AR Balance Aging 30",
    "AR Balance Aging 60",
    "AR Balance Aging 90",
  ];

  const constructData = (data, scoreType) => {
    console.log("Input data:", JSON.stringify(data, null, 2));
    console.log("Score type:", scoreType);

    const segmentLabels = getSegmentLabels(selectedOption.id);
    console.log("Segment labels:", segmentLabels);

    let pivotedData = segmentLabels.map((label) => ({ segment: label }));

    const agingCategories = [
      "Current AR Balance",
      "AR Balance Aging 30",
      "AR Balance Aging 60",
      "AR Balance Aging 90",
    ];

    if (scoreType === "Risk Segment") {
      agingCategories.forEach((category, index) => {
        const item = data[index];
        pivotedData[0][category] = [
          parseFloat(item.totalRS2Point5.replace(/,/g, "")),
          parseInt(item.customerRS2Point5.replace(/,/g, "")),
        ];
        pivotedData[1][category] = [
          parseFloat(item.totalRS5.replace(/,/g, "")),
          parseInt(item.customerRS5.replace(/,/g, "")),
        ];
        pivotedData[2][category] = [
          parseFloat(item.totalRS7Point5.replace(/,/g, "")),
          parseInt(item.customerRS7Point5.replace(/,/g, "")),
        ];
        pivotedData[3][category] = [
          parseFloat(item.totalRS7Point5Greater.replace(/,/g, "")),
          parseInt(item.customerRS7Point5Greater.replace(/,/g, "")),
        ];
      });
    } else {
      agingCategories.forEach((category, index) => {
        const item = data[index];
        pivotedData[0][category] = [
          parseFloat(item.totalLow.replace(/,/g, "")),
          parseInt(item.customerLow.replace(/,/g, "")),
        ];
        pivotedData[1][category] = [
          parseFloat(item.totalMedium.replace(/,/g, "")),
          parseInt(item.customerMedium.replace(/,/g, "")),
        ];
        pivotedData[2][category] = [
          parseFloat(item.totalHigh.replace(/,/g, "")),
          parseInt(item.customerHigh.replace(/,/g, "")),
        ];
      });
    }

    // Add total row
    const totalRow = { segment: "Total" };
    agingCategories.forEach((category) => {
      const totalAmount = pivotedData.reduce(
        (sum, row) => sum + row[category][0],
        0
      );
      const totalCustomers = pivotedData.reduce(
        (sum, row) => sum + row[category][1],
        0
      );
      totalRow[category] = [totalAmount, totalCustomers];
    });
    pivotedData.push(totalRow);

    console.log("Pivoted data:", JSON.stringify(pivotedData, null, 2));

    return pivotedData;
  };

  let segmentLabels = [];
  let data = [];

  if (selectedOption.id === 1) {
    const segmentData = getTotalForAmountReceivableRiskScore(summary);
    segmentLabels = getSegmentLabels(selectedOption.id);
    data = constructData(segmentData, selectedOption.label);
  } else if (selectedOption.id === 2) {
    const segmentData = getTotalForAmountReceivablePaydex(summary);
    segmentLabels = getSegmentLabels(selectedOption.id);
    data = constructData(segmentData, selectedOption.label);
  } else {
    const segmentData = getTotalForAmountReceivableRiskSegment(summary);
    segmentLabels = getSegmentLabels(selectedOption.id);
    data = constructData(segmentData, selectedOption.label);
  }

  console.log("Final data:", JSON.stringify(data, null, 2));

  const handleOnClick = (segmentIndex, category) => {
    if (data[segmentIndex].segment === "Total") return;

    const segmentLabels = getSegmentLabels(selectedOption.id);
    const segment = segmentLabels[segmentIndex];

    let filteredSummary = [];

    if (selectedOption.id === 3) {
      const segmentData = getRiskyCustomerRiskSegment(
        summary,
        segmentIndex + 1
      );
      filteredSummary = segmentData.filter((item) => item[category] > 0);
    } else if (selectedOption.id === 2) {
      const segmentData = getRiskyCustomerPaydex(summary, segmentIndex + 1);
      filteredSummary = segmentData.filter((item) => item[category] > 0);
    } else {
      const segmentData = getRiskyCustomerRiskScore(summary, segmentIndex + 1);
      filteredSummary = segmentData.filter((item) => item[category] > 0);
    }

    localStorage.setItem("segmentName", JSON.stringify(selectedOption.label));
    localStorage.setItem("rangeTypeName", JSON.stringify(segment));
    localStorage.setItem("metricName", JSON.stringify(category));
    localStorage.setItem("filteredSummary", JSON.stringify(filteredSummary));

    const selectedTenant = getTenantIdFromURL();
    if (adminAction === true)
      window.open(
        `/tenant-dashboard/${selectedTenant}/filteredSummary`,
        "_blank"
      );
    else window.open("/filteredSummary", "_blank");
  };

  const cardStyle = {
    background: "#FAFAFC 0% 0% no-repeat padding-box",
    boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
    borderRadius: "8px",
    padding: "20px 20px 0 20px",
    position: "relative",
    marginTop: "15px",
  };

  return (
    <Card sx={cardStyle}>
      <Typography variant="h5" sx={{ fontWeight: 500 }} mb={2}>
        AR Heat Map
      </Typography>
      <Autocomplete
        size="small"
        sx={{ width: 250, my: 5 }}
        value={selectedOption}
        onChange={handleDropdownChange}
        options={xAxisOptions}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} label="Segmentation Type" variant="outlined" />
        )}
      />
      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Segment</TableCell>
              {agingCategories.map((category) => (
                <TableCell key={category}>{category}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.segment}>
                <TableCell>{row.segment}</TableCell>
                {agingCategories.map((category) => (
                  <TableCell
                    key={category}
                    onClick={() =>
                      row.segment !== "Total" &&
                      row[category] &&
                      row[category][0]
                        ? handleOnClick(index, category)
                        : null
                    }
                    sx={{
                      cursor:
                        row.segment !== "Total" &&
                        row[category] &&
                        row[category][0]
                          ? "pointer"
                          : "default",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px" }}>
                      <img src={dollar} style={{ display: "inline" }}></img>
                      {row[category] && row[category][0]
                        ? row[category][0].toLocaleString()
                        : 0}
                    </Typography>
                    {
                      <Typography sx={{ fontSize: "12px" }} variant="caption">
                        <img
                          src={company}
                          style={{ display: "inline", marginRight: "1px" }}
                        ></img>
                        {row[category] && row[category][1]
                          ? row[category][1]
                          : 0}
                      </Typography>
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default ARHeatmapNew;
