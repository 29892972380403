import { Box } from '@mui/material'
import React from 'react'
import { useSearchQuery } from '../context/searchContext'
import SearchIcon from "../../src/Assets/Images/search.svg"



const SearchBar = () => {
    const {searchQuery,setSearchQuery}= useSearchQuery();
    const searchStyle={
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '8px',
        opacity: 1,
        border: 'none',
        width:"220px",
        height:"36px",
        paddingLeft:"10px",
        color:"#2F3D63",
        outline: "none",
    }
  
    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);        
      };

  return (
    <Box sx={{mx:3,}}>
    <div style={{position:"relative",width:"220px",height:"36px"}}>
    <input
      style={searchStyle}
      value={searchQuery}
      placeholder='Search'
      onChange={handleSearchChange}
      />
      <img src={SearchIcon} style={{position:"absolute",top:"7px",right:"7px",width:"22px",height:"22px"}} alt="" />
      </div>
  </Box>
  )
}

export default SearchBar