import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  IconButton,
  Modal,
  Button,
  TextField,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import ConfirmationDialog from './ConfirmationDialog'; 
import { API, graphqlOperation } from 'aws-amplify'; 
import { addNewsLink, getCompany } from "./graphql/queries";
import { deleteGoogleNews } from "./graphql/mutations";


export default function News({ company }) {
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
  const [newsLinkToDelete, setNewsLinkToDelete] = React.useState(null);
  const [newUrl, setNewUrl] = React.useState('');
  const [newsData, setNewsData] = React.useState([]);
  const [googleSearch, setGoogleSearch] = React.useState({});
  const [loading,setLoading] = React.useState(false)

  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  React.useEffect(() => {

    refreshNewsData();
  }, [company]);

  const handleOpenConfirmationDialog = (newsLink) => {
    setNewsLinkToDelete(newsLink);
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    setNewsLinkToDelete(null);
  };
  const handleAddNewsLink = async () => {
    try {
      setLoading(true)
      await API.graphql(
        graphqlOperation(addNewsLink, {
          tenantId: googleSearch.tenantId,
          url: newUrl,
          googleSearchNewsId: googleSearch.id,
        })
      );
      setNewUrl('');
      setLoading(false)
      handleCloseAddModal();
      refreshNewsData(); 
    } catch (error) {
      console.log("Error adding news link", error);
    }
  };

  const handleDeleteNewsLink = async () => {
    try {
      await API.graphql(
        graphqlOperation(deleteGoogleNews, {
          input: { tenantId: newsLinkToDelete.tenantId, id: newsLinkToDelete.id },
        })
      );
      handleCloseConfirmationDialog();
      refreshNewsData(); 
    } catch (error) {
      console.log("Error deleting news link", error);
    }
  };
  const parseDate = (dateString) => {
    return new Date(dateString);
  };

  const refreshNewsData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getCompany, {
          tenantId: company.tenantId,
          id: company.id,
        })
      );
      if (result.data && result.data.getCompany && result.data.getCompany.googleSearch) {
        const searchData = result.data.getCompany.googleSearch;
        setGoogleSearch(searchData);
        searchData.news.items.sort((a, b) => {
          const dateA = parseDate(a.date);
          const dateB = parseDate(b.date);
        
          return dateB - dateA; 
        });
        setNewsData(searchData.news.items);
        setGoogleSearch(searchData)
      } else {
        console.log("No news data available.");
      }
    } catch (error) {
      console.log("Error fetching news data", error);
    }
  };

  const CustomIcon = () => (
    <Box
      sx={{
        width: 30,
        height: 30,
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.29)',
      }}
    >
      <ExpandMoreIcon style={{ color: "#5186EC" }} />
    </Box>
  );

  function extractKeywordsFromUrl(url) {
    const keywordMatches = url.match(
      /\/(materials|material|news|articles|article|blog|blogs)\//
    );

    if (keywordMatches && keywordMatches[1]) {
      if (
        keywordMatches[1] === "materials" ||
        keywordMatches[1] === "material"
      ) {
        return "Materials";
      } else if (keywordMatches[1] === "news") {
        return "News";
      } else if (
        keywordMatches[1] === "article" ||
        keywordMatches[1] === "articles"
      ) {
        return "Articles";
      } else if (
        keywordMatches[1] === "blog" ||
        keywordMatches[1] === "blogs"
      ) {
        return "Blogs";
      }
    } else {
      return ""; 
    }
  }

  return (
    <div style={{ paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px" }}>
      {newsData.length > 0 && <Accordion
        width="90%"
        defaultExpanded
        sx={{
          background: "#FAFAFC",
          boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
          borderRadius: "8px",
          opacity: "1",
        }}
      >
        <AccordionSummary
          expandIcon={<CustomIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{
              width: "290px",
              height: "24px",
              fontSize: "20px",
              fontWeight: "500",
              color: "#1A2A56",
            }}
          >
            News
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
  <List>
  <ListItem>
        <Box sx={{display:"flex",gap:"10px",cursor:'pointer', mx: "auto"}} onClick={handleOpenAddModal}>
        <IconButton aria-label="add-url">
            <AddCircleOutlineOutlined sx={{ color: "#f0f0f0" }} />
          </IconButton>
        </Box>
      </ListItem>
    {newsData.map((row) => (
      <ListItem key={row.id}>
        <ListItemText>
          <Tooltip title={row.link} arrow>
            <Typography
              as="a"
              href={row.link}
              target="_blank"
              sx={{ cursor: "pointer" }}
              style={{
                textAlign: "left",
                textDecoration: "underline",
                fontFamily: "Rubik , sans-serif",
                letterSpacing: "0px",
                opacity: 1,
                color: "#5186EC",
                fontSize: "16px",
                padding: "10px",
                display: "block",
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word"
              }}
            >
              {row.title}
            </Typography>
          </Tooltip>
          <Typography
            style={{
              textAlign: "left",
              fontFamily: "Rubik, sans-serif",
              letterSpacing: "0px",
              padding: "10px",
              color: "#777A82"
            }}
          >
            {row.snippet}
          </Typography>
          {row.date && (
            <Typography
              style={{
                textAlign: "left",
                fontFamily: "Rubik, sans-serif",
                letterSpacing: "0px",
                color: "#777A82",
                fontWeight: "500",
                display: "flex",
                alignContent: "center",
                padding: "10px",
              }}
            >
              Date:
              <Typography style={{ marginLeft: "4px" }}>{row.date}</Typography>
            </Typography>
          )}
          <Typography
            style={{
              textAlign: "left",
              fontFamily: "Rubik, sans-serif",
              letterSpacing: "0px",
              color: "#777A82",
              fontWeight: "500",
              display: "flex",
              alignContent: "center",
              padding: "10px"
            }}
          >
            Source: <Typography style={{ marginLeft: "4px" }}>{row.source}</Typography>
          </Typography>
          {extractKeywordsFromUrl(row?.link) && (
            <Typography
              style={{
                textAlign: "left",
                fontFamily: "Rubik, sans-serif",
                letterSpacing: "0px",
                color: "#777A82",
                fontWeight: "500",
                display: "flex",
                alignContent: "center",
                padding: "10px"
              }}
            >
              Source Type: <Typography style={{ marginLeft: "4px" }}>{extractKeywordsFromUrl(row?.link)}</Typography>
            </Typography>
          )}
        </ListItemText>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => handleOpenConfirmationDialog(row)}
        >
          <DeleteOutlinedIcon sx={{ color: "#ebeced" }} />
        </IconButton>
      </ListItem>
    ))}
  </List>
</AccordionDetails>
      </Accordion>}
      
      <Modal
        open={openAddModal}
        onClose={handleCloseAddModal}
      >
        <Box sx={{ width: '300px', padding: '20px', margin: '100px auto',background: "#FAFAFC",
          boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
          borderRadius: "8px",
          opacity: "1", }}>
          <TextField
            label="News URL"
            variant="outlined"
            fullWidth
            value={newUrl}
            onChange={(e) => setNewUrl(e.target.value)}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <Button onClick={handleCloseAddModal}>Cancel</Button>
            <Button onClick={handleAddNewsLink} disabled={loading} color="primary">Add</Button>
          </Box>
        </Box>
      </Modal>

      <ConfirmationDialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
        onConfirm={handleDeleteNewsLink}
        title="Confirmation"
        message="Are you sure you want to delete this news link?"
        sx={{
          background: "#FAFAFC",
          boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
          borderRadius: "8px",
          opacity: "1",
        }}
      />
    </div>
  );
}