import { getTotalForAmountReceivableRNOrCN } from "./HelperAR";
import { segmentationTypesEnum } from "./SegmentationTypes";

export const getCollectionTableRowsAndColumn = (data) => {
  const CNData = getTotalForAmountReceivableRNOrCN(
    data,
    segmentationTypesEnum.CURRENT_NET.label
  );

  const rows = [
    { path: "Net 30", id: 0 },
    {
      id: 1,
      path: "Net 30/Total Sum",
      totalAR: CNData[0].totalRN30,
      currentAR: CNData[1].totalRN30,
      aging30AR: CNData[2].totalRN30,
      aging60AR: CNData[3].totalRN30,
      aging90AR: CNData[4].totalRN30,
      aging90PlusAR: CNData[5].totalRN30,
      rangeId: 1,
    },
    {
      id: 2,
      path: "Net 30/Number of customer",
      totalAR: CNData[0].customerRN30,
      currentAR: CNData[1].customerRN30,
      aging30AR: CNData[2].customerRN30,
      aging60AR: CNData[3].customerRN30,
      aging90AR: CNData[4].customerRN30,
      aging90PlusAR: CNData[5].customerRN30,
      rangeId: 1,
    },

    { path: "Net 45", id: 3 },
    {
      id: 4,
      path: "Net 45/Total Sum",
      totalAR: CNData[0].totalRN45,
      currentAR: CNData[1].totalRN45,
      aging30AR: CNData[2].totalRN45,
      aging60AR: CNData[3].totalRN45,
      aging90AR: CNData[4].totalRN45,
      aging90PlusAR: CNData[5].totalRN45,
      rangeId: 2,
    },
    {
      id: 5,
      path: "Net 45/Number of customer",
      totalAR: CNData[0].customerRN45,
      currentAR: CNData[1].customerRN45,
      aging30AR: CNData[2].customerRN45,
      aging60AR: CNData[3].customerRN45,
      aging90AR: CNData[4].customerRN45,
      aging90PlusAR: CNData[5].customerRN45,
      rangeId: 2,
    },

    { path: "Net 60", id: 6 },
    {
      id: 7,
      path: "Net 60/Total Sum",
      totalAR: CNData[0].totalRN60,
      currentAR: CNData[1].totalRN60,
      aging30AR: CNData[2].totalRN60,
      aging60AR: CNData[3].totalRN60,
      aging90AR: CNData[4].totalRN60,
      aging90PlusAR: CNData[5].totalRN60,
      rangeId: 3,
    },
    {
      id: 8,
      path: "Net 60/Number of customer",
      totalAR: CNData[0].customerRN60,
      currentAR: CNData[1].customerRN60,
      aging30AR: CNData[2].customerRN60,
      aging60AR: CNData[3].customerRN60,
      aging90AR: CNData[4].customerRN60,
      aging90PlusAR: CNData[5].customerRN60,
      rangeId: 3,
    },

    { path: "Net 90", id: 9 },
    {
      id: 10,
      path: "Net 90/Total Sum",
      totalAR: CNData[0].totalRN90,
      currentAR: CNData[1].totalRN90,
      aging30AR: CNData[2].totalRN90,
      aging60AR: CNData[3].totalRN90,
      aging90AR: CNData[4].totalRN90,
      aging90PlusAR: CNData[5].totalRN90,
      rangeId: 4,
    },
    {
      id: 11,
      path: "Net 90/Number of customer",
      totalAR: CNData[0].customerRN90,
      currentAR: CNData[1].customerRN90,
      aging30AR: CNData[2].customerRN90,
      aging60AR: CNData[3].customerRN90,
      aging90AR: CNData[4].customerRN90,
      aging90PlusAR: CNData[5].customerRN90,
      rangeId: 4,
    },

    { path: "Net 120", id: 12 },
    {
      id: 13,
      path: "Net 120/Total Sum",
      totalAR: CNData[0].totalRN120,
      currentAR: CNData[1].totalRN120,
      aging30AR: CNData[2].totalRN120,
      aging60AR: CNData[3].totalRN120,
      aging90AR: CNData[4].totalRN120,
      aging90PlusAR: CNData[5].totalRN120,
      rangeId: 5,
    },
    {
      id: 14,
      path: "Net 120/Number of customer",
      totalAR: CNData[0].customerRN120,
      currentAR: CNData[1].customerRN120,
      aging30AR: CNData[2].customerRN120,
      aging60AR: CNData[3].customerRN120,
      aging90AR: CNData[4].customerRN120,
      aging90PlusAR: CNData[5].customerRN120,
      rangeId: 5,
    },

    { path: "Net 180", id: 15 },
    {
      id: 16,
      path: "Net 180/Total Sum",
      totalAR: CNData[0].totalRN180,
      currentAR: CNData[1].totalRN180,
      aging30AR: CNData[2].totalRN180,
      aging60AR: CNData[3].totalRN180,
      aging90AR: CNData[4].totalRN180,
      aging90PlusAR: CNData[5].totalRN180,
      rangeId: 6,
    },
    {
      id: 17,
      path: "Net 180/Number of customer",
      totalAR: CNData[0].customerRN180,
      currentAR: CNData[1].customerRN180,
      aging30AR: CNData[2].customerRN180,
      aging60AR: CNData[3].customerRN180,
      aging90AR: CNData[4].customerRN180,
      aging90PlusAR: CNData[5].customerRN180,
      rangeId: 6,
    },

    { path: "Other", id: 18 },
    {
      id: 19,
      path: "Other/Total Sum",
      totalAR: CNData[0].totalOther,
      currentAR: CNData[1].totalOther,
      aging30AR: CNData[2].totalOther,
      aging60AR: CNData[3].totalOther,
      aging90AR: CNData[4].totalOther,
      aging90PlusAR: CNData[5].totalOther,
      rangeId: 7,
    },
    {
      id: 20,
      path: "Other/Number of customer",
      totalAR: CNData[0].customerOther,
      currentAR: CNData[1].customerOther,
      aging30AR: CNData[2].customerOther,
      aging60AR: CNData[3].customerOther,
      aging90AR: CNData[4].customerOther,
      aging90PlusAR: CNData[5].customerOther,
      rangeId: 7,
    },
  ];
  const columns = [
    {
      field: "totalAR",
      headerName: "Total AR Balance",
      width: 200,
      sortable: false,
      renderCell: function (params) {
        return <div style={{ cursor: "pointer" }}>{params.row.totalAR}</div>;
      },
    },
    {
      field: "currentAR",
      headerName: "Current AR Balance",
      width: 200,
      sortable: false,
      renderCell: function (params) {
        return <div style={{ cursor: "pointer" }}>{params.row.currentAR}</div>;
      },
    },
    {
      field: "aging30AR",
      headerName: "AR Balance Aging 30",
      width: 200,
      sortable: false,
      renderCell: function (params) {
        return <div style={{ cursor: "pointer" }}>{params.row.aging30AR}</div>;
      },
    },
    {
      field: "aging60AR",
      headerName: "AR Balance Aging 60",
      width: 200,
      sortable: false,
      renderCell: function (params) {
        return <div style={{ cursor: "pointer" }}>{params.row.aging60AR}</div>;
      },
    },
    {
      field: "aging90AR",
      headerName: "AR Balance Aging 90",
      width: 200,
      sortable: false,
      renderCell: function (params) {
        return <div style={{ cursor: "pointer" }}>{params.row.aging90AR}</div>;
      },
    },
    {
      field: "aging90PlusAR",
      headerName: "AR Balance Aging 90+",
      width: 200,
      sortable: false,
      renderCell: function (params) {
        return (
          <div style={{ cursor: "pointer" }}>{params.row.aging90PlusAR}</div>
        );
      },
    },
  ];

  return { rows, columns };
};
