import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from "react";


const SearchQueryContext = createContext(undefined);

export const SearchQueryProvider= ({ children }) => {
  const [searchQuery, setSearchQuery] = useState(null);

  return (
    <SearchQueryContext.Provider value={{ searchQuery, setSearchQuery }}>
      {children}
    </SearchQueryContext.Provider>
  );
};

export const useSearchQuery = ()=> {
  const context = useContext(SearchQueryContext);
  if (!context) {
    throw new Error("searchQuery must be used within a SearchQueryProvider");
  }
  return context;
};