import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from "react";


const arSettingContext = createContext(undefined);

export const ARSettingsProvider= ({ children }) => {
    const [arBal30, setArBal30] = useState(true);
    const [arBal60, setArBal60] = useState(true);
    const [arBal90, setArBal90] = useState(true);

    return (
        <arSettingContext.Provider value={{ arBal30, setArBal30, arBal60, setArBal60, arBal90, setArBal90 }}>
          {children}
        </arSettingContext.Provider>
      );
};

export const useARSetting = ()=> {
  const context = useContext(arSettingContext);
  if (!context) {
    throw new Error("arSetting must be used within a ARSettingProvider")
  }
  return context;
};