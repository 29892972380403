import React, { useState, useEffect } from "react";
import { Paper, Typography, Box, Button, TextField } from "@mui/material";
import {
  updateCompany,
  updateNewCompanyRecord,
  updateWikipedia,
} from "./graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { useLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addNewsLink,
  getCompany,
  getCompanyPDFInfo,
  getNewCompanyRecord,
  getTenant,
} from "./graphql/queries";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { getTenantIdAuth0, isAdminAuth0 } from "./authUtils";
import AWS from "aws-sdk";

export default function CustomSourcePage() {
  const { company } = useLoaderData();

  const [textFieldValue, setTextFieldValue] = useState(
    company.customSource || ""
  );
  const [companySummary, setCompanySummary] = useState(
    company.summary ? JSON.parse(company.summary) : ""
  );
  const [ticker, setTicker] = useState(company.ticker || "");
  const [country, setCountry] = useState(company.country || "");
  const [requestedCreditLimit, setRequestedCreditLimit] = useState(
    company.requestedCreditLimit || 0
  );
  const [parentCompanyName, setParentCompanyName] = useState(
    company.parentCompanyName || ""
  );
  const [parentDnbCompanyId, setParentDnbCompanyId] = useState(
    company.parentDnbCompanyId || ""
  );
  const [parentCountry, setParentCountry] = useState(
    company.parentCountry || ""
  );
  const [parentTicker, setParentTicker] = useState(company.parentTicker || "");
  const [companyJsonData, setCompanyJsonData] = useState(
    company.companyJsonData || ""
  );
  const [isManuallyAdded, setIsManuallyAdded] = useState(
    company.isManuallyAdded || false
  );
  const [currentTenant, setCurrentTenant] = useState(null);
  const [editedFields, setEditedFields] = useState({});
  const [companyRecord, setCompanyRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [infobox, setInfobox] = useState(() => {
    try {
      return company?.wikipedia?.infobox
        ? JSON.parse(company.wikipedia.infobox)
        : null;
    } catch (error) {
      console.error("Error parsing wikipedia infobox", error);
      return null;
    }
  });
  const [manualSource, setManualSource] = useState(() => {
    return company?.manualSource
      ? JSON.parse(company?.manualSource)
      : {
          equity: 0,
          netIncome: 0,
          revenue: 0,
          numberOfEmployees: 0,
          marketCap: 0,
          yearsOfOperation: 0,
          AQR: 0,
        };
  });
  const [avData, setAVData] = useState({});
  const [dnbData, setDnBData] = useState({});
  const [googleSearch, setGoogleSearch] = useState({});
  const [newsData, setNewsData] = useState({});
  const [newUrl, setNewUrl] = useState('');
  const [linkedInURL, setLinkedInURL] = useState('');

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const handleManualSourceSubmit = async () => {
    setLoading(true);
    try {
      const result = await API.graphql(
        graphqlOperation(updateCompany, {
          input: {
            tenantId: company.tenantId,
            id: company.id,
            manualSource: JSON.stringify(manualSource),
          },
        })
      );
      const response = result.data.updateCompany;
      console.log(result.data.updateCompany);
      toast.success("Financial information updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error("Error updating Financial information");
    }
    setLoading(false);
  };

  const handleManualSourceChange = (key, value) => {
    setManualSource((prevManualSource) => ({
      ...prevManualSource,
      [key]: value,
    }));
  };

  const handleInfoboxChange = (key, value) => {
    setInfobox((prevInfobox) => ({
      ...prevInfobox,
      [key]: value,
    }));
    setEditedFields((prevEditedFields) => ({
      ...prevEditedFields,
      [key]: true,
    }));

    console.log("editedFields", editedFields);
  };

  const handleUpdateCustomSourceClick = async () => {
    setLoading(true);
    try {
      const response = await handleUpdateCompanyTable();
      await handleUpdateWikipedia(response.updatedAt);
      await handleAddNewsLink()
      setNewUrl("")
      toast.success("Company information updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error("Error updating company information");
    }
    setLoading(false);
  };

  const handleUpdateWikipedia = async (updateTime) => {
    try {
      const updatedInfobox = { ...infobox };
      for (const key in editedFields) {
        if (editedFields[key]) {
          updatedInfobox[key] = { value: updatedInfobox[key], manual: true };
        }
      }
      const result = await API.graphql(
        graphqlOperation(updateWikipedia, {
          input: {
            tenantId: company.tenantId,
            id: company.wikipedia.id,
            infobox: JSON.stringify(updatedInfobox),
          },
        })
      );

      await syncCompanyRecord(updatedInfobox, updateTime);
      console.log("wiki update", result.data.updateWikipedia);
      toast.success("Wikipedia information updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error("Error updating Wikipedia information");
    }
    setLoading(false);
  };

  const getCurrentTenant = async () => {
    const selectedTenant = getTenantIdFromURL();
    const isAdmin = await isAdminAuth0();
    const tenantId = isAdmin ? selectedTenant : await getTenantIdAuth0();
    console.log("getCurrentTenant");
    const response = await API.graphql(
      graphqlOperation(getTenant, {
        id: tenantId,
      })
    );

    console.log("Current Tenant -> ", response.data?.getTenant);
    setCurrentTenant(response?.data?.getTenant);
  };

  const fetchCompanyRecord = async () => {
    try {
      let result = await API.graphql(
        graphqlOperation(getNewCompanyRecord, {
          tenantId: company.tenantId,
          id: company.id,
        })
      );

      const record = result.data?.getNewCompanyRecord;
      setCompanyRecord(record);
    } catch (error) {
      console.log("error while fetching company records", error);
    }
  };

  const syncCompanyRecord = async (infoboxData, updateTime) => {
    let companyInfo = companyRecord.companyInfo
      ? JSON.parse(companyRecord.companyInfo)
      : null;

    if (companyInfo) {
      const newCompanyInfo = {
        ...companyInfo,
        country: country || null,
        ticker: ticker || null,
        infobox: JSON.stringify(infoboxData),
      };

      try {
        let result = await API.graphql(
          graphqlOperation(updateNewCompanyRecord, {
            input: {
              tenantId: company.tenantId,
              id: company.id,
              companyInfo: JSON.stringify(newCompanyInfo),
              originalItemUpdatedAt: updateTime,
            },
          })
        );

        console.log("Company Record Updated", result);
      } catch (error) {
        console.log("error while updating company records", error);
      }
    }
  };

  const fetchAVdata = async () => {
    try {
      const key = `public/alpha-vantage-financial-info/${company?.name}-${company.tenantId}.json`;
      const s3 = new AWS.S3();
      AWS.config.getCredentials((err) => {
        if (err) {
          console.log("Error retrieving AWS credentials:", err);
        } else {
          console.log("AWS credentials are valid and set up correctly.");
        }
      });
      const data = await s3
        .getObject({ Bucket: "crediarc-content01853-production", Key: key })
        .promise();
      const fileData = data.Body.toString("utf-8");
      const jsonData = JSON.parse(fileData);
      const companyOverviewData = jsonData.overview;
      const companyBalanceSheetData = jsonData.balanceSheet;
      const companyIncomeStatementData = jsonData.incomeStatement;
      const extractedData = {
        "Net Income": companyIncomeStatementData?.annualReports[0]?.netIncome,
        Revenue: companyIncomeStatementData?.annualReports[0]?.totalRevenue,
        Equity:
          companyBalanceSheetData?.annualReports[0]?.totalShareholderEquity,
        "Market Cap": companyOverviewData?.MarketCapitalization,
      };
      setAVData(extractedData);
      console.log("jsonData", jsonData);
    } catch (error) {
      console.log("Failed to read file", error);
    }
  };

  const fetchDnbData = async () => {
    if (company?.dnbCompanyId) {
      try {
        const key =
          "public/company-info/info-" + company?.dnbCompanyId + ".json";
        const s3 = new AWS.S3();
        const data = await s3
          .getObject({ Bucket: "crediarc-content01853-production", Key: key })
          .promise();
        const fileData = data.Body.toString("utf-8");
        const currentCompany = JSON.parse(fileData);
        for (let i = 0; i < currentCompany.length; i++) {
          const row = currentCompany[i];
          for (const key in row) {
            if (typeof row[key] === "string") {
              row[key] = isNaN(parseFloat(row[key].replace(/,/g, "")))
                ? row[key]
                : parseFloat(row[key].replace(/,/g, ""));
            }
          }
        }
        const extractedData = {
          Revenue: formatNumber(
            currentCompany[0].response?.organization?.financials[0]
              ?.yearlyRevenue[0]?.value
          ),
          "Number Of Employees":
            currentCompany[0].response?.organization?.domesticUltimate
              ?.numberOfEmployees[0]?.value,
        };
        console.log("extractedData", extractedData);
        setDnBData(extractedData);
      } catch (error) {
        setDnBData(undefined);
        console.log(error);
      }
    }
  };

  const parseDate = (dateString) => {
    return new Date(dateString);
  };

  const refreshNewsData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getCompany, {
          tenantId: company.tenantId,
          id: company.id,
        })
      );
      if (result.data && result.data.getCompany && result.data.getCompany.googleSearch) {
        const searchData = result.data.getCompany.googleSearch;
        setGoogleSearch(searchData);
        searchData.news.items.sort((a, b) => {
          const dateA = parseDate(a.date);
          const dateB = parseDate(b.date);
        
          return dateB - dateA; 
        });
        setNewsData(searchData.news.items);
        setGoogleSearch(searchData)
      } else {
        console.log("No news data available.");
      }
    } catch (error) {
      console.log("Error fetching news data", error);
    }
  };

  const handleAddNewsLink = async () => {
    try {
      setLoading(true)
      await API.graphql(
        graphqlOperation(addNewsLink, {
          tenantId: googleSearch.tenantId,
          url: newUrl,
          googleSearchNewsId: googleSearch.id,
        })
      );
      setNewUrl('');
      setLoading(false)
      refreshNewsData();
      toast.success("News Link added successfully!");
      
    } catch (error) {
      console.log("Error adding news link", error);
    }
  };
  const handleUpdateCompanyTable = async () => {
      let filename = '';
      if(linkedInURL){
        filename = await getLinkedInFileName();
      }
    try {
      const result = await API.graphql(
        graphqlOperation(updateCompany, {
          input: {
            tenantId: company.tenantId,
            id: company.id,
            customSource: textFieldValue,
            summary: JSON.stringify(companySummary),
            ticker,
            country,
            requestedCreditLimit,
            parentCompanyName,
            parentDnbCompanyId,
            parentCountry,
            parentTicker,
            companyJsonData,
            isManuallyAdded,
            linkedInFileName:filename
          },
        })
      );
      console.log("Updated Company Result",result.data.updateCompany)
      return result.data.updateCompany;
    } catch (error) {
      console.log("Error adding news link", error);
    }
  };

  async function getLinkedInFileName() {
    const url = 'https://5zoenoqpswg7yejzkhvbjkir3u0xksar.lambda-url.eu-central-1.on.aws/';
    const data = {
      url: linkedInURL,
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.json();

      const filename = result.path.split("/")[1];
      console.log('LinkedIn parser file name',filename);
      return filename; 
    } catch (error) {
      console.error('Error:', error);
    }
  }
  

  useEffect(() => {
    getCurrentTenant();
    fetchCompanyRecord();
    fetchAVdata();
    fetchDnbData();
    refreshNewsData();
    console.log("manualSource",manualSource)
  }, []);

  const formatNumber = (number) => {
    if (isNaN(number) || !number) {
      return "-"; // Return a placeholder for missing values
    }
    if (number >= 1e9) {
      return `US $${(number / 1e9).toFixed(2)} billion`;
    } else if (number >= 1e6) {
      return `US $${(number / 1e6).toFixed(2)} million`;
    } else if (number >= 1e3) {
      return `US $${(number / 1e3).toFixed(2)} thousand`;
    } else {
      return `US $${number}`;
    }
  };

  return (
    <React.Fragment>
      <Paper
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography variant="h2" fontWeight="bold" color="text.primary">
            {company.name}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Custom Text
        </Typography>
        <TextField
          id="outlined-multiline-flexible"
          label="Enter custom text"
          multiline
          rows={4}
          fullWidth
          value={textFieldValue}
          onChange={(e) => setTextFieldValue(e.target.value)}
        />
        <Button
          sx={{ width: 200, m: 2, alignSelf: "center" }}
          variant="contained"
          onClick={handleUpdateCustomSourceClick}
          disabled={loading}
        >
          Update Custom Source
        </Button>
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Summary
        </Typography>
        <TextField
          id="outlined-multiline-flexible"
          label="Enter Company Summary"
          multiline
          // rows={10}
          fullWidth
          value={companySummary}
          onChange={(e) => setCompanySummary(e.target.value)}
        />
        <Button
          sx={{ width: 200, m: 2, alignSelf: "center" }}
          variant="contained"
          onClick={handleUpdateCustomSourceClick}
          disabled={loading}
        >
          Update Summary
        </Button>
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Country
        </Typography>
        <TextField
          id="country"
          label="Country"
          fullWidth
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Ticker
        </Typography>
        <TextField
          id="ticker"
          label="Ticker"
          fullWidth
          value={ticker}
          onChange={(e) => setTicker(e.target.value)}
        />
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Requested Credit Limit
        </Typography>
        <TextField
          id="requestedCreditLimit"
          label="Requested Credit Limit"
          type="number"
          fullWidth
          value={requestedCreditLimit}
          onChange={(e) => setRequestedCreditLimit(parseFloat(e.target.value))}
        />
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Parent Company Name
        </Typography>
        <TextField
          id="parentCompanyName"
          label="Parent Company Name"
          fullWidth
          value={parentCompanyName}
          onChange={(e) => setParentCompanyName(e.target.value)}
        />{" "}
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Parent DNB Company ID
        </Typography>
        <TextField
          id="parentDnbCompanyId"
          label="Parent DNB Company ID"
          fullWidth
          value={parentDnbCompanyId}
          onChange={(e) => setParentDnbCompanyId(e.target.value)}
        />
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Parent Country
        </Typography>
        <TextField
          id="parentCountry"
          label="Parent Country"
          fullWidth
          value={parentCountry}
          onChange={(e) => setParentCountry(e.target.value)}
        />
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Parent Ticker
        </Typography>
        <TextField
          id="parentTicker"
          label="Parent Ticker"
          fullWidth
          value={parentTicker}
          onChange={(e) => setParentTicker(e.target.value)}
        />
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Company Linked URL
        </Typography>
        <TextField
          id="CompanyLinkedURL"
          label="Company Linked URL"
          fullWidth
          value={linkedInURL}
          onChange={(e) => setLinkedInURL(e.target.value)}
        />
        {newsData.length===0?(<><Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Add News Link
        </Typography>
        <TextField
          id="NewsLink"
          label="News Link"
          fullWidth
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
        /></>):null}
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Infobox
        </Typography>
        {infobox ? (
          Object.keys(infobox).map((key) => (
            <TextField
              key={key}
              label={key}
              fullWidth
              value={infobox[key]?.manual ? infobox[key].value : infobox[key]}
              onChange={(e) => handleInfoboxChange(key, e.target.value)}
              sx={{ mb: 2 }}
            />
          ))
        ) : (
          <Typography variant="body1" color="text.secondary">
            No infobox data available.
          </Typography>
        )}
        <Button
          sx={{ width: 200, m: 2, alignSelf: "center" }}
          variant="contained"
          onClick={handleUpdateCustomSourceClick}
          disabled={loading}
        >
          Update Company Info
        </Button>
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", my: 2 }}
        >
          Financial Information
        </Typography>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", my: 2 }}
        >
          Alpha Vantage:
        </Typography>
        {avData
          ? Object.keys(avData).map((key) => (
              <Typography key={key} sx={{ mb: 2 }}>
                <strong>{key}:</strong> {formatNumber(avData[key])}
              </Typography>
            ))
          : 'No Alpha Vantage Data Available'}
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", my: 2 }}
        >
          DnB:
        </Typography>
        {dnbData
          ? Object.keys(dnbData).map((key) => (
              <Typography key={key} sx={{ mb: 2 }}>
                <strong>{key}:</strong> {dnbData[key]}
              </Typography>
            ))
          : 'No DnB Data Available'}
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", my: 2 }}
        >
          Manual Financial Information
        </Typography>
        {manualSource
          ? Object.keys(manualSource).map((key) => (
              <TextField
                key={key}
                label={key}
                fullWidth
                value={manualSource[key]}
                onChange={(e) => handleManualSourceChange(key, e.target.value)} //todo
                sx={{ mb: 2 }}
              />
            ))
          : null}
        <Button
          sx={{ width: 200, m: 2, alignSelf: "center" }}
          variant="contained"
          onClick={handleManualSourceSubmit}
          disabled={loading}
        >
          Update Financial Info
        </Button>
      </Paper>
    </React.Fragment>
  );
}
