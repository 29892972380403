import { Box, Divider, Switch, Typography,Card, styled } from "@mui/material";
import React, { useEffect, useState,forwardRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";
import Green from '../Assets/Images/smiley_green.png'
import Red from '../Assets/Images/smiley_red.png'
import Yellow from '../Assets/Images/smiley_yellow.png'
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import { getDataBasedOnCombinedScore } from "./helper";
import { useProspectsAR } from "../context/prospectsARContext";

const AntSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    margin: 4,
    "&.Mui-checked": {
      color: "#fff",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: "#000",
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    border: "2px solid black",
  },
}));

 const  RiskDistributionDiagram = forwardRef((props, ref) => {
  const rowData = props.rowData;
  const [isMoney, setIsMoney] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [computedData, setComputedData] = useState(null);
  const [yAxisName, setYAxisName] = useState("");
  const [hoveredBarIndex, setHoveredBarIndex] = useState(null);
  const {isProspects, setProspects} = useProspectsAR();
  const handleChange = (event) => {
    setIsMoney(event.target.checked);
    if (event.target.checked) {
      setGraphData(computedData.totalARBalArray);
      setYAxisName("Total AR Balance");
    } else {
      setGraphData(computedData.customerArray);
      setYAxisName("Amount of Customers");
    }
    console.log("computedData",computedData)
  };

  useEffect(() => {
    const result = getDataBasedOnCombinedScore({data:rowData,isProspects});
    setComputedData(result);
    setGraphData(result.customerArray);
    setYAxisName("Number of Customers");
  }, [rowData]);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  const getScoreImage = (score) => {
    if (score >= 1 && score <= 3.99) {
      return Green;
    } else if (score >= 4 && score <= 7.99) {
      return Yellow;
    } else if (score >= 8 && score <= 10) {
      return Red;
    }
    return null; 
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length && label) {
      const [low, high] = label?.split('-');
      const average = ((parseFloat(low) + parseFloat(high)) / 2).toFixed(1);
      let scoreImage = getScoreImage(average);
      return (
        <div
          style={{
            backgroundColor: '#F9F9F9',
            color: '#1A2A56',
            fontWeight: '500',
            padding: '8px',
            borderRadius: '4px',
            boxShadow: 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029',
            transform: 'translate(10px, -10px)', 
            marginLeft: '20px' 
          }}
        >
          <p>{`Average Score`}</p>
          <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px',alignItems:"center" }}>
            <p>{average}</p>
            <img src={scoreImage} style={{ width: '16px', height: '18px' }} alt="" />
          </div>
        </div>
      );
    }
  
    return null;
  };
  


  
  return (
    <Card ref={ref} variant="outlined" style={{  height: 'fit-content',margin:"15px 0px 0 0px",padding:"0 0 10px 0", boxShadow: 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029', position: 'relative',backgroundColor:"#FAFAFC",}}>
      <Typography variant="h5" style={{marginLeft:"30px" ,color:"#1A2A56",fontSize:"26px",fontWeight:"500"}}  mt={3}>
        {"Customer's Risk"}{" "}
      </Typography>
      <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', height: '100%' }}>
        <Box sx={{ mt:"20px",gridRow: 2, display: 'flex',flexDirection:"column", justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
          <BarChart
            width={800}
            height={300}
            data={graphData}
            margin={{
              top: 5,
              right: 30,
              left: 50,
              bottom: 0,
            }}
            
          >
            <CartesianGrid vertical={false} strokeDasharray="1 0" />
            <XAxis dataKey="range" tick={false} />
            <YAxis axisLine={false}>
              <Label
                value={yAxisName}
                angle={-90}
                position="insideLeft"
                style={{ textAnchor: "middle",fill: "#1A2A56",fontWeight:"400" }}
                offset={-30}
                
              />
            </YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="value"
              fill="#5186EC"
              barSize={30}
              radius={[2, 2, 0, 0]}
              onMouseEnter={(data, index) => setHoveredBarIndex(index)}
              onMouseLeave={() => setHoveredBarIndex(null)}
              style={{
                cursor: 'pointer',
              }}
              shape={({ x, y, width, height, fill, index }) => {
                return(
                <rect
                  x={x}
                  y={y}
                  width={width}
                  height={height}
                  fill={hoveredBarIndex === index ? "#315293" : fill}
                  rx={2} 
                  ry={2} 
                />
              )}}
            />

          </BarChart>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '670px', marginLeft: '75px' }}>
          <Box sx={{ mr: 1, flexGrow: 8, flexBasis: '29.5%' }}> 
            <Divider
              sx={{
                height: 10,
                backgroundColor: '#60AC50',
                borderRadius: '10px 0 0 10px',
                boxShadow: 'inset 0px 1px 3px grey, 0px 3px 6px white'
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={Green} style={{ width: '18px', height: '18px' }} alt="" />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                1-3
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mr: 1, flexGrow: 12, flexBasis: '40.4%' }}> 
            <Divider sx={{ height: 10, backgroundColor: '#E7BF3C',boxShadow: 'inset 0px 1px 3px grey, 0px 3px 6px white' }} />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={Yellow} style={{ width: '16px', height: '18px' }} alt="" />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                4-7
              </Typography>
            </Box>
          </Box>
          <Box sx={{ flexBasis: '21.5%' }}> 
            <Divider
              sx={{
                height: 10,
                backgroundColor: '#F25A48',
                borderRadius: '0 10px 10px 0',
                boxShadow: 'inset 0px 1px 3px grey, 0px 3px 6px white'
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={Red} style={{ width: '16px', height: '18px' }} alt="" />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                8-10
              </Typography>
            </Box>
          </Box>
        </Box>
          <Box sx={{ gridRow: 1, display: 'flex',alignSelf:"flex-start", justifyContent: 'space-between', alignItems: 'center', px: 2, pt: 2,width:"200px",visibility: isProspects ? 'hidden' : 'visible', }}>
          <Typography style={{color:"#2F3D63",fontWeight:"400",fontSize:"16px"}}>Customers</Typography>
          <AntSwitch
            checked={isMoney}
            onChange={handleChange}
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
          />
          <Typography style={{color:"#2F3D63",fontWeight:"400",fontSize:"16px"}}>Money</Typography>
        </Box>
        </Box>
      </Box>
    </Card>
  )

}
)

export default RiskDistributionDiagram;
