export const segmentationTypes = [
  { id: 1, label: "Alternative Score" },
  { id: 2, label: "Recommended Net" },
  { id: 3, label: "Legal Score" },
  { id: 4, label: "General Risk Score" },
  { id: 5, label: "Failure Score" },
  { id: 6, label: "Delinquency Score" },
  { id: 7, label: "Underwriting" },
  { id: 8, label: "Recommended Limit" },
  { id: 9, label: "Current AR Balance" },
  { id: 10, label: "Risk Segment" },
  { id: 11, label: "Combined Aging" },
  { id: 12, label: "Country Score" },
  { id: 13, label: "Underwriting Reason" },
  { id: 14, label: "AR Balance Aging 30" },
  { id: 15, label: "AR Balance Aging 60" },
  { id: 16, label: "AR Balance Aging 90" },
  { id: 17, label: "AR Balance Aging 90+" },
  { id: 18, label: "Current Limit" },
  { id: 19, label: "Current Net" },
];

export const underwritingReasons = {
  val1: "Requested limit exceeds max limit.",
  val2: "Risk ind > 3.",
  val3: "Approved",
  val4: "Missing risk segment.",
  val5: "Approved for low limits.",
  val6: "Buyer currently overdue.",
  val7: "Country rating too high.",
};

export const countryScores = {
  val1: 1,
  val2: 2,
  val3: 3,
  val4: 4,
  val5: 5,
  val6: 6,
  val7: 7,
};

export const agingTypes = {
  AGINNG30: { label: "AR Balance Aging 30" },
  AGINNG60: { label: "AR Balance Aging 60" },
  AGINNG90: { label: "AR Balance Aging 90" },
  AGINNG90PLUS: { label: "AR Balance Aging 90+" },
};

export const segmentationTypesEnum = {
  ALTERNATIVE_SCORE: { id: 1, label: "Alternative Score" },
  RECOMMENDED_NET: { id: 2, label: "Recommended Net" },
  LEGAL_SCORE: { id: 3, label: "Legal Score" },
  GENERAL_RISK_SCORE: { id: 4, label: "General Risk Score" },
  FAILURE_SCORE: { id: 5, label: "Failure Score" },
  DELINQUENCY_SCORE: { id: 6, label: "Delinquency Score" },
  UNDERWRITING: { id: 7, label: "Underwriting" },
  RECOMMENDED_LIMIT: { id: 8, label: "Recommended Limit" },
  CARB: { id: 9, label: "Current AR Balance" },
  RISK_SEGMENT: { id: 10, label: "Risk Segment" },
  COMBINED_AGING: { id: 11, label: "Combined Aging" },
  COUNTRY_SCORE: { id: 12, label: "Country Score" },
  UNDERWRITING_REASON: { id: 13, label: "Underwriting Reason" },
  ARB_AGING30: { id: 14, label: "AR Balance Aging 30" },
  ARB_AGING60: { id: 15, label: "AR Balance Aging 60" },
  ARB_AGING90: { id: 16, label: "AR Balance Aging 90" },
  ARB_AGING90PLUS: { id: 17, label: "AR Balance Aging 90+" },
  CURRENT_LIMIT: { id: 18, label: "Current Limit" },
  CURRENT_NET: { id: 19, label: "Current Net" },
};
