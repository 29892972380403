export const ColorEnum = {
    TEXT_WHITE: "#E9E9E9",
    PRIMARY_BLUE: "#5186EC",
    DARK_BLUE: "#2F3D63",
    DARK_BLUE_SHADE1: "#485d97",
    DARK_BLUE_SHADE3: "#1A2A56",
    // from here
    BG_WHITE: "#ECEDF0",
    GREEN: "#60AC50",
    DARK_BLUE_SHADE2: "#3753A0",
    TEXT_DARK_BLUE: "#1A2A56",
    GREY: "#707070"
}