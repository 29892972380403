import {
  segmentationTypesEnum,
  underwritingReasons,
} from "./SegmentationTypes";

export const RecommendedNetRange = [
  { id: 0, label: "All" },
  { id: 1, label: "Net 30" },
  { id: 2, label: "Net 45" },
  { id: 3, label: "Net 60" },
  { id: 4, label: "Net 90" },
  { id: 5, label: "Net 120" },
  { id: 6, label: "Net 180" },
  { id: 7, label: "Other" },
];

export const UnderwritingRange = [
  { id: 0, label: "All" },
  { id: 1, label: "Manual" },
  { id: 2, label: "Rejected" },
  { id: 3, label: "Approved" },
  { id: 4, label: "Other" },
];

export const getRangeForRiskyCustomer = (segmentId) => {
  if (
    segmentId === segmentationTypesEnum.ALTERNATIVE_SCORE.id ||
    segmentId === segmentationTypesEnum.LEGAL_SCORE.id ||
    segmentId === segmentationTypesEnum.DELINQUENCY_SCORE.id ||
    segmentId === segmentationTypesEnum.FAILURE_SCORE.id ||
    segmentId === segmentationTypesEnum.GENERAL_RISK_SCORE.id
  ) {
    return [
      { id: 0, label: "All" },
      { id: 1, label: "<=1 " },
      { id: 2, label: "<=2" },
      { id: 3, label: "<=3" },
      { id: 4, label: "<=4" },
      { id: 5, label: "<=5" },
      { id: 6, label: "<=6" },
      { id: 7, label: "<=7" },
      { id: 8, label: ">7" },
      { id: 9, label: "Other" },
    ];
  } else if (
    segmentId === segmentationTypesEnum.ARB_AGING30.id ||
    segmentId === segmentationTypesEnum.ARB_AGING60.id ||
    segmentId === segmentationTypesEnum.ARB_AGING90.id ||
    segmentId === segmentationTypesEnum.ARB_AGING90PLUS.id ||
    segmentId === segmentationTypesEnum.COMBINED_AGING.id
  ) {
    return [
      { id: 0, label: "All" },
      { id: 1, label: "<=0 " },
      { id: 2, label: ">0" },
    ];
  } else if (
    segmentId === segmentationTypesEnum.RECOMMENDED_NET.id ||
    segmentId === segmentationTypesEnum.CURRENT_NET.id
  ) {
    return RecommendedNetRange;
  } else if (segmentId === segmentationTypesEnum.UNDERWRITING.id) {
    return UnderwritingRange;
  } else if (segmentId === segmentationTypesEnum.UNDERWRITING_REASON.id) {
    return [
      { id: 0, label: "All" },
      { id: 1, label: underwritingReasons.val1 },
      { id: 2, label: underwritingReasons.val2 },
      { id: 3, label: underwritingReasons.val3 },
      { id: 4, label: underwritingReasons.val4 },
      { id: 5, label: underwritingReasons.val5 },
      { id: 6, label: underwritingReasons.val6 },
      { id: 7, label: underwritingReasons.val7 },
      { id: 8, label: "Other" },
    ];
  } else if (segmentId === segmentationTypesEnum.COUNTRY_SCORE.id) {
    return [
      { id: 0, label: "All" },
      { id: 1, label: "1" },
      { id: 2, label: "2" },
      { id: 3, label: "3" },
      { id: 4, label: "4" },
      { id: 5, label: "5" },
      { id: 6, label: "6" },
      { id: 7, label: "7" },
      { id: 8, label: ">7" },
    ];
  } else if (segmentId === segmentationTypesEnum.RISK_SEGMENT.id) {
    return [
      { id: 0, label: "All" },
      { id: 1, label: "<=2.5" },
      { id: 2, label: "<=5" },
      { id: 3, label: "<=7.5" },
      { id: 4, label: ">7.5" },
    ];
  } else if (segmentId === segmentationTypesEnum.RECOMMENDED_LIMIT.id) {
    return [
      { id: 0, label: "All" },
      { id: 1, label: "<50K" },
      { id: 2, label: "<100K" },
      { id: 3, label: "<150K" },
      { id: 4, label: "<200K" },
      { id: 5, label: "<250K" },
      { id: 6, label: "<300K" },
      { id: 7, label: ">=300K" },
    ];
  } else if (
    segmentId === segmentationTypesEnum.CURRENT_LIMIT.id ||
    segmentId === segmentationTypesEnum.CARB.id
  ) {
    return [
      { id: 0, label: "All" },
      { id: 1, label: "<20K" },
      { id: 2, label: "<50K" },
      { id: 3, label: "<150K" },
      { id: 4, label: "<250K" },
      { id: 5, label: "<500K" },
      { id: 6, label: ">500K" },
    ];
  }
};
