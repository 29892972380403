import { Box, Button, Input, Modal, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AWS from 'aws-sdk';
import awsExports from "./aws-exports";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { API, graphqlOperation } from "aws-amplify";
import { convertAgingAndPaymentFile, convertExlFilesPapaya } from "./graphql/queries";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useSelector } from "react-redux";
import Close from "./Assets/Images/close.svg"
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { getTenantInfo } from "./utils/GraphQLHelper/GetTenantInfo";

Amplify.configure(awsExports);

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

Amplify.configure(awsconfig);

const modalStyle = {
  width: "500px",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop:"15px",
  position:"relative"
};

const inputStyle = {
  display: "none",
};

const buttonStyle = {
  mt: 2,
  backgroundColor: "#5186EC",
  color: "#fff",
  '&:hover': {
    backgroundColor: "#4069D0",
  },
  borderRadius:"8px"
};

const FileUploadModal = (props) => {
  const { open, close } = props;
  const [agingFile, setAgingFile] = useState(null);
  const [paymentFile, setPaymentFile] = useState(null);
  const [agingFileName, setAgingFileName] = useState("No file selected");
  const [paymentFileName, setPaymentFileName] = useState("No file selected");
  const [tenantId, setTenantId] = useState();
  const [popupOpen, setPopupOpen] = useState(false);
  const [isAREnabled, setIsAREnabled] = useState(false);
  const [loading,setLoading] = useState(false)


  const navigate = useNavigate();

  const isAdmin = useSelector((state) => state.userInfo.isAdmin);
  const currentTenant = useSelector((state) => state.userInfo.tenantId);
  const URLTenant = getTenantIdFromURL();

  const selectTenantId = () => {
    if (isAdmin) setTenantId(URLTenant);
    else setTenantId(currentTenant);
  };

  const handleClose = () => {
    setAgingFile(null);
    setPaymentFile(null);
    setAgingFileName("No file chosen");
    setPaymentFileName("No file chosen");
    close();
  };

  const fetchIsAREnabled = async ()=>{
    const { isAREnabled } = await getTenantInfo();
    setIsAREnabled(isAREnabled);
  }

  useEffect(() => {
    selectTenantId();
    fetchIsAREnabled()
  }, [isAdmin, currentTenant, URLTenant]);

  const handleFileChange = (event, setter, setFileName) => {
    const file = event.target.files[0];
    setter(file);
    setFileName(file ? file.name : "No file chosen");
  };

  const uploadFile = async (file) => {
    try {
      const key = `aging-json-files/${tenantId}/${file.name}`;
      const s3 = new AWS.S3();
      const result = await s3
        .putObject({
          Bucket: "crediarc-content01853-production",
          Key: `public/aging-json-files/${tenantId}/${file.name}`,
          Body: file,
          ContentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        .promise();
      if(isAREnabled) toast.success("File uploaded successfully!");
      return { key, result };
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  const handleUpload = async () => {
    setLoading(true)
    try {
      let agingRes, paymentRes;
      if (agingFile) {
        agingRes = await uploadFile(agingFile);
        setAgingFile(null);
      }

      if (paymentFile) {
        paymentRes = await uploadFile(paymentFile);
        setPaymentFile(null);
      }

      if (agingRes?.key && paymentRes?.key) {
        if (tenantId === "300358df-22a8-471b-863e-2bb84b5ef948") {
          await API.graphql(
            graphqlOperation(convertExlFilesPapaya, {
              tenantId: tenantId,
              agingKey: agingRes.key,
              paymentKey: paymentRes.key,
            })
          );
        } else {
          await API.graphql(
            graphqlOperation(convertAgingAndPaymentFile, {
              tenantId: tenantId,
              agingKey: agingRes.key,
              paymentKey: paymentRes.key,
            })
          );
        }
      }
      if (!isAREnabled) setPopupOpen(true); 
      handleClose();
      setLoading(false)
    } catch (error) {
      setLoading(false)
      handleClose();
    }
  };

  const handleSettingsClick = () => {
    handleClosePopup();
    navigate("/settings/AREnableRequest");
};

const handleClosePopup = () => {
  setPopupOpen(false);
};

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="file-upload-modal-title"
        aria-describedby="file-upload-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
          backgroundColor: "rgb(255, 255, 255, 0.5)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            border: "none",
          }}
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              handleClose();
            }
          }}
        >
          <Box sx={modalStyle}>
            <IconButton
              sx={{ position: "absolute", top: 10, left: 10 }}
              onClick={handleClose}
            >
              <img src={Close}></img>
            </IconButton>
            <Typography
              id="file-upload-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 2, color: "#2F3D63" }}
            >
              Add Files
            </Typography>
            <Box
              sx={{
                width: "100%",
                mb: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <label htmlFor="aging-file-upload">
                <Button variant="contained" component="span" sx={buttonStyle}>
                  Select Aging File
                </Button>
                <Input
                  id="aging-file-upload"
                  type="file"
                  onChange={(e) =>
                    handleFileChange(e, setAgingFile, setAgingFileName)
                  }
                  sx={inputStyle}
                />
              </label>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Selected: {agingFileName}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                mb: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <label htmlFor="payment-file-upload">
                <Button variant="contained" component="span" sx={buttonStyle}>
                  Select Payment File
                </Button>
                <Input
                  id="payment-file-upload"
                  type="file"
                  onChange={(e) =>
                    handleFileChange(e, setPaymentFile, setPaymentFileName)
                  }
                  sx={inputStyle}
                />
              </label>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Selected: {paymentFileName}
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                ...buttonStyle,
                backgroundColor: loading ? "#f0f0f0" : "#5186EC",
                color: loading ? "#9F9F9F" : "#fff",
                "&:hover": {
                  backgroundColor: loading ? "#f0f0f0" : "#4069D0",
                },
              }}
              disabled={loading}
              onClick={handleUpload}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={popupOpen}
        onClose={handleClosePopup}
        aria-labelledby="ar-disabled-popup-title"
        aria-describedby="ar-disabled-popup-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "400px",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "20px",
            boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
            padding: "20px",
            paddingTop: "10px",
            paddingLeft: "45px",
          }}
        >
          <Typography
            id="ar-disabled-popup-title"
            variant="h6"
            component="h2"
            sx={{ color: "#2F3D63" }}
          >
            Accounts Receivable Not Enabled
          </Typography>
          <Typography
            id="ar-disabled-popup-description"
            sx={{ mt: 2, color: "#3d4e7a" }}
          >
            You currently don't have Accounts Receivable enabled. To enable it,
            please click on the link:
            <span style={{ marginLeft: "10px" }}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleSettingsClick();
                }}
                style={{
                  color: "#5186EC",
                  textDecoration: "underline",
                  wordWrap: "break-word",
                  cursor: "pointer",
                }}
              >
                go to settings
              </a>
            </span>
          </Typography>

          <IconButton
            sx={{ position: "absolute", top: 5, left: 5 }}
            onClick={handleClosePopup}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
};

export default FileUploadModal;
