import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from "react";


const prospectsARContext = createContext(undefined);

export const ProspectsARProvider= ({ children }) => {
  const [isProspects, setProspects] = useState(true);

  return (
    <prospectsARContext.Provider value={{ isProspects, setProspects }}>
      {children}
    </prospectsARContext.Provider>
  );
};

export const useProspectsAR = ()=> {
  const context = useContext(prospectsARContext);
  if (!context) {
    throw new Error("prospectAR must be used within a prospectsARProvider");
  }
  return context;
};