import { Box, Card, Typography, ToggleButton, ToggleButtonGroup, Tabs, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { API, graphqlOperation } from "aws-amplify";
import { useSelector } from "react-redux";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { listPortfolioScoreRecords } from "./graphql/queries";

const boxStyle = {
  background: "#FAFAFC",
  boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
  borderRadius: "8px",
  opacity: "1",
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
};

const PortfolioScoreChart = () => {
  const [tenantId, setTenantId] = useState();
  const [displayData, setDisplayData] = useState([]);
  const [data, setData] = useState([]);
  const [view, setView] = useState("weekly");
  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenant = useSelector(state => state.userInfo.tenantId);
  const [selectedTab, setSelectedTab] = useState(0);
  const [labelUnit,setLabelUnit] = useState('')
  const [unit,setUnit] = useState(1)

  const selectTenantId = () => {
    if (isAdmin) setTenantId(URLTenant);
    else setTenantId(currentTenant);
  };

  const fetchPortfolioScore = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(listPortfolioScoreRecords)
      );
  
      const records = result.data.listPortfolioScoreRecords.items;
  
      const filteredData = records.filter(record => record.tenantId === tenantId);
  
      const sortedData = filteredData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  
      console.log("Sorted Records", sortedData);
  
      setData(prevData => {
        formatData(sortedData, view);
        return sortedData;
      });
    } catch (error) {
      console.error("Error fetching portfolio score", error);
    }
  };
  const formatData = (data, view) => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const firstDataYear = Math.min(
      ...data.map((record) => new Date(record.createdAt).getFullYear())
    );

    let localUnit = 1;
    let localLabelUnit = "";

    if (selectedTab === 1) {

      const highestValue = Math.max(
        ...data.map((record) => record.totalMoneyAtRisk)
      );


      if (highestValue >= 1000000000) {
        localUnit = 1000000000;
        localLabelUnit = "Billions";
      } else if (highestValue >= 1000000) {
        localUnit = 1000000;
        localLabelUnit = "Millions";
      } else if (highestValue >= 1000) {
        localUnit = 1000;
        localLabelUnit = "Thousands";
      }
      setUnit(localUnit); 
      setLabelUnit(localLabelUnit); 
    }

    let formattedData = [];

    const getValue = (record) =>
      selectedTab === 1
        ? (record.totalMoneyAtRisk / localUnit)
        : record.averagePortfolioScore;
    const getCompanies = (record) => record.totalValidCompanies;
    const formatMonth = (month) => {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[month];
    };

    switch (view) {
      case "weekly":
        formattedData = data.map((record) => ({
          name: new Date(record.createdAt).toISOString().slice(0, 10),
          Value: getValue(record),
          TotalCompanies: getCompanies(record),
        }));
        break;

      case "monthly":
        const monthMap = {};
        data.forEach((record) => {
          const date = new Date(record.createdAt);
          const year = date.getFullYear();
          const month = date.getMonth();
          const monthKey = `${year}-${
            month < 10 ? "0" + (month + 1) : month + 1
          }`;
          if (!monthMap[monthKey]) {
            monthMap[monthKey] = { scoreSum: 0, companiesSum: 0, count: 0 };
          }
          monthMap[monthKey].scoreSum += parseFloat(getValue(record));
          monthMap[monthKey].companiesSum += getCompanies(record);
          monthMap[monthKey].count += 1;
        });

        formattedData = [];
        for (let year = firstDataYear; year <= currentYear; year++) {
          for (let month = 0; month < 12; month++) {
            const monthKey = `${year}-${
              month < 9 ? "0" + (month + 1) : month + 1
            }`;
            const monthName = formatMonth(month);
            if (monthMap[monthKey]) {
              formattedData.push({
                name: `${monthName}-${year}`,
                Value: (monthMap[monthKey].scoreSum / monthMap[monthKey].count),
                TotalCompanies:
                  monthMap[monthKey].companiesSum / monthMap[monthKey].count,
              });
            }
          }
        }
        break;

      case "quarterly":
        const quarterMap = {};
        data.forEach((record) => {
          const date = new Date(record.createdAt);
          const year = date.getFullYear();
          const quarter = Math.floor(date.getMonth() / 3) + 1;
          const quarterKey = `${year}-Q${quarter}`;
          if (!quarterMap[quarterKey]) {
            quarterMap[quarterKey] = { scoreSum: 0, companiesSum: 0, count: 0 };
          }
          quarterMap[quarterKey].scoreSum += parseFloat(getValue(record));
          quarterMap[quarterKey].companiesSum += getCompanies(record);
          quarterMap[quarterKey].count += 1;
        });

        formattedData = [];
        for (let year = firstDataYear; year <= currentYear; year++) {
          for (let quarter = 1; quarter <= 4; quarter++) {
            const quarterKey = `${year}-Q${quarter}`;
            if (quarterMap[quarterKey]) {
              formattedData.push({
                name: `${quarterKey}`,
                Value: (
                  quarterMap[quarterKey].scoreSum / quarterMap[quarterKey].count
                ),
                TotalCompanies:
                  quarterMap[quarterKey].companiesSum /
                  quarterMap[quarterKey].count,
              });
            }
          }
        }
        break;

      default:
        formattedData = [];
    }

    const emptyEntry = {
      name: "",
      Value: null,
      TotalCompanies: 0,
    };
    
    let dataWithEmptyEntry =
      formattedData.length > 0 ? [...formattedData] : [emptyEntry];
    setDisplayData(dataWithEmptyEntry);
  };

  useEffect(() => {
    selectTenantId();
  }, [isAdmin, URLTenant, currentTenant]);

  useEffect(() => {
    if (tenantId) {
      fetchPortfolioScore();
    }
  }, [tenantId]);
  
  useEffect(() => {
    if (data.length > 0) {
      formatData(data, view);
    }
  }, [ selectedTab===1]);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
      if (tenantId) {
        formatData(data, newView);
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', marginTop: "15px" }}>
      <Card sx={boxStyle}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#2F3D63",
              height: 4,
              borderRadius: '8px',
            },
          }}
          sx={{
            borderBottom: 0.1,
            borderBottomColor: '#dbd9d9',
            mb: 2,
          }}
        >
          <Tab
            label={"Portfolio Arc Score"}
            style={{
              textTransform: 'capitalize',
              color: selectedTab === 0 ? '#1A2A56' : 'grey', 
              fontWeight: selectedTab === 0 ? '500' : '400',
              fontSize: '15px',
            }}
          />
          <Tab
            label= { "Collections at Risk"}
            style={{
              textTransform: 'capitalize',
              color: selectedTab === 1 ? '#1A2A56' : 'grey', 
              fontWeight: selectedTab === 1 ? '500' : '400',
              fontSize: '15px',
            }}
          />
        </Tabs>
        <Box sx={{ width: "100%", padding: "25px", display: "flex", flexDirection: "column" }}>
          <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              sx={{ margin: "0 auto 10px auto" }}
            >
              <ToggleButton
                value="weekly"
                sx={{ color: view === 'weekly' ? '#2F3D63' : '#929292', '&.Mui-selected': { color: '#2F3D63' } }}
              >
                Weekly
              </ToggleButton>
              <ToggleButton
                value="monthly"
                sx={{ color: view === 'monthly' ? '#2F3D63' : '#929292', '&.Mui-selected': { color: '#2F3D63' } }}
              >
                Monthly
              </ToggleButton>
              <ToggleButton
                value="quarterly"
                sx={{ color: view === 'quarterly' ? '#2F3D63' : '#929292', '&.Mui-selected': { color: '#2F3D63' } }}
              >
                Quarterly
              </ToggleButton>
          </ToggleButtonGroup>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={displayData} margin={{ left: selectedTab===1 ? 50 : 0,right: selectedTab===1 ? 50 : 50,top:10}}>
              <CartesianGrid stroke="#DEDEDE" vertical={false} />
              <XAxis dataKey="name" stroke="#DEDEDE" tick={{ fill: "#1A2A56", fontWeight: "400" }} interval="equidistantPreserveStart" />
              <YAxis
                label={{
                  value: selectedTab===1 ? `Collection At Risk in $ (${labelUnit})` : "Portfolio Arc Score",
                  angle: -90,
                  position: "insideLeft",
                  fill: "#1A2A56",
                  style: { textAnchor: "middle" },
                  offset: selectedTab===1 ? -40 : 10,
                }}
                {...(selectedTab===1 ? {} : { ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] })}
                axisLine={false}
                tickCount={12}
                stroke="#DEDEDE"
                {...(selectedTab===1
                  ? {
                      tick: ({ payload, ...props }) => {
                        return (
                          <text {...props} fill={'#2F3D63'} >
                            {payload.value}
                          </text>
                        );
                      }
                    }
                  : {
                      tick: ({ payload, ...props }) => {
                        let color;
                        if (payload.value < 5) {
                          color = "green";
                        } else if (payload.value >= 5 && payload.value < 8) {
                          color = "orange";
                        } else {
                          color = "red";
                        }
                        return (
                          <text {...props} fill={color}>
                            {payload.value}
                          </text>
                        );
                      }
                    })}
              />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const { Value, name, TotalCompanies = 0 } = payload[0].payload;
                    return (
                      <Box
                        sx={{
                          background: "#5186EC",
                          opacity: 1,
                          border: "1px solid #ccc",
                          padding: "10px",
                          color: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <Typography>{`Date: ${name}`}</Typography>
                        <Typography>
                          {selectedTab===1
                            ? `Total Money At Risk: ${(Value*unit).toFixed(0)}`
                            : `Average Portfolio Score: ${parseFloat(Value).toFixed(1)}`}
                        </Typography>
                        {!selectedTab===1 && <Typography>{`Total Companies: ${TotalCompanies}`}</Typography>}
                      </Box>
                    );
                  }
                  return null;
                }}
              />
              <Line
                type="monotone"
                dataKey="Value"
                stroke="#5186EC"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Card>
    </Box>
  );
};

export default PortfolioScoreChart;


