import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

function AddCounterModal({
  open,
  onClose,
  counterValue,
  setCounterValue,
  handleSaveCounter,
}) {
    
  const handleClose = () => {
    setCounterValue("");
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            handleClose();
          }
        }}
      >
        <Box
          sx={{
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Enter Counter Value
          </Typography>
          <TextField
            label="Counter Value"
            variant="outlined"
            value={counterValue}
            onChange={(e) => setCounterValue(e.target.value)}
            fullWidth
          />
          <Button
            onClick={handleSaveCounter}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddCounterModal;
