import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInBrowser from "@mui/icons-material/OpenInBrowser";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import openInNew from "../src/Assets/Images/questionUp.svg";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import {  API, Amplify, graphqlOperation  } from 'aws-amplify';
import AWS from 'aws-sdk';
import awsExports from "./aws-exports";
import { updateCompany,createCompanyQuestion  } from "./graphql/mutations";
import { useSelector } from "react-redux";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";


Amplify.configure(awsExports);
AWS.config.update(
  {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  }
);

const AccordionItem = ({ title, className, children }) => {
  const CustomIcon = () => {
    return (
        <Box
            sx={{
                width: 30, 
                height: 30, 
                backgroundColor: '#FFFFFF', 
                borderRadius: '50%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.29)'
            }}
        >
            <ExpandMoreIcon style={{color: "#5186EC"}}/>
        </Box>
    );
  };
  return (
    <Accordion
        width="90%"
        height="76px"
        sx={{
          background: "#FAFAFC",
          boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
          borderRadius: "8px",
          opacity: "1",
          '&:before': {
                display: 'none',
            },
          "&.inrisk": {
            background: "#F25A4826",
            boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
            borderRadius: "8px",
            opacity: "1",
          },
        }}
      >
      <AccordionSummary // title
        expandIcon={<CustomIcon />}
        className={className}
        sx={{
          "&.inrisk": {
            background: "#F25A4826",
            borderRadius: "8px",
          },
          color:"#1A2A56",
          fontSize:"20px",
          fontWeight:"500"
        }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails sx={{color:"#777A82",fontSize:"14px"}}>{children}</AccordionDetails>
    </Accordion>
  );
};

function QuestionsAccordion({ company,setQuestions }) {
  const questions = company?.questions?.items;
  const [newQuestion, setNewQuestion] = useState('');
  const [updatedQuestions, setUpdatedQuestions] = useState([]);
  const [tenantId,setTenantId] = useState()

  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenant  = useSelector(state => state.userInfo.tenantId);
  const selectTenantId =()=>{
    if(isAdmin) setTenantId(URLTenant)
    else setTenantId(currentTenant)
  }
  useEffect(() => {
    if (company?.questions) {
      setUpdatedQuestions(company?.questions.items);
    }
  }, [company?.questions]);

  useEffect(() => {
    selectTenantId()
  }, []);

  const handleQuestionChange = (event) => {
    setNewQuestion(event.target.value);
  };

  const updateCompanyWithNewQuestion = async (newQuestion, tenantId, companyId) => {
    try {
      const input = {
        tenantId: tenantId,
        questions: {
          items: [
            ...company?.questions.items,
            {
              id: newQuestion.id,
              question: newQuestion.question,
              answer: newQuestion.answer,
              summary: newQuestion.summary,
              companyQuestionsId: companyId,
              companyQuestionsTenantId: tenantId,
              citations: {
                items: [],
              },
              __typename: "CompanyQuestion",
            },
          ],
        },
        view:1
      };
      const result = await API.graphql(
        graphqlOperation(updateCompany, { input })
      );
      console.log("Company updated with new question:", result.data.updateCompany);
    } catch (error) {
      console.error( error);
    }
  };

  const handleAddQuestion = async () => {
    if (newQuestion.trim().length > 0 && newQuestion.trim().length <= 100) {
      try {
        const input = {
          tenantId: tenantId,
          question: newQuestion,
          answer: false,
          summary: '',
          companyQuestionsId:company?.id,
          companyQuestionsTenantId:tenantId
        };
        const result = await API.graphql(graphqlOperation(createCompanyQuestion, { input }));
        const newQuestionData = result.data.createCompanyQuestion;
        console.log("added questions",newQuestionData)
        setUpdatedQuestions([...updatedQuestions, newQuestionData]);
        setNewQuestion('');

      await updateCompanyWithNewQuestion(newQuestionData, tenantId, company?.id);
      } catch (error) {
        console.error('Error creating new question:', error);
      }
    }
  };
  
  

  const questionCharactersRemaining = 100 - newQuestion.length;



  if (questions) {
    console.log(questions);
    questions.sort(
      (a, b) =>
        (a.template ? a.template.position : 0) -
        (b.template ? b.template.position : 0)
    );

    const cardStyle = { 
      height: '175px',
      boxShadow : 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029', 
      position:'relative',
      padding:"20px"
    }

    const typoStyle = { 
      color:"#2F3D63" , 
      fontSize: "20px" , 
      alignContent: "left" , 
      fontWeight: '500',
      paddingTop:"10px"
    }
    setQuestions(questions)

    return (
          <Box >
<Card variant="outlined" style={cardStyle}>
  <Typography style={typoStyle}>
    Ask a question
  </Typography>
  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "500px", gap: "10px" }}>
            <TextField
              style={{ width: 500 }}
              label="Write your question here"
              variant="standard"
              value={newQuestion}
              onChange={handleQuestionChange}
              InputProps={{
                endAdornment: (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      my: 1,
                      width: "90px",
                      height: "30px",
                      backgroundColor: "#5186EC",
                      borderRadius: "8px",
                      '&:hover': {
                        backgroundColor: '#5186EC',
                      },
                    }}
                    onClick={handleAddQuestion}
                    disabled={newQuestion.length === 0 || newQuestion.length > 100}
                  >
                    Add
                  </Button>
                )
              }}
            />
            <Typography style={{ alignSelf: "flex-end", fontSize: "14px", color: "#777A82" }}>
              {questionCharactersRemaining} characters remaining
            </Typography>
          </Box>
</Card>
            {updatedQuestions ? (
              <List sx={{display:"flex",flexDirection:'column',gap:"20px"}}>
                {questions.map((row) => {
                  return (
                    <AccordionItem
                      title={row.question}
                      key={row.id}
                      className={row.template?.inRisk ? "inrisk" : ""}
                    >
                      <Typography variant="body1">{row.summary}</Typography>
                      <List sx={{padding:0}}>
                        {row.citations?.items?.map((citation) => (
                          <ListItem key={citation.id} href={citation.link} target="_blank" rel="noopener noreferrer" sx={{padding:0,display:"flex",width:"fit-content"}}>
                          <Button
                            size="small"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ textTransform: "none",padding:"0 10px 0 5px",minWidth:"fit-content" }}
                            href={citation.link}
                          >
                            <img src={openInNew} alt="Open in new tab"/>
                          </Button>
                          <ListItemText
                            primary={
                              <>
                                {citation.text} - <span style={{ fontStyle: 'italic' }}>{citation.date}</span>
                              </>
                            }
                            sx={{ textAlign: "right" }}
                          />
                        </ListItem>
                        
                        ))}
                      </List>
                    </AccordionItem>
                  );
                })}
              </List>
            ) : <p>No Questions Available</p>}
          </Box>
    );
  } else {
    return null;
  }
}

export default QuestionsAccordion;
