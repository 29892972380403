import { API, graphqlOperation } from "aws-amplify";
import {
  createDashboardPreferences,
  updateDashboardPreferences,
} from "../../graphql/mutations";
import { getTenantIdAuth0 } from "../../authUtils";

export const CreateDashboardTablePreferences = async (preferencesData) => {
  try {
    const result = await API.graphql(
      graphqlOperation(createDashboardPreferences, {
        input: {
          tenantId: await getTenantIdAuth0(),
          feature: preferencesData.feature,
          preferences: preferencesData.preferences,
        },
      })
    );
    console.log("created item in preferences!");
  } catch (error) {
    console.error("Error creating preferences:", error);
  }
};

export const updateDashboardTablePreferences = async (preferencesData) => {
  try {
    if (preferencesData) {
      const updatedResult = await API.graphql(
        graphqlOperation(updateDashboardPreferences, {
          input: {
            tenantId: await getTenantIdAuth0(),
            feature: preferencesData.feature,
            preferences: preferencesData.preferences,
          },
        })
      );

      console.log(
        "Preferences updated successfully:",
        updatedResult.data.updateDashboardPreferences
      );
    } else {
      console.log("Cannot update preferences as preferencesData is null.");
    }
  } catch (error) {
    console.error("Error updating preferences:", error);
  }
};
