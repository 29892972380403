import React, { useEffect, useState, useRef } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import {
  addCompanyByAgingFile,
  companiesByName,
  getDashboardPreferences,
  getTenant,
} from "./graphql/queries";
import { createCompany, updateCompany } from "./graphql/mutations";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useFeatureFlag } from "./context/featureFlagContext";
import { triggerSendEvaluationEmail } from "./graphql/queries";
import {
  DataGridPremium,
  GridToolbar,
  GridRowModes,
  GridRowEditStopReasons,
  GridActionsCellItem,
} from "@mui/x-data-grid-premium";
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  gridClasses,
  Button,
} from "@mui/material";
import {
  agingTypes,
  countryScores,
  segmentationTypes,
  segmentationTypesEnum,
  underwritingReasons,
} from "./utils/SegmentationTypes";
import {
  amountReceivableColumnGroupingForCA,
  amountReceivableColumnGroupingForCARBOrCL,
  amountReceivableColumnGroupingForCS,
  amountReceivableColumnGroupingForRL,
  amountReceivableColumnGroupingForRN,
  amountReceivableColumnGroupingForRS,
  amountReceivableColumnGroupingForScore,
  amountReceivableColumnGroupingForUWR,
  amountReceivableColumnGroupingForUnderwriting,
  colDefAmountReceivableCA,
  colDefAmountReceivableCARBOrCL,
  colDefAmountReceivableRL,
  colDefAmountReceivableRN,
  colDefAmountReceivableRS,
  colDefAmountReceivableScore,
  colDefAmountReceivableUWROrCS,
  colDefAmountReceivableUnderwriting,
  colDefCreditLimitForCARBOrCL,
  colDefCreditLimitForCombinedAging,
  colDefCreditLimitForCountryScore,
  colDefCreditLimitForRN,
  colDefCreditLimitForRS,
  colDefCreditLimitForRecommendedLimit,
  colDefCreditLimitForScore,
  colDefCreditLimitForUnderwriting,
  colDefCreditLimitForUnderwritingReason,
  colDefRiskyCustomer,
} from "./utils/DataGridDef";
import {
  getTotalCombinedAgeCL,
  getTotalSegmentedOnCARBOrCL,
  getTotalSegmentedOnRNOrCN,
  getTotalSegmentedOnRS,
  getTotalSegmentedOnRecommendedLimit,
  getTotalSegmentedOnScore,
  getTotalSegmentedOnUWROrCS,
  getTotalSegmentedOnUnderwriting,
} from "./utils/Helper";
import {
  getTotalForAmountReceivableCA,
  getTotalForAmountReceivableCARBOrCL,
  getTotalForAmountReceivableRL,
  getTotalForAmountReceivableRNOrCN,
  getTotalForAmountReceivableRS,
  getTotalForAmountReceivableScore,
  getTotalForAmountReceivableUWSOrCS,
  getTotalForAmountReceivableUnderwriting,
} from "./utils/HelperAR";
import styled from "@emotion/styled";
import { getRangeForRiskyCustomer } from "./utils/GetRangeForRIskyCustomer";
import {
  getRiskyCustomerAging,
  getRiskyCustomerCARBOrCL,
  getRiskyCustomerCombinedAging,
  getRiskyCustomerCountryScore,
  getRiskyCustomerRNOrCN,
  getRiskyCustomerRecommendedLimit,
  getRiskyCustomerRiskSegment,
  getRiskyCustomerScore,
  getRiskyCustomerUnderwriting,
  getRiskyCustomerUnderwritingReason,
} from "./utils/HelperRC";
import { PieChart } from "@mui/x-charts";
import { Amplify, Storage } from "aws-amplify";
import { getCollectionTableRowsAndColumn } from "./utils/HelperCollectionTable";
import AmountReceivableHeatMap from "./AmountReceivableHeatMap";
import CreditLimitBarChart from "./CreditLimitBarChart";
import {
  CreateDashboardTablePreferences,
  updateDashboardTablePreferences,
} from "./utils/GraphQLHelper/DashboardTable";
import {
  AmountReceivableColumnModal,
  CollectionTableColumnModal,
  CreditLimitColumnModal,
  RiskyCustomerColumnModal,
  TableEnum,
} from "./utils/GraphQLHelper/TableEnum";
import CreateCompanyModal from "./CreateCompanyModal";
import RequestManualEvaluationDialog from "./RequestManualEvaluationDialog";
import { toast } from "react-toastify";
import { CallbackPage } from ".";
import AWS from 'aws-sdk';
import awsExports from "./aws-exports";
import NewCollectionTable from "./NewCollectionTable/NewCollectionTable";
import CollectionAtRiskTable from "./CollectionAtRiskTable/CollectionAtRiskTable";
import FileUploadModal from "./FileUploadModal";
import RiskDistributionDiagram from "./RiskDistributionDiagram/RiskDistributionDiagram";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useSelector } from "react-redux";
import RecommendationTable from "./RecommendationTable/RecommendationTable";
import { CurrencyExchange } from "@mui/icons-material";
Amplify.configure(awsExports);

AWS.config.update(
  {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  }
);


const CreateColumnHeader = (headerTitle) => {
  return (
    <Typography variant="caption" fontWeight="bold">
      {headerTitle}
    </Typography>
  );
};

export default function SummaryPage({adminAction}) {
  let { summary } = useLoaderData();
    const [tenantId,setTenantId] = useState()
    const isAdmin = useSelector(state => state.userInfo.isAdmin);
    const URLTenant = getTenantIdFromURL();
    const currentTenant  = useSelector(state => state.userInfo.tenantId);
    const selectTenantId =()=>{
      if(isAdmin) setTenantId(URLTenant)
      else setTenantId(currentTenant)
    }

  const dataWithIds = summary?.map((obj, index) => {
    const { id, ...rest } = obj;

    return {
      id: index + 1,
      ...rest,
    };
  });

  //Get rows with Aging data only
  let SummaryRows = dataWithIds;
  SummaryRows = SummaryRows.filter(
    (row) =>
      (row["AR Balance Aging 30"] !== null &&
        row["AR Balance Aging 30"] !== 0 &&
        row["AR Balance Aging 30"] !== "") ||
      (row["AR Balance Aging 60"] !== null &&
        row["AR Balance Aging 60"] !== 0 &&
        row["AR Balance Aging 60"] !== "") ||
      (row["AR Balance Aging 90"] !== null &&
        row["AR Balance Aging 90"] !== 0 &&
        row["AR Balance Aging 90"] !== "") ||
      (row["AR Balance Aging 90+"] !== null &&
        row["AR Balance Aging 90+"] !== 0 &&
        row["AR Balance Aging 90+"] !== "") ||
      (row["Current AR Balance"] !== null &&
        row["Current AR Balance"] !== 0 &&
        row["Current AR Balance"] !== "") ||
      (row["Total AR Balance"] !== null &&
        row["Total AR Balance"] !== 0 &&
        row["Total AR Balance"] !== "")
  );

  let ProspectsRows = dataWithIds;
  ProspectsRows = ProspectsRows.filter(
    (row) =>
      (row["AR Balance Aging 30"] === null ||
        row["AR Balance Aging 30"] === 0 ||
        row["AR Balance Aging 30"] === "") &&
      (row["AR Balance Aging 60"] === null ||
        row["AR Balance Aging 60"] === 0 ||
        row["AR Balance Aging 60"] === "") &&
      (row["AR Balance Aging 90"] === null ||
        row["AR Balance Aging 90"] === 0 ||
        row["AR Balance Aging 90"] === "") &&
      (row["AR Balance Aging 90+"] === null ||
        row["AR Balance Aging 90+"] === 0 ||
        row["AR Balance Aging 90+"] === "") &&
      (row["Current AR Balance"] === null ||
        row["Current AR Balance"] === 0 ||
        row["Current AR Balance"] === "") &&
      (row["Total AR Balance"] === null ||
        row["Total AR Balance"] === 0 ||
        row["Total AR Balance"] === "")
  );

  const [selectedOptionRC, setSelectedOptionRC] = useState({
    id: 8,
    label: "Recommended Limit",
  });
  const [rows, setRows] = useState(SummaryRows);

  const [toggleS3, setToggleS3] = useState(undefined);
  const [collectionTable, setCollectionTable] = useState({
    rows: [],
    columns: [],
  });

  const [selectedRangeRC, setSelectedRangeRC] = useState({
    id: 0,
    label: "All",
  });

  let limit = "";
  const [range, setRange] = useState([]);
  const [rangePieChartAR, setRangePieChartAR] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [specificCreditLimit, setSpecificCreditLimit] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [fullName, setFullName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyInfo, setCompanyInfo] = useState(null);
  const [fileModalOpen, setFileModalOpen] = useState(false)
  const [showAtRisk,setShowAtRisk] = useState(false)


  const handleSetCompanyInfo = (value) => {
    setCompanyInfo(value);
  };

  const setCompanyInfoToNull = () => {
    setCompanyInfo(null);
  };

  const [selectedOptionPieChartAR, setSelectedOptionPieChartAR] = useState({
    id: 8,
    label: "Recommended Limit",
  });
  const [selectedRangePieChartAR, setSelectedRangePieChartAR] = useState({
    id: 0,
    label: "All",
  });

  const [CollectionTablevisibility, setCollectionTablevisibility] =
    useState(true);
  const [
    HeatMapCollectionTableTotalSumvisibility,
    setHeatMapCollectionTableTotalSumvisibility,
  ] = useState(true);
  const [
    HeatMapCollectionTableCustomersVisibilty,
    setHeatMapCollectionTableCustomersVisibilty,
  ] = useState(true);
  const [MostRiskyCustomersVisibilty, setMostRiskyCustomersVisibilty] =
    useState(true);
  const [CreditLimitVisibility, setCreditLimitVisibility] = useState(true);
  const [CreditLimitChartVisibility, setCreditLimitChartVisibility] =
    useState(true);
  const [AccountReceivableVisibility, setAccountReceivableVisibility] =
    useState(true);
  const [AmountReceivableChartVisibility, setAmountReceivableChartVisibility] =
    useState(true);
  const [
    HeatMapAmountReceivableTotalSumVisibility,
    setHeatMapAmountReceivableTotalSumVisibility,
  ] = useState(true);
  const [
    HeatMapAmountReceivableCustomersVisibility,
    setHeatMapAmountReceivableCustomersVisibility,
  ] = useState(true);
  const [
    SummaryRecommendationsVisibility,
    setSummaryRecommendationsVisibility,
  ] = useState(true);

  const [
    collectionV2Visibility,
    setCollectionV2Visibility,
  ] = useState(true);

  const [
    collectionAtRiskVisibility,
    setCollectionAtRiskVisibility,
  ] = useState(true);

  const [collectionTableOrder, setcollectionTableOrder] = useState(13);
  const [
    HeatMapCollectionTableTotalSumOrder,
    setHeatMapCollectionTableTotalSumOrder,
  ] = useState(13);
  const [
    HeatMapCollectionTableCustomersOrder,
    setHeatMapCollectionTableCustomersOrder,
  ] = useState(13);
  const [MostRiskyCustomersOrder, setMostRiskyCustomersOrder] = useState(13);
  const [CreditLimitOrder, setCreditLimitOrder] = useState(13);
  const [CreditLimitChartOrder, setCreditLimitChartOrder] = useState(13);
  const [AccountReceivableOrder, setAccountReceivableOrder] = useState(13);
  const [AmountReceivableChartOrder, setAmountReceivableChartOrder] =
    useState(13);
  const [
    HeatMapAmountReceivableTotalSumOrder,
    setHeatMapAmountReceivableTotalSumOrder,
  ] = useState(13);
  const [
    HeatMapAmountReceivableCustomersOrder,
    setHeatMapAmountReceivableCustomersOrder,
  ] = useState(13);
  const [SummaryRecommendationsOrder, setSummaryRecommendationsOrder] =
    useState(13);
  
  const [collectionV2Order, setCollectionV2Order] =
    useState(13);
  
  const [collectionAtRiskOrder, setCollectionAtRiskOrder] =
    useState(13);



  function updateFields(data) {
    const fieldsToUpdate = [
      "Current Limit",
      "Current AR Balance",
      "AR Balance Aging 30",
      "AR Balance Aging 60",
      "AR Balance Aging 90",
      "AR Balance Aging 90+",
      "Requested Limit",
      "Recommended Limit",
      "Total AR Balance",
    ];

    const fieldsToModify = ["Recommended Net", "Current Net"];

    fieldsToUpdate.forEach((field) => {
      if (data[field] === "-" || data[field] === "") {
        data[field] = null;
      }
    });

    fieldsToModify.forEach((field) => {
      if (
        data[field] === "NET 30" ||
        data[field] === "NET 45" ||
        data[field] === "NET 60" ||
        data[field] === "NET 90" ||
        data[field] === "NET 120" ||
        data[field] === "NET 180"
      ) {
        let formattedValue = data[field].toLowerCase();
        formattedValue =
          formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
        data[field] = formattedValue;
      }
    });
  }

  rows?.forEach(updateFields);

  const [selectedOptionCL, setSelectedOptionCL] = useState({
    id: 8,
    label: "Recommended Limit",
  });
  const [selectedOptionAR, setSelectedOptionAR] = useState({
    id: 8,
    label: "Recommended Limit",
  });

  const dateTimeSortComparator = (date1, date2) => {
    if (!date1 && !date2) return 0; // Both dates are null
    if (!date1) return 1; // 'date1' is null, 'date2' comes first
    if (!date2) return -1; // 'date2' is null, 'date1' comes first

    return new Date(date1) - new Date(date2);
  };

  const columnDefinition = [];
  const columns = getUniqueKeys(rows).filter(
    (column) => column !== "" && column !== "id" && column !== "Evaluation Status"

  );

  columns.forEach((column) => {
    const definition = {
      field: column,
      headerName: column,
      width: 120,
      hide: false,
      renderHeader: (params) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params) => {
        if (column === "Company Name") {
          return (
            <div style={{ cursor: "pointer" }}>
              {params?.row["Company Name"]}
            </div>
          );
        }
      },
    };
    if (typeof rows[0][column] === "number") {
      definition.type = "number";
    }
    if (column === "Requested Limit") {
      definition.editable = true;
    }
    if (column === "Current Net") {
      definition.editable = true;
    }
    if (column === "Current Limit") {
      definition.editable = true;
    }
    if (column === "Last Update Time") {
      definition.sortComparator = dateTimeSortComparator;
    }

    columnDefinition.push(definition);
  });
  const actionsColumnDefinition = {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    hide: false,
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  };

  const ManualEvaluationButton = {
    field: "toggle",
    headerName: "Request Manual Evaluation",
    width: 130,
    renderCell: (params) => {
      let buttonStyle = {
        backgroundColor: "#2196F3", // Blue color
        color: "#fff", // White text color
        borderRadius: "5px", // Rounded corners
        padding: "8px 16px", // Padding for better appearance
        cursor: "pointer", // Cursor style on hover
        border: "none", // No border
        outline: "none", // Remove outline on focus
      };


      let buttonText = "Request";
      let disabled = false;


      if (params.row["Evaluation Status"] === "Sent") {
        buttonText = "Sent";
        disabled = true;
        buttonStyle = {
          ...buttonStyle,
          backgroundColor: "#ccc", // Grey color for disabled
          cursor: "default", // Default cursor for disabled
        };
      }

      return (
        <button
          style={buttonStyle}
          onClick={() => handleManualEvaluationButtonClick(params)}
          disabled={disabled}
        >
          {buttonText}
        </button>
      );
    },
  };
  columnDefinition.push(actionsColumnDefinition);
  columnDefinition.push(ManualEvaluationButton);
  const initialColumnVisibilityModel = Object.fromEntries(
    columnDefinition.map((column) => [column.field, !column.hide])
  );

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initialColumnVisibilityModel
  );

  const [amountReceivableCVM, setAmountReceivableCVM] = useState(
    AmountReceivableColumnModal
  );

  const [creditLimitCVM, setCreditLimitCVM] = useState(CreditLimitColumnModal);
  const [riskyCustomerCVM, setRiskyCustomerCVM] = useState(
    RiskyCustomerColumnModal
  );
  const [collectionTableCVM, setCollectionTableCVM] = useState(
    CollectionTableColumnModal
  );

  const navigate = useNavigate();

  const getRowId = (row) => {
    const timestamp = new Date().getTime();
    const randomNum = Math.random() * 10000; // Adjust the range as per your needs
    const uniqueId = `${timestamp}-${randomNum}`;
    return uniqueId;
  };

  const handleManualEvaluationButtonClick = async (params) => {
    setDialogOpen(true);
    setCompanyName(params.row["Company Name"]);
  };

  const handleCloseDialog = () => {
    limit = "";
    setSpecificCreditLimit("");
    setComment("");
    setSelectedOption("");
    setDialogOpen(false);
    setCompanyName("");
    setCompanyName("");
    setFullName("");
    setTitle("");
    setEmail("");
    setPhoneNumber("");
  };

  const handleSubmit = async (row) => {
    try {
      let limit;
      if (selectedOption === "Specific Credit Limit") {
        console.log("Specific credit limit:", specificCreditLimit);
        limit = specificCreditLimit;
      } else {
        limit = "Max";
      }

      let result = await API.graphql(
        graphqlOperation(companiesByName, {
          name: companyName,
          limit: 1,
          filter: {
            tenantId: {
              eq: tenantId,
            },
          },
        })
      );

      const companyid = result.data.companiesByName.items[0].id;
      let tenant = await API.graphql(
        graphqlOperation(getTenant, {
          id: tenantId,
        })
      );
      const tenantName = tenant.data.getTenant.name;
      await API.graphql(
        graphqlOperation(triggerSendEvaluationEmail, {
          tenantId: tenantId,
          tenantName: tenantName,
          id: companyid,
          companyName: companyName,
          requestedAmount: limit,
          comments: comment,
          fullName: fullName,
          title: title,
          email: email,
          phoneNumber: phoneNumber,
        })
      );

      let foundCompany = rows.find(
        (obj) => obj["Company Name"] === companyName
      );
      if (foundCompany) {
        foundCompany["Evaluation Status"] = "Sent";
      }
      handleCloseDialog();
      uploadJsonToS3();
      toast.success("Evaluation request sent successfully!");
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error(
        "An error occurred while sending the evaluation request. Please try again later."
      );
    }
  };

  useEffect(() => {
    console.log("Limit in useEffect:", limit);
  }, [limit]);

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleCommentsChange = (event) => {
    setComment(event.target.value);
  };

  const handleSpecificCreditLimitValue = (event) => {
    setSpecificCreditLimit(event.target.value);
  };

  const handleOptionChange = (event) => {
    console.log("Limit", limit);
    setSelectedOption(event.target.value);
  };

  const handleCellClick = async (params) => {

    if (params.field === "Company Name") {
      let nextToken = null;
      let items = [];
      let result;
      do {
        result = await API.graphql(
          graphqlOperation(companiesByName, {
            name: params.row["Company Name"],
            limit: 1,
            filter: {
              tenantId: {
                eq: tenantId,
              },
            },
            nextToken: nextToken,
          })
        );

        items = result.data.companiesByName.items;
        nextToken = result.data.companiesByName.nextToken;

        if (items.length > 0) {
          break;
        }
      } while (nextToken != null);

      let company = null;
      if (items.length === 0) {
        try {
          result = await API.graphql(
            graphqlOperation(createCompany, {
              input: {
                name: params.row["Company Name"],
                tenantId: tenantId,
              },
            })
          );
        } catch (error) {
          console.log(error);
        }
        company = result.data.createCompany;
      } else {
        company = items[0];
      }
      if(isAdmin) window.open(`/tenant-dashboard/${tenantId}/company/${company.id}`, "_blank");
      else window.open(`/company/${company.id}`, "_blank");
    }
  };

  const handleColumnVisibilityModelChange = async (model, details) => {
    console.log("Column Visibility Model:", model);
    setColumnVisibilityModel(model);
    const preferenceData = {
      feature: TableEnum.SUMMARY_TABLE,
      preferences: JSON.stringify(model),
    };
    updateDashboardTablePreferences(preferenceData);
  };

  const handleColumnVisibilityModelChangeForAR = async (model, details) => {
    console.log("Column Visibility Model:", model);
    const temp = { ...amountReceivableCVM };
    temp[selectedOptionAR.label] = model;

    setAmountReceivableCVM(temp);
    const preferenceData = {
      feature: TableEnum.AMOUNT_RECEIVABLE,
      preferences: JSON.stringify(temp),
    };
    updateDashboardTablePreferences(preferenceData);
  };

  const handleCVMChangeForCreditLimit = async (model, details) => {
    console.log("Column Visibility Model:", model);
    const temp = { ...creditLimitCVM };
    temp[selectedOptionCL.label] = model;

    setCreditLimitCVM(temp);
    const preferenceData = {
      feature: TableEnum.CREDIT_LIMIT,
      preferences: JSON.stringify(temp),
    };
    updateDashboardTablePreferences(preferenceData);
  };

  const handleCVMChangeForRiskyCustomer = async (model, details) => {
    console.log("Column Visibility Model:", model);
    const temp = { ...riskyCustomerCVM };
    temp[selectedOptionRC.label] = model;

    setRiskyCustomerCVM(temp);
    const preferenceData = {
      feature: TableEnum.RISKY_CUSTOMER,
      preferences: JSON.stringify(temp),
    };
    updateDashboardTablePreferences(preferenceData);
  };

  const handleCVMChangeForCollectionTable = async (model, details) => {
    console.log("Column Visibility Model:", model);
    setCollectionTableCVM(model);
    const preferenceData = {
      feature: TableEnum.COLLECTION_TABLE,
      preferences: JSON.stringify(model),
    };
    updateDashboardTablePreferences(preferenceData);
  };

  function getUniqueKeys(arrayOfObjects) {
    let uniqueKeys = new Set();
    arrayOfObjects?.forEach((obj) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          uniqueKeys.add(key);
        }
      }
    });
    return Array.from(uniqueKeys);
  }

  const handleDropdownChange = (event, newValue) => {
    if (newValue) {
      setSelectedOptionCL(newValue);
    }
  };

  const handleDropdownChangeForAR = (event, newValue) => {
    if (newValue) {
      setSelectedOptionAR(newValue);
    }
  };

  const handleDropdownChangeForRC = (event, newValue) => {
    if (newValue) {
      setSelectedOptionRC(newValue);
      const rangeArray = getRangeForRiskyCustomer(newValue.id);
      setRange(rangeArray);
      setSelectedRangeRC({ id: 0, label: "All" });
    }
  };

  const handleDropdownChangeForPieChartAR = (event, newValue) => {
    if (newValue) {
      setSelectedOptionPieChartAR(newValue);
      const rangeArray = getRangeForRiskyCustomer(newValue.id);
      setRangePieChartAR(rangeArray);
      setSelectedRangePieChartAR({ id: 0, label: "All" });
    }
  };

  const handleRangeChangeRC = (event, newValue) => {
    if (newValue) {
      setSelectedRangeRC(newValue);
    }
  };

  const handleRangeChangePCAR = (event, newValue) => {
    if (newValue) {
      setSelectedRangePieChartAR(newValue);
    }
  };

  const createPayload = (element, chartType) => {
    let payload = { label: element.metric };

    if (chartType === "customer") {
      let stringWithoutCommas = element.customer.replace(/,/g, "");
      let parsedNumber = parseInt(stringWithoutCommas, 10);
      payload.value = parsedNumber;
    } else {
      let stringWithoutCommas = element.total.replace(/,/g, "");
      let parsedNumber = parseInt(stringWithoutCommas, 10);
      if (parsedNumber < 0) {
        payload.value = 0;
      } else {
        payload.value = parsedNumber;
      }
    }
    return payload;
  };

  const setARPieChartData = (dataArray, chartType) => {
    let data = [];

    dataArray.forEach((element) => {
      if (element.metric === "Current AR Balance") {
        const result = createPayload(element, chartType);
        data.push(result);
      } else if (element.metric === "AR Balance Aging 30") {
        data.push(createPayload(element, chartType));
      } else if (element.metric === "AR Balance Aging 60") {
        data.push(createPayload(element, chartType));
      } else if (element.metric === "AR Balance Aging 90") {
        data.push(createPayload(element, chartType));
      } else if (element.metric === "AR Balance Aging 90+") {
        data.push(createPayload(element, chartType));
      }
    });

    return data;
  };

  const [rowModesModel, setRowModesModel] = React.useState({});

  const uploadJsonToS3 = async () => {
    try {
      const s3 = new AWS.S3();
      const mergedRows = rows.concat(ProspectsRows);
      const jsonString = JSON.stringify(mergedRows);
      const key = "public/summary_" + tenantId + ".json"

      const result = await s3.putObject({
        Bucket: 'crediarc-content01853-production', // Specify your bucket name
        Key: key,
        Body: jsonString,
        ContentType: 'application/json',
      }).promise();

      console.log("File uploaded successfully", result);
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleChange = async (row) => {
    try {
      let companyNameValue = row["Company Name"];
  
      if (companyNameValue.trim() !== "" && row["Company Name"].trim() !== "") {
        let result = await API.graphql(
          graphqlOperation(companiesByName, {
            name: companyNameValue,
            limit: 1,
            filter: {
              tenantId: {
                eq: tenantId,
              },
            },
          })
        );
  
        let company = null;
        let requestedCreditLimit = row["Requested Limit"];
  
        if (result.data.companiesByName.items.length > 0) {
          company = result.data.companiesByName.items[0];
          await API.graphql(
            graphqlOperation(updateCompany, {
              input: {
                tenantId: tenantId,
                id: company.id,
                requestedCreditLimit: parseFloat(requestedCreditLimit),
                view: 1,
              },
            })
          );
          company = result.data.updateCompany;
          console.log("updated");
        } else {
          console.log("No company found with the given name");
        }
      } else {
        console.log("companyNameValue or row['Account ID'] is an empty string");
      }
    } catch (error) {
      console.log("error while saving file", error);
    }
  };
  

  const handleButtonClick = () => {
    setFileModalOpen(true)
  };

  const handleSaveClick = (id) => () => {
    // const jsonData = rows;
    const foundItem = rows.find((item) => item.id === id);
    handleChange(foundItem);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    const isConfirmed = window.confirm("Are you sure you want to delete?");
    if (isConfirmed) {
      setRows(rows.filter((row) => row.id !== id));
      setToggleS3(!toggleS3);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
      setToggleS3(!toggleS3);
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    console.log("Process Row Update", updatedRow);
    setToggleS3(!toggleS3);
    handleChange(updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
      selectTenantId();
    
    // set initial range for Recommended Limit
    if (selectedOptionRC.id) {
      const rangeArray = getRangeForRiskyCustomer(selectedOptionRC.id);
      setRange(rangeArray);
    }
    if (selectedOptionPieChartAR.id) {
      const initialPieRange = getRangeForRiskyCustomer(selectedOptionRC.id);
      setRangePieChartAR(initialPieRange);
    }
  }, []);

  const checkAndFetchPreferencesForAmountReceivable = async () => {
    if(tenantId){
    try {
      const feature = TableEnum.AMOUNT_RECEIVABLE;
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, { tenantId, feature })
      );

      if (result.data.getDashboardPreferences) {
        const existingPreferences =
          result.data.getDashboardPreferences.preferences;
        console.log("Item exists:", JSON.parse(existingPreferences));
        setAmountReceivableCVM(JSON.parse(existingPreferences));
      } else {
        const payload = {
          feature: TableEnum.AMOUNT_RECEIVABLE,
          preferences: JSON.stringify(amountReceivableCVM),
        };
        CreateDashboardTablePreferences(payload);
      }
    } catch (error) {
      console.error("Error checking and fetching preferences:", error);
    }
  }
  };

  const checkAndFetchPreferencesForCreditLimit = async () => {
    if(tenantId){
    try {
      const feature = TableEnum.CREDIT_LIMIT;
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, { tenantId, feature })
      );

      if (result.data.getDashboardPreferences) {
        const existingPreferences =
          result.data.getDashboardPreferences.preferences;
        console.log("Item exists:", JSON.parse(existingPreferences));
        setCreditLimitCVM(JSON.parse(existingPreferences));
      } else {
        const payload = {
          feature: TableEnum.CREDIT_LIMIT,
          preferences: JSON.stringify(creditLimitCVM),
        };
        CreateDashboardTablePreferences(payload);
      }
    } catch (error) {
      console.error("Error checking and fetching preferences:", error);
    }
  }
  };

  const checkAndFetchPreferencesForRiskyCustomer = async () => {
    if(tenantId){
    try {
      const feature = TableEnum.RISKY_CUSTOMER;
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, { tenantId, feature })
      );

      if (result.data.getDashboardPreferences) {
        const existingPreferences =
          result.data.getDashboardPreferences.preferences;
        console.log("Item exists:", JSON.parse(existingPreferences));
        setRiskyCustomerCVM(JSON.parse(existingPreferences));
      } else {
        const payload = {
          feature: TableEnum.RISKY_CUSTOMER,
          preferences: JSON.stringify(riskyCustomerCVM),
        };
        CreateDashboardTablePreferences(payload);
      }
    } catch (error) {
      console.error("Error checking and fetching preferences:", error);
    }
  }
  };

  const checkAndFetchPreferencesForCollectionTable = async () => {
    if(tenantId){
    try {
      const feature = TableEnum.COLLECTION_TABLE;
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, { tenantId, feature })
      );

      if (result.data.getDashboardPreferences) {
        const existingPreferences =
          result.data.getDashboardPreferences.preferences;
        console.log("Item exists:", JSON.parse(existingPreferences));
        setCollectionTableCVM(JSON.parse(existingPreferences));
      } else {
        const payload = {
          feature: TableEnum.COLLECTION_TABLE,
          preferences: JSON.stringify(collectionTableCVM),
        };
        CreateDashboardTablePreferences(payload);
      }
    } catch (error) {
      console.error("Error checking and fetching preferences:", error);
    }
  }
  };

  const checkAndFetchPreferencesForSummaryTable = async () => {
    if(tenantId){
    try {
      const feature = TableEnum.SUMMARY_TABLE;
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, { tenantId, feature })
      );

      if (result.data.getDashboardPreferences) {
        const existingPreferences =
          result.data.getDashboardPreferences.preferences;
        console.log("Item exists:", JSON.parse(existingPreferences));
        setColumnVisibilityModel(JSON.parse(existingPreferences));
      } else {
        const payload = {
          feature: TableEnum.SUMMARY_TABLE,
          preferences: JSON.stringify(initialColumnVisibilityModel),
        };
        CreateDashboardTablePreferences(payload);
      }
    } catch (error) {
      console.error("Error checking and fetching preferences:", error);
    }
  }
  };



  useEffect(() => {
    checkAndFetchPreferencesForSummaryTable();
    checkAndFetchPreferencesForAmountReceivable();
    checkAndFetchPreferencesForCreditLimit();
    checkAndFetchPreferencesForRiskyCustomer();
    checkAndFetchPreferencesForCollectionTable();
  }, [tenantId]);

  useEffect(() => {
    const gridDefData = getCollectionTableRowsAndColumn(rows);
    setCollectionTable(gridDefData);
  }, [rows]);

  useEffect(() => {
    if (toggleS3 !== undefined) {
      uploadJsonToS3();
    }
  }, [!toggleS3]);

  let gridRowsCL = [];
  let gridColumnsCL = [];

  const allowedScoreSegment = [
    "Alternative Score",
    "Legal Score",
    "General Risk Score",
    "Failure Score",
    "Delinquency Score",
  ];

  if (allowedScoreSegment.includes(selectedOptionCL?.label)) {
    gridRowsCL = getTotalSegmentedOnScore(rows, selectedOptionCL?.label);
    gridColumnsCL = colDefCreditLimitForScore;
  } else if (
    selectedOptionCL?.label === "Recommended Net" ||
    selectedOptionCL?.label === "Current Net"
  ) {
    gridRowsCL = getTotalSegmentedOnRNOrCN(rows, selectedOptionCL?.label);
    gridColumnsCL = colDefCreditLimitForRN;
  } else if (selectedOptionCL?.label === "Underwriting") {
    gridRowsCL = getTotalSegmentedOnUnderwriting(rows);
    gridColumnsCL = colDefCreditLimitForUnderwriting;
  } else if (selectedOptionCL?.label === "Recommended Limit") {
    gridRowsCL = getTotalSegmentedOnRecommendedLimit(rows);
    gridColumnsCL = colDefCreditLimitForRecommendedLimit;
  } else if (
    selectedOptionCL?.label === "Current AR Balance" ||
    selectedOptionCL?.label === "Current Limit"
  ) {
    gridRowsCL = getTotalSegmentedOnCARBOrCL(rows, selectedOptionCL?.label);
    gridColumnsCL = colDefCreditLimitForCARBOrCL;
  } else if (selectedOptionCL?.label === "Risk Segment") {
    gridRowsCL = getTotalSegmentedOnRS(rows);
    gridColumnsCL = colDefCreditLimitForRS;
  } else if (selectedOptionCL?.label === "Combined Aging") {
    gridRowsCL = getTotalCombinedAgeCL(rows, "combined");
    gridColumnsCL = colDefCreditLimitForCombinedAging;
  } else if (selectedOptionCL?.label === "Country Score") {
    gridRowsCL = getTotalSegmentedOnUWROrCS(
      rows,
      selectedOptionCL?.label,
      countryScores
    );
    gridColumnsCL = colDefCreditLimitForCountryScore;
  } else if (selectedOptionCL?.label === "Underwriting Reason") {
    gridRowsCL = getTotalSegmentedOnUWROrCS(
      rows,
      selectedOptionCL?.label,
      underwritingReasons
    );
    gridColumnsCL = colDefCreditLimitForUnderwritingReason;
  } else if (
    selectedOptionCL?.label === agingTypes.AGINNG30.label ||
    selectedOptionCL?.label === agingTypes.AGINNG60.label ||
    selectedOptionCL?.label === agingTypes.AGINNG90.label ||
    selectedOptionCL?.label === agingTypes.AGINNG90PLUS.label
  ) {
    gridRowsCL = getTotalCombinedAgeCL(rows, selectedOptionCL?.label);
    gridColumnsCL = colDefCreditLimitForCombinedAging;
  }

  let gridRowsAR = [];
  let gridColumnsAR = [];
  let gridColGroupAR = [];

  if (selectedOptionAR?.id === 10) {
    gridRowsAR = getTotalForAmountReceivableRS(rows);
    gridColumnsAR = colDefAmountReceivableRS;
    gridColGroupAR = amountReceivableColumnGroupingForRS;
  } else if (selectedOptionAR?.id === 11) {
    gridRowsAR = getTotalForAmountReceivableCA(rows, "combined");
    gridColumnsAR = colDefAmountReceivableCA;
    gridColGroupAR = amountReceivableColumnGroupingForCA;
  } else if (selectedOptionAR?.id === 9 || selectedOptionAR?.id === 18) {
    gridRowsAR = getTotalForAmountReceivableCARBOrCL(
      rows,
      selectedOptionAR?.label
    );
    gridColumnsAR = colDefAmountReceivableCARBOrCL;
    gridColGroupAR = amountReceivableColumnGroupingForCARBOrCL;
  } else if (selectedOptionAR?.id === 8) {
    gridRowsAR = getTotalForAmountReceivableRL(rows);
    gridColumnsAR = colDefAmountReceivableRL;
    gridColGroupAR = amountReceivableColumnGroupingForRL;
  } else if (selectedOptionAR?.id === 7) {
    gridRowsAR = getTotalForAmountReceivableUnderwriting(rows);
    gridColumnsAR = colDefAmountReceivableUnderwriting;
    gridColGroupAR = amountReceivableColumnGroupingForUnderwriting;
  } else if (selectedOptionAR?.id === 2 || selectedOptionAR?.id === 19) {
    gridRowsAR = getTotalForAmountReceivableRNOrCN(
      rows,
      selectedOptionAR?.label
    );
    gridColumnsAR = colDefAmountReceivableRN;
    gridColGroupAR = amountReceivableColumnGroupingForRN;
  } else if (allowedScoreSegment.includes(selectedOptionAR?.label)) {
    gridRowsAR = getTotalForAmountReceivableScore(
      rows,
      selectedOptionAR?.label
    );
    gridColumnsAR = colDefAmountReceivableScore;
    gridColGroupAR = amountReceivableColumnGroupingForScore;
  } else if (
    selectedOptionAR?.id === 14 ||
    selectedOptionAR?.id === 15 ||
    selectedOptionAR?.id === 16 ||
    selectedOptionAR?.id === 17
  ) {
    gridRowsAR = getTotalForAmountReceivableCA(rows, selectedOptionAR?.label);
    gridColumnsAR = colDefAmountReceivableCA;
    gridColGroupAR = amountReceivableColumnGroupingForCA;
  } else if (selectedOptionAR?.id === 12) {
    gridRowsAR = getTotalForAmountReceivableUWSOrCS(
      rows,
      selectedOptionAR?.label,
      countryScores
    );
    gridColumnsAR = colDefAmountReceivableUWROrCS;
    gridColGroupAR = amountReceivableColumnGroupingForCS;
  } else if (selectedOptionAR?.id === 13) {
    gridRowsAR = getTotalForAmountReceivableUWSOrCS(
      rows,
      selectedOptionAR?.label,
      underwritingReasons
    );
    gridColumnsAR = colDefAmountReceivableUWROrCS;
    gridColGroupAR = amountReceivableColumnGroupingForUWR;
  }

  function calculateSortingScore(
    riskSegment,
    failureScore,
    alternativeScore,
    delinquencyScore
  ) {
    let sum = 0;
    let denominator = 0;

    if (riskSegment !== undefined) {
      sum += 5 * riskSegment;
      denominator += 5;
    }

    if (failureScore !== undefined) {
      sum += 3 * failureScore;
      denominator += 3;
    }

    if (alternativeScore !== undefined) {
      sum += 2 * alternativeScore;
      denominator += 2;
    }

    if (delinquencyScore !== undefined) {
      sum += 2 * delinquencyScore;
      denominator += 2;
    }

    if (denominator !== 0) {
      return sum / denominator;
    } else {
      // Handle the case where all scores are missing
      console.error(
        "All scores are missing. Unable to calculate sorting score."
      );
      return undefined;
    }
  }

  const getTop10RiskyCustomer = (data) => {
    const processedData = data.map((item) => {
      const riskSegment = parseFloat(item["Risk Segment"]);
      const failureScore = parseFloat(item["Failure Score"]);
      const alternativeScore = parseFloat(item["Alternative Score"]);
      const delinquencyScore = parseFloat(item["Delinquency Score"]);

      // Check for NaN and set SortingScore accordingly
      const sortingScore = calculateSortingScore(
        isNaN(riskSegment) ? undefined : riskSegment,
        isNaN(failureScore) ? undefined : failureScore,
        isNaN(alternativeScore) ? undefined : alternativeScore,
        isNaN(delinquencyScore) ? undefined : delinquencyScore
      );

      return { ...item, SortingScore: sortingScore };
    });

    const sortedData = processedData.slice().sort((a, b) => {
      const scoreA = a.SortingScore || 0;
      const scoreB = b.SortingScore || 0;

      return scoreB - scoreA;
    });

    const top10RiskyCompanies = sortedData.slice(0, 10).map((item, index) => ({
      ...item,
      Rank: index + 1,
    }));

    return top10RiskyCompanies;
  };

  let gridRowsRC = [];

  if (selectedOptionRC.id === segmentationTypesEnum.RECOMMENDED_LIMIT.id) {
    const dataArray = getRiskyCustomerRecommendedLimit(
      rows,
      selectedRangeRC.id
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (
    selectedOptionRC.id === segmentationTypesEnum.RECOMMENDED_NET.id ||
    selectedOptionRC.id === segmentationTypesEnum.CURRENT_NET.id
  ) {
    const dataArray = getRiskyCustomerRNOrCN(
      rows,
      selectedRangeRC.id,
      selectedOptionRC.label
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (selectedOptionRC.id === segmentationTypesEnum.UNDERWRITING.id) {
    const dataArray = getRiskyCustomerUnderwriting(rows, selectedRangeRC.id);
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (allowedScoreSegment.includes(selectedOptionRC?.label)) {
    const dataArray = getRiskyCustomerScore(
      rows,
      selectedRangeRC.id,
      selectedOptionRC?.label
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (
    selectedOptionRC.id === segmentationTypesEnum.ARB_AGING30.id ||
    selectedOptionRC.id === segmentationTypesEnum.ARB_AGING60.id ||
    selectedOptionRC.id === segmentationTypesEnum.ARB_AGING90.id ||
    selectedOptionRC.id === segmentationTypesEnum.ARB_AGING90PLUS.id
  ) {
    const dataArray = getRiskyCustomerAging(
      rows,
      selectedRangeRC.id,
      selectedOptionRC.label
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (selectedOptionRC.id === segmentationTypesEnum.COMBINED_AGING.id) {
    const dataArray = getRiskyCustomerCombinedAging(rows, selectedRangeRC.id);
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (
    selectedOptionRC.id === segmentationTypesEnum.CARB.id ||
    selectedOptionRC.id === segmentationTypesEnum.CURRENT_LIMIT.id
  ) {
    const dataArray = getRiskyCustomerCARBOrCL(
      rows,
      selectedRangeRC.id,
      selectedOptionRC.label
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (selectedOptionRC.id === segmentationTypesEnum.RISK_SEGMENT.id) {
    const dataArray = getRiskyCustomerRiskSegment(rows, selectedRangeRC.id);
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (selectedOptionRC.id === segmentationTypesEnum.COUNTRY_SCORE.id) {
    const dataArray = getRiskyCustomerCountryScore(rows, selectedRangeRC.id);
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (
    selectedOptionRC.id === segmentationTypesEnum.UNDERWRITING_REASON.id
  ) {
    const dataArray = getRiskyCustomerUnderwritingReason(
      rows,
      selectedRangeRC.id
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  }

  // Set Data for Pie Chart
  let pieChartDataCustomer = [];
  let pieChartDataTotalSum = [];

  if (selectedOptionPieChartAR.id === segmentationTypesEnum.RISK_SEGMENT.id) {
    const dataArray = getRiskyCustomerRiskSegment(
      rows,
      selectedRangePieChartAR.id
    );
    const temp = getTotalForAmountReceivableRS(dataArray);
    pieChartDataCustomer = setARPieChartData(temp, "customer");
    pieChartDataTotalSum = setARPieChartData(temp, "sum");
  } else if (
    selectedOptionPieChartAR.id === segmentationTypesEnum.COUNTRY_SCORE.id
  ) {
    const dataArray = getRiskyCustomerCountryScore(
      rows,
      selectedRangePieChartAR.id
    );
    const temp = getTotalForAmountReceivableUWSOrCS(
      dataArray,
      selectedOptionPieChartAR.label,
      countryScores
    );
    pieChartDataCustomer = setARPieChartData(temp, "customer");
    pieChartDataTotalSum = setARPieChartData(temp, "sum");
  } else if (
    selectedOptionPieChartAR.id === segmentationTypesEnum.RECOMMENDED_LIMIT.id
  ) {
    const dataArray = getRiskyCustomerRecommendedLimit(
      rows,
      selectedRangePieChartAR.id
    );
    const temp = getTotalForAmountReceivableRL(dataArray);
    pieChartDataCustomer = setARPieChartData(temp, "customer");
    pieChartDataTotalSum = setARPieChartData(temp, "sum");
  } else if (
    selectedOptionPieChartAR.id === segmentationTypesEnum.RECOMMENDED_NET.id ||
    selectedOptionPieChartAR.id === segmentationTypesEnum.CURRENT_NET.id
  ) {
    const dataArray = getRiskyCustomerRNOrCN(
      rows,
      selectedRangePieChartAR.id,
      selectedOptionPieChartAR.label
    );
    const temp = getTotalForAmountReceivableRNOrCN(
      dataArray,
      selectedOptionPieChartAR.label
    );
    pieChartDataCustomer = setARPieChartData(temp, "customer");
    pieChartDataTotalSum = setARPieChartData(temp, "sum");
  } else if (
    selectedOptionPieChartAR.id === segmentationTypesEnum.UNDERWRITING.id
  ) {
    const dataArray = getRiskyCustomerUnderwriting(
      rows,
      selectedRangePieChartAR.id
    );
    const temp = getTotalForAmountReceivableUnderwriting(dataArray);
    pieChartDataCustomer = setARPieChartData(temp, "customer");
    pieChartDataTotalSum = setARPieChartData(temp, "sum");
  } else if (allowedScoreSegment.includes(selectedOptionPieChartAR?.label)) {
    const dataArray = getRiskyCustomerScore(
      rows,
      selectedRangePieChartAR.id,
      selectedOptionPieChartAR?.label
    );
    const temp = getTotalForAmountReceivableScore(
      dataArray,
      selectedOptionPieChartAR?.label
    );
    pieChartDataCustomer = setARPieChartData(temp, "customer");
    pieChartDataTotalSum = setARPieChartData(temp, "sum");
  } else if (
    selectedOptionPieChartAR.id === segmentationTypesEnum.ARB_AGING30.id ||
    selectedOptionPieChartAR.id === segmentationTypesEnum.ARB_AGING60.id ||
    selectedOptionPieChartAR.id === segmentationTypesEnum.ARB_AGING90.id ||
    selectedOptionPieChartAR.id === segmentationTypesEnum.ARB_AGING90PLUS.id
  ) {
    const dataArray = getRiskyCustomerAging(
      rows,
      selectedRangePieChartAR.id,
      selectedOptionPieChartAR.label
    );
    const temp = getTotalForAmountReceivableCA(
      dataArray,
      selectedOptionPieChartAR?.label
    );
    pieChartDataCustomer = setARPieChartData(temp, "customer");
    pieChartDataTotalSum = setARPieChartData(temp, "sum");
  } else if (
    selectedOptionPieChartAR.id === segmentationTypesEnum.COMBINED_AGING.id
  ) {
    const dataArray = getRiskyCustomerCombinedAging(
      rows,
      selectedRangePieChartAR.id
    );
    const temp = getTotalForAmountReceivableCA(dataArray, "combined");
    pieChartDataCustomer = setARPieChartData(temp, "customer");

    pieChartDataTotalSum = setARPieChartData(temp, "sum");
  } else if (
    selectedOptionPieChartAR.id === segmentationTypesEnum.UNDERWRITING_REASON.id
  ) {
    const dataArray = getRiskyCustomerUnderwritingReason(
      rows,
      selectedRangePieChartAR.id
    );
    const temp = getTotalForAmountReceivableUWSOrCS(
      dataArray,
      selectedOptionPieChartAR?.label,
      underwritingReasons
    );
    pieChartDataCustomer = setARPieChartData(temp, "customer");
    pieChartDataTotalSum = setARPieChartData(temp, "sum");
  } else if (
    selectedOptionPieChartAR.id === segmentationTypesEnum.CARB.id ||
    selectedOptionPieChartAR.id === segmentationTypesEnum.CURRENT_LIMIT.id
  ) {
    const dataArray = getRiskyCustomerCARBOrCL(
      rows,
      selectedRangePieChartAR.id,
      selectedOptionPieChartAR.label
    );
    const temp = getTotalForAmountReceivableCARBOrCL(
      dataArray,
      selectedOptionPieChartAR?.label
    );
    pieChartDataCustomer = setARPieChartData(temp, "customer");
    pieChartDataTotalSum = setARPieChartData(temp, "sum");
  }

  const handleARCellClick = (params) => {
    if (params.colDef.headerName !== "Metric") {
      const groupId = params.colDef.groupId;
      const rangeId = groupId - 1;
      let filteredSummary = [];

      const rangeArray = getRangeForRiskyCustomer(selectedOptionAR.id);
      const rangeTypeObj = rangeArray.find((item) => item.id === rangeId);

      localStorage.setItem(
        "segmentName",
        JSON.stringify(selectedOptionAR.label)
      );
      localStorage.setItem("rangeTypeName", JSON.stringify(rangeTypeObj.label));
      localStorage.setItem("metricName", JSON.stringify(params.row.metric));

      if (
        selectedOptionAR.id === segmentationTypesEnum.RECOMMENDED_NET.id ||
        selectedOptionAR.id === segmentationTypesEnum.CURRENT_NET.id
      ) {
        const data = getRiskyCustomerRNOrCN(
          rows,
          rangeId,
          selectedOptionAR.label
        );
        filteredSummary = data.filter(
          (item) => item[params.row.metric] > 0 || item[params.row.metric] < 0
        );
      } else if (
        selectedOptionAR.id === segmentationTypesEnum.RISK_SEGMENT.id
      ) {
        const data = getRiskyCustomerRiskSegment(rows, rangeId);
        filteredSummary = data.filter(
          (item) => item[params.row.metric] > 0 || item[params.row.metric] < 0
        );
      } else if (
        selectedOptionAR.id === segmentationTypesEnum.COUNTRY_SCORE.id
      ) {
        const data = getRiskyCustomerCountryScore(rows, rangeId);
        filteredSummary = data.filter(
          (item) => item[params.row.metric] > 0 || item[params.row.metric] < 0
        );
      } else if (
        selectedOptionAR.id === segmentationTypesEnum.RECOMMENDED_LIMIT.id
      ) {
        const data = getRiskyCustomerRecommendedLimit(rows, rangeId);
        filteredSummary = data.filter(
          (item) => item[params.row.metric] > 0 || item[params.row.metric] < 0
        );
      } else if (
        selectedOptionAR.id === segmentationTypesEnum.UNDERWRITING.id
      ) {
        const data = getRiskyCustomerUnderwriting(rows, rangeId);
        filteredSummary = data.filter(
          (item) => item[params.row.metric] > 0 || item[params.row.metric] < 0
        );
      } else if (allowedScoreSegment.includes(selectedOptionAR?.label)) {
        const data = getRiskyCustomerScore(
          rows,
          rangeId,
          selectedOptionAR?.label
        );
        filteredSummary = data.filter(
          (item) => item[params.row.metric] > 0 || item[params.row.metric] < 0
        );
      } else if (
        selectedOptionAR.id === segmentationTypesEnum.ARB_AGING30.id ||
        selectedOptionAR.id === segmentationTypesEnum.ARB_AGING60.id ||
        selectedOptionAR.id === segmentationTypesEnum.ARB_AGING90.id ||
        selectedOptionAR.id === segmentationTypesEnum.ARB_AGING90PLUS.id
      ) {
        const data = getRiskyCustomerAging(
          rows,
          rangeId,
          selectedOptionAR?.label
        );
        filteredSummary = data.filter(
          (item) => item[params.row.metric] > 0 || item[params.row.metric] < 0
        );
      } else if (
        selectedOptionAR.id === segmentationTypesEnum.COMBINED_AGING.id
      ) {
        const data = getRiskyCustomerCombinedAging(rows, rangeId);
        filteredSummary = data.filter(
          (item) => item[params.row.metric] > 0 || item[params.row.metric] < 0
        );
      } else if (
        selectedOptionAR.id === segmentationTypesEnum.UNDERWRITING_REASON.id
      ) {
        const data = getRiskyCustomerUnderwritingReason(rows, rangeId);
        filteredSummary = data.filter(
          (item) => item[params.row.metric] > 0 || item[params.row.metric] < 0
        );
      } else if (
        selectedOptionAR.id === segmentationTypesEnum.CARB.id ||
        selectedOptionAR.id === segmentationTypesEnum.CURRENT_LIMIT.id
      ) {
        const data = getRiskyCustomerCARBOrCL(
          rows,
          rangeId,
          selectedOptionAR.label
        );
        filteredSummary = data.filter(
          (item) => item[params.row.metric] > 0 || item[params.row.metric] < 0
        );
      }

      localStorage.setItem("filteredSummary", JSON.stringify(filteredSummary));
      if(adminAction) window.open(`/tenant-dashboard/${tenantId}/filteredSummary`, "_blank");
      else window.open("/filteredSummary", "_blank");
    }
  };

  const handleCTCellClick = (params) => {
    if (params.colDef.headerName !== "Current Net") {
      let filteredSummary = [];
      const data = getRiskyCustomerRNOrCN(
        rows,
        params.row.rangeId,
        "Current Net"
      );
      filteredSummary = data.filter(
        (item) => item[params.colDef.headerName] > 0
      );
      localStorage.setItem("filteredSummary", JSON.stringify(filteredSummary));
      const rangeArray = getRangeForRiskyCustomer(
        segmentationTypesEnum.CURRENT_NET.id
      );
      const rangeTypeObj = rangeArray.find(
        (item) => item.id === params.row.rangeId
      );

      localStorage.setItem(
        "segmentName",
        JSON.stringify(segmentationTypesEnum.CURRENT_NET.label)
      );
      localStorage.setItem("rangeTypeName", JSON.stringify(rangeTypeObj.label));
      localStorage.setItem(
        "metricName",
        JSON.stringify(params.colDef.headerName)
      );
      if(adminAction) window.open(`/tenant-dashboard/${tenantId}/filteredSummary`, "_blank");
      else window.open("/filteredSummary", "_blank");
    }
  };

  // Handle Cell click for Credit Limit
  const handleCreditLimitCellClick = (params) => {
    if (params.colDef.headerName !== "Metric") {
      const rangeId = params.colDef.rangeId;
      let filteredSummary = [];

      const rangeArray = getRangeForRiskyCustomer(selectedOptionCL.id);
      const rangeTypeObj = rangeArray.find((item) => item.id === rangeId);

      localStorage.setItem(
        "segmentName",
        JSON.stringify(selectedOptionCL.label)
      );
      localStorage.setItem("rangeTypeName", JSON.stringify(rangeTypeObj.label));
      localStorage.setItem("metricName", JSON.stringify("Credit Limit"));

      if (
        selectedOptionCL.id === segmentationTypesEnum.RECOMMENDED_NET.id ||
        selectedOptionCL.id === segmentationTypesEnum.CURRENT_NET.id
      ) {
        filteredSummary = getRiskyCustomerRNOrCN(
          rows,
          rangeId,
          selectedOptionCL.label
        );
      } else if (
        selectedOptionCL.id === segmentationTypesEnum.RISK_SEGMENT.id
      ) {
        filteredSummary = getRiskyCustomerRiskSegment(rows, rangeId);
      } else if (
        selectedOptionCL.id === segmentationTypesEnum.COUNTRY_SCORE.id
      ) {
        filteredSummary = getRiskyCustomerCountryScore(rows, rangeId);
      } else if (
        selectedOptionCL.id === segmentationTypesEnum.RECOMMENDED_LIMIT.id
      ) {
        filteredSummary = getRiskyCustomerRecommendedLimit(rows, rangeId);
      } else if (
        selectedOptionCL.id === segmentationTypesEnum.UNDERWRITING.id
      ) {
        filteredSummary = getRiskyCustomerUnderwriting(rows, rangeId);
      } else if (allowedScoreSegment.includes(selectedOptionCL?.label)) {
        filteredSummary = getRiskyCustomerScore(
          rows,
          rangeId,
          selectedOptionCL?.label
        );
      } else if (
        selectedOptionCL.id === segmentationTypesEnum.ARB_AGING30.id ||
        selectedOptionCL.id === segmentationTypesEnum.ARB_AGING60.id ||
        selectedOptionCL.id === segmentationTypesEnum.ARB_AGING90.id ||
        selectedOptionCL.id === segmentationTypesEnum.ARB_AGING90PLUS.id
      ) {
        filteredSummary = getRiskyCustomerAging(
          rows,
          rangeId,
          selectedOptionCL?.label
        );
      } else if (
        selectedOptionCL.id === segmentationTypesEnum.COMBINED_AGING.id
      ) {
        filteredSummary = getRiskyCustomerCombinedAging(rows, rangeId);
      } else if (
        selectedOptionCL.id === segmentationTypesEnum.UNDERWRITING_REASON.id
      ) {
        filteredSummary = getRiskyCustomerUnderwritingReason(rows, rangeId);
      } else if (
        selectedOptionCL.id === segmentationTypesEnum.CARB.id ||
        selectedOptionCL.id === segmentationTypesEnum.CURRENT_LIMIT.id
      ) {
        filteredSummary = getRiskyCustomerCARBOrCL(
          rows,
          rangeId,
          selectedOptionCL.label
        );
      }

      localStorage.setItem("filteredSummary", JSON.stringify(filteredSummary));

      if(adminAction) window.open(`/tenant-dashboard/${tenantId}/filteredSummary`, "_blank");
      else window.open("/filteredSummary", "_blank");
    }
  };
 
  useEffect(() => {
    const showOrHide = async () => {

      const feature = "dashboard";
      try {
        const result = await API.graphql(
          graphqlOperation(getDashboardPreferences, { tenantId, feature })
        );
        if (result != null) {
          const jsonString = result.data.getDashboardPreferences?.preferences;
          const jsonData = JSON.parse(jsonString);
          let idx = 0;
          jsonData.forEach((row) => {
            idx++;
            if (row.name === "Collection Table") {
              if (row.show === false) {
                setCollectionTablevisibility(false);
              } else {
                setcollectionTableOrder(idx);
              }
            } else if (row.name === "Heat Map: Collection Table: Total Sum") {
              if (row.show === false) {
                setHeatMapCollectionTableTotalSumvisibility(false);
              } else {
                setHeatMapCollectionTableTotalSumOrder(idx);
              }
            } else if (row.name === "Heat Map: Collection Table: Customers") {
              if (row.show === false) {
                setHeatMapCollectionTableCustomersVisibilty(false);
              } else {
                setHeatMapCollectionTableCustomersOrder(idx);
              }
            } else if (row.name === "10 Most Risky Customers") {
              if (row.show === false) {
                setMostRiskyCustomersVisibilty(false);
              } else {
                setMostRiskyCustomersOrder(idx);
              }
            } else if (row.name === "Credit Limit") {
              if (row.show === false) {
                setCreditLimitVisibility(false);
              } else {
                setCreditLimitOrder(idx);
              }
            } else if (row.name === "Chart - Credit Limit") {
              if (row.show === false) {
                setCreditLimitChartVisibility(false);
              } else {
                setCreditLimitChartOrder(idx);
              }
            } else if (row.name === "Account Receivable") {
              if (row.show === false) {
                setAccountReceivableVisibility(false);
              } else {
                setAccountReceivableOrder(idx);
              }
            } else if (row.name === "Chart - Amount Receivable") {
              if (row.show === false) {
                setAmountReceivableChartVisibility(false);
              } else {
                setAmountReceivableChartOrder(idx);
              }
            } else if (
              row.name === "Heat Map : Amount Receivable : Total Sum"
            ) {
              if (row.show === false) {
                setHeatMapAmountReceivableTotalSumVisibility(false);
              } else {
                setHeatMapAmountReceivableTotalSumOrder(idx);
              }
            } else if (
              row.name === "Heat Map : Amount Receivable : Customers"
            ) {
              if (row.show === false) {
                setHeatMapAmountReceivableCustomersVisibility(false);
              } else {
                setHeatMapAmountReceivableCustomersOrder(idx);
              }
            } else if (row.name === "Summary & Recommendations") {
              if (row.show === false) {
                setSummaryRecommendationsVisibility(false);
              } else {
                setSummaryRecommendationsOrder(idx);
              }
            } else if (row.name === "Collection Table - II") {
              if (row.show === false) {
                setCollectionV2Visibility(false);
              } else {
                setCollectionV2Order(idx);
              }
            } else if (row.name === "Collection At Risk") {
              if (row.show === false) {
                setCollectionAtRiskVisibility(false);
              } else {
                setCollectionAtRiskOrder(idx);
              }
            }
          });
        } else {
          console.log("dashboard table does not exists");
        }
      } catch (error) {
        console.error(error);
      }
    };
    if(tenantId){
    showOrHide();}
  }, [tenantId]);
  
  const {isFeatureFlag, setFeatureFlag} = useFeatureFlag(); 
  const {show_file_upload_button} = isFeatureFlag?.["Buttons"] || {};

  if(!localStorage.getItem("subId")) return <CallbackPage origin="loader" login={true} />;

  return (
    <>
      <div
        style={{
          width: "100%",
          marginTop: 30,
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Button
          variant="contained"
          style={{ marginLeft: 30 }}
          onClick={handleButtonClick}
        >
          Upload
        </Button>
        {show_file_upload_button && <FileUploadModal 
          open={fileModalOpen}
          close={() => setFileModalOpen(false)}
        />}
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "100px" }}>
        {CollectionTablevisibility && (
          <div
            style={{
              width: "100%",
              order: collectionTableOrder,
            }}
          >
            {/* <Typography variant="h5" mb={5}>
              Collection Table
            </Typography> */}
            {/* <DataGridPremium
              sx={{ height: "85vh" }}
              treeData
              getTreeDataPath={(row) => row.path.split("/")}
              onCellClick={handleCTCellClick}
              rows={collectionTable.rows}
              columns={collectionTable.columns}
              defaultGroupingExpansionDepth={-1}
              columnVisibilityModel={collectionTableCVM || {}}
              onColumnVisibilityModelChange={handleCVMChangeForCollectionTable}
              groupingColDef={{
                headerName: "Current Net",
                width: 250,
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 50 },
                },
              }}
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              pageSizeOptions={[5, 10, 50]}
            /> */}
          </div>
        )}

        {/* {HeatMapCollectionTableTotalSumvisibility && (
          <div
            style={{
              width: "100%",
              order: HeatMapCollectionTableTotalSumOrder,
            }}
          >
            <AmountReceivableHeatMap
              summary={rows}
              adminAction={adminAction}
              tableType={"total"}
              segmentInfo={{
                id: segmentationTypesEnum.CURRENT_NET.id,
                label: segmentationTypesEnum.CURRENT_NET.label,
              }}
            />
          </div>
        )}

        {HeatMapCollectionTableCustomersVisibilty && (
          <div
            style={{
              width: "100%",
              order: HeatMapCollectionTableCustomersOrder,
            }}
          >
            <AmountReceivableHeatMap
              summary={rows}
              tableType={"customer"}
              adminAction={adminAction}

              segmentInfo={{
                id: segmentationTypesEnum.CURRENT_NET.id,
                label: segmentationTypesEnum.CURRENT_NET.label,
              }}
            />
          </div>
        )}

        {MostRiskyCustomersVisibilty && (
          <div
            style={{
              width: "100%",
              order: MostRiskyCustomersOrder,
            }}
          >
            <Typography variant="h5" mb={2}>
              10 Most Risky Customers
            </Typography>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Autocomplete
                size="small"
                sx={{ width: 250, my: 5 }}
                value={selectedOptionRC}
                onChange={handleDropdownChangeForRC}
                options={segmentationTypes}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Segmentation Type"
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                size="small"
                sx={{ width: 250, my: 5 }}
                value={selectedRangeRC}
                onChange={handleRangeChangeRC}
                options={range}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Segmentation Range"
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <DataGridPremium
              sx={{ height: "85vh" }}
              getRowId={getRowId}
              rows={gridRowsRC}
              autosizeOnMount={true}
              onCellClick={handleCellClick}
              columnVisibilityModel={
                riskyCustomerCVM[selectedOptionRC.label] || {}
              }
              onColumnVisibilityModelChange={handleCVMChangeForRiskyCustomer}
              columns={colDefRiskyCustomer}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 50 },
                },
              }}
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              pageSizeOptions={[5, 10, 50]}
            />
          </div>
        )}

        {CreditLimitVisibility && (
          <div style={{ width: "100%", order: CreditLimitOrder }}>
            <Typography variant="h5" mb={5}>
              Credit Limit
            </Typography>
            <Autocomplete
              size="small"
              sx={{ width: 250, my: 5 }}
              value={selectedOptionCL}
              onChange={handleDropdownChange}
              options={segmentationTypes}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Segmentation Type"
                  variant="outlined"
                />
              )}
            />
            <DataGridPremium
              sx={{ height: "75vh" }}
              rows={gridRowsCL}
              columns={gridColumnsCL}
              onCellClick={handleCreditLimitCellClick}
              // autosizeOnMount={true}
              columnVisibilityModel={
                creditLimitCVM[selectedOptionCL.label] || {}
              }
              onColumnVisibilityModelChange={handleCVMChangeForCreditLimit}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 50 },
                },
              }}
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              pageSizeOptions={[5, 10, 50]}
            />
          </div>
        )}

        {CreditLimitChartVisibility && (
          <div
            style={{
              width: "100%",
              order: CreditLimitChartOrder,
            }}
          >
            <CreditLimitBarChart summary={rows} />
          </div>
        )} */}

        {/* {AccountReceivableVisibility && (
          <div
            style={{
              width: "100%",
              order: AccountReceivableOrder,
            }}
          >
            <Typography variant="h5" mb={5}>
              Account Receivable
            </Typography>
            <Autocomplete
              size="small"
              sx={{ width: 250, my: 5 }}
              value={selectedOptionAR}
              onChange={handleDropdownChangeForAR}
              options={segmentationTypes}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Segmentation Type"
                  variant="outlined"
                />
              )}
            />
            <DataGridPremium
              sx={{ height: "85vh" }}
              rows={gridRowsAR}
              columns={gridColumnsAR}
              onCellClick={handleARCellClick}
              columnVisibilityModel={
                amountReceivableCVM[selectedOptionAR.label] || {}
              }
              onColumnVisibilityModelChange={
                handleColumnVisibilityModelChangeForAR
              }
              // autosizeOnMount={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 50 },
                },
              }}
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={gridColGroupAR}
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              pageSizeOptions={[5, 10, 50]}
            />
          </div>
        )}

        {AmountReceivableChartVisibility && (
          <div
            style={{
              width: "100%",
              height: "85vh",
              order: AmountReceivableChartOrder,
            }}
          >
            <Typography variant="h5" mb={2}>
              Chart - Amount Receivable
            </Typography>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Autocomplete
                size="small"
                sx={{ width: 250, my: 5 }}
                value={selectedOptionPieChartAR}
                onChange={handleDropdownChangeForPieChartAR}
                options={segmentationTypes}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Segmentation Type"
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                size="small"
                sx={{ width: 250, my: 5 }}
                value={selectedRangePieChartAR}
                onChange={handleRangeChangePCAR}
                options={rangePieChartAR}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Segmentation Range"
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  mb={2}
                  mr={5}
                  textAlign={"center"}
                >
                  Number of Customers
                </Typography>
                <PieChart
                  sx={{ margin: 5 }}
                  legend={{ hidden: true }}
                  height={300}
                  series={[
                    {
                      data: pieChartDataCustomer,
                    },
                  ]}
                />
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  mb={2}
                  mr={8}
                  textAlign={"center"}
                >
                  Total AR Sum
                </Typography>
                <PieChart
                  sx={{ margin: 5 }}
                  height={300}
                  series={[
                    {
                      data: pieChartDataTotalSum,
                    },
                  ]}
                />
              </Box>
            </Box>
          </div>
        )}

        {HeatMapAmountReceivableTotalSumVisibility && (
          <div
            style={{
              width: "100%",
              order: HeatMapAmountReceivableTotalSumOrder,
            }}
          >
            <AmountReceivableHeatMap  summary={rows}
              adminAction={adminAction}
             tableType={"total"} />
          </div>
        )}

        {HeatMapAmountReceivableCustomersVisibility && (
          <div
            style={{
              width: "100%",
              order: HeatMapAmountReceivableCustomersOrder,
            }}
          >
            <AmountReceivableHeatMap summary={rows} 
              adminAction={adminAction}
            tableType={"customer"} />
          </div>
        )}

        {SummaryRecommendationsVisibility && (
          <div
            style={{
              width: "100%",
              order: SummaryRecommendationsOrder,
            }}
          >
            <Typography variant="h5" mb={5}>
              Summary & Recommendations
            </Typography>
            <DataGridPremium
              sx={{
                height: "85vh",
              }}
              onCellClick={handleCellClick}
              // getRowId={getRowId}
              rows={rows}
              columns={columnDefinition}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              columnVisibilityModel={columnVisibilityModel || {}}
              onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              // autosizeOnMount={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 50 },
                },
              }}
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              pageSizeOptions={[5, 10, 50]}
            />
          </div>
        )}
        {collectionV2Visibility && (
          <div
            style={{
              width: "100%",
              order: collectionV2Order,
            }}
          >
          <NewCollectionTable data={rows} />
          </div>
        )}
        {collectionAtRiskVisibility && (
          <div
            style={{
              width: "100%",
              order: collectionAtRiskOrder,
            }}
          >
          <CollectionAtRiskTable rowData={rows}/>
          </div>
        )} */}
        <RiskDistributionDiagram rowData={rows} />
        <RecommendationTable rows={rows} riskyRows={gridRowsRC} showAtRisk={showAtRisk} setShowAtRisk={setShowAtRisk} />
        {/* <div>
          <RequestManualEvaluationDialog
            title={title}
            comment={comment}
            email={email}
            fullName={fullName}
            phoneNumber={phoneNumber}
            isDialogOpen={isDialogOpen}
            selectedOption={selectedOption}
            specificCreditLimit={specificCreditLimit}
            handleTitleChange={handleTitleChange}
            handleCloseDialog={handleCloseDialog}
            handleCommentsChange={handleCommentsChange}
            handleEmailChange={handleEmailChange}
            handleFullNameChange={handleFullNameChange}
            handleOptionChange={handleOptionChange}
            handlePhoneNumberChange={handlePhoneNumberChange}
            handleSpecificCreditLimitValue={handleSpecificCreditLimitValue}
            handleSubmit={handleSubmit}
          />
        </div> */}
      </div>
    </>
  );
}
