// Table - Credit Line

import { underwritingReasons } from "./SegmentationTypes";

// Segment Type - Alternative Score
export const colDefCreditLimitForScore = [
  {
    field: "metric",
    headerName: "Metric",
    width: 300,
  },
  {
    field: "total",
    headerName: "Total",
    width: 180,
    rangeId: 0,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "totalScoreLTE1",
    headerName: "<=1",
    width: 180,
    rangeId: 1,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE1}</div>
      );
    },
  },
  {
    field: "totalScoreLTE2",
    headerName: "<=2",
    width: 180,
    rangeId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE2}</div>
      );
    },
  },
  {
    field: "totalScoreLTE3",
    headerName: "<=3",
    width: 180,
    rangeId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE3}</div>
      );
    },
  },
  {
    field: "totalScoreLTE4",
    headerName: "<=4",
    width: 180,
    rangeId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE4}</div>
      );
    },
  },
  {
    field: "totalScoreLTE5",
    headerName: "<=5",
    width: 180,
    rangeId: 5,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE5}</div>
      );
    },
  },
  {
    field: "totalScoreLTE6",
    headerName: "<=6",
    width: 180,
    rangeId: 6,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE6}</div>
      );
    },
  },
  {
    field: "totalScoreLTE7",
    headerName: "<=7",
    width: 180,
    rangeId: 7,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE7}</div>
      );
    },
  },
  {
    field: "totalScoreGT7",
    headerName: ">7",
    width: 180,
    rangeId: 8,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreGT7}</div>
      );
    },
  },
  {
    field: "totalOther",
    headerName: "Other",
    width: 180,
    rangeId: 9,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalOther}</div>;
    },
  },
];

//   Segment Type - Recommended Net

export const colDefCreditLimitForRN = [
  {
    field: "metric",
    headerName: "Metric",
    width: 300,
  },
  {
    field: "total",
    headerName: "Total",
    width: 180,
    rangeId: 0,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "totalRN30",
    headerName: "30",
    width: 180,
    rangeId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRN30}</div>;
    },
  },
  {
    field: "totalRN45",
    headerName: "45",
    width: 180,
    rangeId: 2,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRN45}</div>;
    },
  },
  {
    field: "totalRN60",
    headerName: "60",
    width: 180,
    rangeId: 3,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRN60}</div>;
    },
  },
  {
    field: "totalRN90",
    headerName: "90",
    width: 180,
    rangeId: 4,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRN90}</div>;
    },
  },
  {
    field: "totalRN120",
    headerName: "120",
    width: 180,
    rangeId: 5,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRN120}</div>;
    },
  },
  {
    field: "totalRN180",
    headerName: "180",
    width: 180,
    rangeId: 6,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRN180}</div>;
    },
  },
  {
    field: "other",
    headerName: "Other",
    width: 180,
    rangeId: 7,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.other}</div>;
    },
  },
];

//   Segment Type - Underwriting

export const colDefCreditLimitForUnderwriting = [
  {
    field: "metric",
    headerName: "Metric",
    width: 300,
  },
  {
    field: "total",
    headerName: "Total",
    width: 180,
    rangeId: 0,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "manual",
    headerName: "Manual",
    width: 180,
    rangeId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.manual}</div>;
    },
  },
  {
    field: "rejected",
    headerName: "Rejected",
    width: 180,
    rangeId: 2,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.rejected}</div>;
    },
  },
  {
    field: "approved",
    headerName: "Approved",
    width: 180,
    rangeId: 3,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.approved}</div>;
    },
  },
  {
    field: "other",
    headerName: "Other",
    width: 180,
    rangeId: 4,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.other}</div>;
    },
  },
];

export const colDefCreditLimitForRecommendedLimit = [
  {
    field: "metric",
    headerName: "Metric",
    width: 300,
  },
  {
    field: "total",
    headerName: "Total",
    width: 180,
    rangeId: 0,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "totalRL0",
    headerName: "<50K",
    width: 180,
    rangeId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL0}</div>;
    },
  },
  {
    field: "totalRL50k",
    headerName: "<100K",
    width: 180,
    rangeId: 2,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL50k}</div>;
    },
  },
  {
    field: "totalRL100k",
    headerName: "<150K",
    width: 180,
    rangeId: 3,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL100k}</div>;
    },
  },
  {
    field: "totalRL150k",
    headerName: "<200K",
    width: 180,
    rangeId: 4,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL150k}</div>;
    },
  },
  {
    field: "totalRL200k",
    headerName: "<250K",
    width: 180,
    rangeId: 5,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL200k}</div>;
    },
  },
  {
    field: "totalRL250k",
    headerName: "<300K",
    width: 180,
    rangeId: 6,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL250k}</div>;
    },
  },
  {
    field: "totalRL300k",
    headerName: ">=300K",
    width: 180,
    rangeId: 7,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL300k}</div>;
    },
  },
];

// Segment Type - Risk Segment

export const colDefCreditLimitForRS = [
  {
    field: "metric",
    headerName: "Metric",
    width: 300,
  },
  {
    field: "total",
    headerName: "Total",
    width: 180,
    rangeId: 0,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "totalRS2Point5",
    headerName: "<=2.5",
    width: 180,
    rangeId: 1,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalRS2Point5}</div>
      );
    },
  },
  {
    field: "totalRS5",
    headerName: "<=5",
    width: 180,
    rangeId: 2,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRS5}</div>;
    },
  },
  {
    field: "totalRS7Point5",
    headerName: "<=7.5",
    width: 180,
    rangeId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalRS7Point5}</div>
      );
    },
  },
  {
    field: "totalRS7Point5Greater",
    headerName: ">7.5",
    width: 180,
    rangeId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params.row.totalRS7Point5Greater}
        </div>
      );
    },
  },
];

// Segment Type - Current AR Balance

export const colDefCreditLimitForCARBOrCL = [
  {
    field: "metric",
    headerName: "Metric",
    width: 300,
  },
  {
    field: "total",
    headerName: "Total",
    width: 180,
    rangeId: 0,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "totalCARB20K",
    headerName: "<20K",
    width: 180,
    rangeId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalCARB20K}</div>;
    },
  },
  {
    field: "totalCARB50K",
    headerName: "<50K",
    width: 180,
    rangeId: 2,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalCARB50K}</div>;
    },
  },
  {
    field: "totalCARB150K",
    headerName: "<150K",
    width: 180,
    rangeId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalCARB150K}</div>
      );
    },
  },
  {
    field: "totalCARB250K",
    headerName: "<250K",
    width: 180,
    rangeId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalCARB250K}</div>
      );
    },
  },
  {
    field: "totalCARB500K",
    headerName: "<500K",
    width: 180,
    rangeId: 5,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalCARB500K}</div>
      );
    },
  },
  {
    field: "totalCARB500KGreater",
    headerName: ">500K",
    width: 180,
    rangeId: 6,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params.row.totalCARB500KGreater}
        </div>
      );
    },
  },
];

export const colDefCreditLimitForCombinedAging = [
  {
    field: "metric",
    headerName: "Metric",
    width: 300,
  },
  {
    field: "total",
    headerName: "Total",
    width: 180,
    rangeId: 0,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "lessThanEqualZero",
    headerName: "<=0",
    width: 180,
    rangeId: 1,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.lessThanEqualZero}</div>
      );
    },
  },
  {
    field: "greaterThanZero",
    headerName: ">0",
    width: 180,
    rangeId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.greaterThanZero}</div>
      );
    },
  },
];

export const colDefCreditLimitForCountryScore = [
  {
    field: "metric",
    headerName: "Metric",
    width: 300,
  },
  {
    field: "total",
    headerName: "Total",
    width: 180,
    rangeId: 0,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "total1",
    headerName: "1",
    width: 180,
    rangeId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total1}</div>;
    },
  },
  {
    field: "total2",
    headerName: "2",
    width: 180,
    rangeId: 2,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total2}</div>;
    },
  },
  {
    field: "total3",
    headerName: "3",
    width: 180,
    rangeId: 3,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total3}</div>;
    },
  },
  {
    field: "total4",
    headerName: "4",
    width: 180,
    rangeId: 4,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total4}</div>;
    },
  },
  {
    field: "total5",
    headerName: "5",
    width: 180,
    rangeId: 5,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total5}</div>;
    },
  },
  {
    field: "total6",
    headerName: "6",
    width: 180,
    rangeId: 6,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total6}</div>;
    },
  },
  {
    field: "total7",
    headerName: "7",
    width: 180,
    rangeId: 7,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total7}</div>;
    },
  },
  {
    field: "total8",
    headerName: ">7",
    width: 180,
    rangeId: 8,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total8}</div>;
    },
  },
];

export const colDefCreditLimitForUnderwritingReason = [
  {
    field: "metric",
    headerName: "Metric",
    width: 300,
  },
  {
    field: "total",
    headerName: "Total",
    width: 180,
    rangeId: 0,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "total1",
    headerName: underwritingReasons.val1,
    width: 180,
    rangeId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total1}</div>;
    },
  },
  {
    field: "total2",
    headerName: underwritingReasons.val2,
    width: 180,
    rangeId: 2,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total2}</div>;
    },
  },
  {
    field: "total3",
    headerName: underwritingReasons.val3,
    width: 180,
    rangeId: 3,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total3}</div>;
    },
  },
  {
    field: "total4",
    headerName: underwritingReasons.val4,
    width: 180,
    rangeId: 4,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total4}</div>;
    },
  },
  {
    field: "total5",
    headerName: underwritingReasons.val5,
    width: 180,
    rangeId: 5,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total5}</div>;
    },
  },
  {
    field: "total6",
    headerName: underwritingReasons.val6,
    width: 180,
    rangeId: 6,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total6}</div>;
    },
  },
  {
    field: "total7",
    headerName: underwritingReasons.val7,
    width: 180,
    rangeId: 7,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total7}</div>;
    },
  },
  {
    field: "total8",
    headerName: "Other",
    width: 180,
    rangeId: 8,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total8}</div>;
    },
  },
];

// Table - Amount Receivable
export const colDefAmountReceivableRS = [
  {
    field: "metric",
    headerName: "Metric",
    width: 200,
  },
  {
    field: "customer",
    headerName: "Number of customers",
    width: 150,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer}</div>;
    },
  },
  {
    field: "total",
    headerName: "Total Sum",
    width: 90,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "customerRS2Point5",
    headerName: "Number of customers",
    width: 150,
    groupId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerRS2Point5}
        </div>
      );
    },
  },
  {
    field: "totalRS2Point5",
    headerName: "Total Sum",
    width: 90,
    groupId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalRS2Point5}</div>
      );
    },
  },
  {
    field: "customerRS5",
    headerName: "Number of customers",
    width: 150,
    groupId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRS5}</div>
      );
    },
  },
  {
    field: "totalRS5",
    headerName: "Total Sum",
    width: 90,
    groupId: 3,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRS5}</div>;
    },
  },
  {
    field: "customerRS7Point5",
    headerName: "Number of customers",
    width: 150,
    groupId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerRS7Point5}
        </div>
      );
    },
  },
  {
    field: "totalRS7Point5",
    headerName: "Total Sum",
    width: 90,
    groupId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalRS7Point5}</div>
      );
    },
  },
  {
    field: "customerRS7Point5Greater",
    headerName: "Number of customers",
    width: 150,
    groupId: 5,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerRS7Point5Greater}
        </div>
      );
    },
  },
  {
    field: "totalRS7Point5Greater",
    headerName: "Total Sum",
    width: 90,
    groupId: 5,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params.row.totalRS7Point5Greater}
        </div>
      );
    },
  },
];

export const amountReceivableColumnGroupingForRS = [
  {
    groupId: 1,
    headerName: "Total",
    description: "",
    children: [{ field: "customer" }, { field: "total" }],
  },
  {
    groupId: 2,
    headerName: "<=2.5",
    description: "",
    children: [{ field: "customerRS2Point5" }, { field: "totalRS2Point5" }],
  },
  {
    groupId: 3,
    headerName: "<=5",
    description: "",
    children: [{ field: "customerRS5" }, { field: "totalRS5" }],
  },
  {
    groupId: 4,
    headerName: "<=7.5",
    description: "",
    children: [{ field: "customerRS7Point5" }, { field: "totalRS7Point5" }],
  },
  {
    groupId: 5,
    headerName: ">7.5",
    description: "",
    children: [
      { field: "customerRS7Point5Greater" },
      { field: "totalRS7Point5Greater" },
    ],
  },
];

// Column Defination for Combined Aging & Aging Segments
export const colDefAmountReceivableCA = [
  {
    field: "metric",
    headerName: "Metric",
    width: 200,
  },
  {
    field: "customer",
    headerName: "Number of customers",
    width: 150,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer}</div>;
    },
  },
  {
    field: "total",
    headerName: "Total Sum",
    width: 90,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "customerCALessThanEqualZero",
    headerName: "Number of customers",
    width: 150,
    groupId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerCALessThanEqualZero}
        </div>
      );
    },
  },
  {
    field: "totalCALessThanEqualZero",
    headerName: "Total Sum",
    width: 90,
    groupId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params.row.totalCALessThanEqualZero}
        </div>
      );
    },
  },
  {
    field: "customerCAGreaterThanZero",
    headerName: "Number of customers",
    width: 150,
    groupId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerCAGreaterThanZero}
        </div>
      );
    },
  },
  {
    field: "totalCAGreaterThanZero",
    headerName: "Total Sum",
    width: 90,
    groupId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params.row.totalCAGreaterThanZero}
        </div>
      );
    },
  },
];

export const amountReceivableColumnGroupingForCA = [
  {
    groupId: 1,
    headerName: "Total",
    description: "",
    children: [{ field: "customer" }, { field: "total" }],
  },
  {
    groupId: 2,
    headerName: "<=0",
    description: "",
    children: [
      { field: "customerCALessThanEqualZero" },
      { field: "totalCALessThanEqualZero" },
    ],
  },
  {
    groupId: 3,
    headerName: ">0",
    description: "",
    children: [
      { field: "customerCAGreaterThanZero" },
      { field: "totalCAGreaterThanZero" },
    ],
  },
];

export const colDefAmountReceivableCARBOrCL = [
  {
    field: "metric",
    headerName: "Metric",
    width: 200,
  },
  {
    field: "customer",
    headerName: "Number of customers",
    width: 150,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer}</div>;
    },
  },
  {
    field: "total",
    headerName: "Total Sum",
    width: 90,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "customerCARB20K",
    headerName: "Number of customers",
    width: 150,
    groupId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerCARB20K}</div>
      );
    },
  },
  {
    field: "totalCARB20K",
    headerName: "Total Sum",
    width: 90,
    groupId: 2,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalCARB20K}</div>;
    },
  },
  {
    field: "customerCARB50K",
    headerName: "Number of customers",
    width: 150,
    groupId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerCARB50K}</div>
      );
    },
  },
  {
    field: "totalCARB50K",
    headerName: "Total Sum",
    width: 90,
    groupId: 3,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalCARB50K}</div>;
    },
  },
  {
    field: "customerCARB150K",
    headerName: "Number of customers",
    width: 150,
    groupId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerCARB150K}</div>
      );
    },
  },
  {
    field: "totalCARB150K",
    headerName: "Total Sum",
    width: 90,
    groupId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalCARB150K}</div>
      );
    },
  },
  {
    field: "customerCARB250K",
    headerName: "Number of customers",
    width: 150,
    groupId: 5,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerCARB250K}</div>
      );
    },
  },
  {
    field: "totalCARB250K",
    headerName: "Total Sum",
    width: 90,
    groupId: 5,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalCARB250K}</div>
      );
    },
  },
  {
    field: "customerCARB500K",
    headerName: "Number of customers",
    width: 150,
    groupId: 6,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerCARB500K}</div>
      );
    },
  },
  {
    field: "totalCARB500K",
    headerName: "Total Sum",
    width: 90,
    groupId: 6,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalCARB500K}</div>
      );
    },
  },
  {
    field: "customerCARB500KGreater",
    headerName: "Number of customers",
    width: 150,
    groupId: 7,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerCARB500KGreater}
        </div>
      );
    },
  },
  {
    field: "totalCARB500KGreater",
    headerName: "Total Sum",
    width: 90,
    groupId: 7,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params.row.totalCARB500KGreater}
        </div>
      );
    },
  },
];

export const amountReceivableColumnGroupingForCARBOrCL = [
  {
    groupId: 1,
    headerName: "Total",
    description: "",
    children: [{ field: "customer" }, { field: "total" }],
  },
  {
    groupId: 2,
    headerName: "<20K",
    description: "",
    children: [{ field: "customerCARB20K" }, { field: "totalCARB20K" }],
  },
  {
    groupId: 3,
    headerName: "<50K",
    description: "",
    children: [{ field: "customerCARB50K" }, { field: "totalCARB50K" }],
  },
  {
    groupId: 4,
    headerName: "<150K",
    description: "",
    children: [{ field: "customerCARB150K" }, { field: "totalCARB150K" }],
  },
  {
    groupId: 5,
    headerName: "<250K",
    description: "",
    children: [{ field: "customerCARB250K" }, { field: "totalCARB250K" }],
  },
  {
    groupId: 6,
    headerName: "<500K",
    description: "",
    children: [{ field: "customerCARB500K" }, { field: "totalCARB500K" }],
  },
  {
    groupId: 7,
    headerName: ">500K",
    description: "",
    children: [
      { field: "customerCARB500KGreater" },
      { field: "totalCARB500KGreater" },
    ],
  },
];

export const colDefAmountReceivableRL = [
  {
    field: "metric",
    headerName: "Metric",
    width: 200,
  },
  {
    field: "customer",
    headerName: "Number of customers",
    width: 150,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer}</div>;
    },
  },
  {
    field: "total",
    headerName: "Total Sum",
    width: 90,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "customerRL0",
    headerName: "Number of customers",
    width: 150,
    groupId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRL0}</div>
      );
    },
  },
  {
    field: "totalRL0",
    headerName: "Total Sum",
    width: 90,
    groupId: 2,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL0}</div>;
    },
  },
  {
    field: "customerRL50k",
    headerName: "Number of customers",
    width: 150,
    groupId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRL50k}</div>
      );
    },
  },
  {
    field: "totalRL50k",
    headerName: "Total Sum",
    width: 90,
    groupId: 3,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL50k}</div>;
    },
  },
  {
    field: "customerRL100k",
    headerName: "Number of customers",
    width: 150,
    groupId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRL100k}</div>
      );
    },
  },
  {
    field: "totalRL100k",
    headerName: "Total Sum",
    width: 90,
    groupId: 4,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL100k}</div>;
    },
  },
  {
    field: "customerRL150k",
    headerName: "Number of customers",
    width: 150,
    groupId: 5,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRL150k}</div>
      );
    },
  },
  {
    field: "totalRL150k",
    headerName: "Total Sum",
    width: 90,
    groupId: 5,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL150k}</div>;
    },
  },
  {
    field: "customerRL200k",
    headerName: "Number of customers",
    width: 150,
    groupId: 6,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRL200k}</div>
      );
    },
  },
  {
    field: "totalRL200k",
    headerName: "Total Sum",
    width: 90,
    groupId: 6,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL200k}</div>;
    },
  },
  {
    field: "customerRL250k",
    headerName: "Number of customers",
    width: 150,
    groupId: 7,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRL250k}</div>
      );
    },
  },
  {
    field: "totalRL250k",
    headerName: "Total Sum",
    width: 90,
    groupId: 7,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL250k}</div>;
    },
  },
  {
    field: "customerRL300k",
    headerName: "Number of customers",
    width: 150,
    groupId: 8,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRL300k}</div>
      );
    },
  },
  {
    field: "totalRL300k",
    headerName: "Total Sum",
    width: 90,
    groupId: 8,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRL300k}</div>;
    },
  },
];

export const amountReceivableColumnGroupingForRL = [
  {
    groupId: 1,
    headerName: "Total",
    description: "",
    children: [{ field: "customer" }, { field: "total" }],
  },
  {
    groupId: 2,
    headerName: "<50K",
    description: "",
    children: [{ field: "customerRL0" }, { field: "totalRL0" }],
  },
  {
    groupId: 3,
    headerName: "<100K",
    description: "",
    children: [{ field: "customerRL50k" }, { field: "totalRL50k" }],
  },
  {
    groupId: 4,
    headerName: "<150K",
    description: "",
    children: [{ field: "customerRL100k" }, { field: "totalRL100k" }],
  },
  {
    groupId: 5,
    headerName: "<200K",
    description: "",
    children: [{ field: "customerRL150k" }, { field: "totalRL150k" }],
  },
  {
    groupId: 6,
    headerName: "<250K",
    description: "",
    children: [{ field: "customerRL200k" }, { field: "totalRL200k" }],
  },
  {
    groupId: 7,
    headerName: "<300K",
    description: "",
    children: [{ field: "customerRL250k" }, { field: "totalRL250k" }],
  },
  {
    groupId: 8,
    headerName: ">=300K",
    description: "",
    children: [{ field: "customerRL300k" }, { field: "totalRL300k" }],
  },
];

export const colDefAmountReceivableUnderwriting = [
  {
    field: "metric",
    headerName: "Metric",
    width: 200,
  },
  {
    field: "customer",
    headerName: "Number of customers",
    width: 150,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer}</div>;
    },
  },
  {
    field: "total",
    headerName: "Total Sum",
    width: 90,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "customerUnderwritingManual",
    headerName: "Number of customers",
    width: 150,
    groupId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerUnderwritingManual}
        </div>
      );
    },
  },
  {
    field: "totalUnderwritingManual",
    headerName: "Total Sum",
    width: 90,
    groupId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params.row.totalUnderwritingManual}
        </div>
      );
    },
  },
  {
    field: "customerUnderwritingRejected",
    headerName: "Number of customers",
    width: 150,
    groupId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerUnderwritingRejected}
        </div>
      );
    },
  },
  {
    field: "totalUnderwritingRejected",
    headerName: "Total Sum",
    width: 90,
    groupId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params.row.totalUnderwritingRejected}
        </div>
      );
    },
  },
  {
    field: "customerUnderwritingApproved",
    headerName: "Number of customers",
    width: 150,
    groupId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerUnderwritingApproved}
        </div>
      );
    },
  },
  {
    field: "totalUnderwritingApproved",
    headerName: "Total Sum",
    width: 90,
    groupId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params.row.totalUnderwritingApproved}
        </div>
      );
    },
  },
  {
    field: "customerUnderwritingOther",
    headerName: "Number of customers",
    width: 150,
    groupId: 5,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerUnderwritingOther}
        </div>
      );
    },
  },
  {
    field: "totalUnderwritingOther",
    headerName: "Total Sum",
    width: 90,
    groupId: 5,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params.row.totalUnderwritingOther}
        </div>
      );
    },
  },
];

export const amountReceivableColumnGroupingForUnderwriting = [
  {
    groupId: 1,
    headerName: "Total",
    description: "",
    children: [{ field: "customer" }, { field: "total" }],
  },
  {
    groupId: 2,
    headerName: "Manual",
    description: "",
    children: [
      { field: "customerUnderwritingManual" },
      { field: "totalUnderwritingManual" },
    ],
  },
  {
    groupId: 3,
    headerName: "Rejected",
    description: "",
    children: [
      { field: "customerUnderwritingRejected" },
      { field: "totalUnderwritingRejected" },
    ],
  },
  {
    groupId: 4,
    headerName: "Approved",
    description: "",
    children: [
      { field: "customerUnderwritingApproved" },
      { field: "totalUnderwritingApproved" },
    ],
  },
  {
    groupId: 5,
    headerName: "Other",
    description: "",
    children: [
      { field: "customerUnderwritingOther" },
      { field: "totalUnderwritingOther" },
    ],
  },
];

export const colDefAmountReceivableRN = [
  {
    field: "metric",
    headerName: "Metric",
    width: 200,
  },
  {
    field: "customer",
    headerName: "Number of customers",
    width: 150,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer}</div>;
    },
  },
  {
    field: "total",
    headerName: "Total Sum",
    width: 90,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "customerRN30",
    headerName: "Number of customers",
    width: 150,
    groupId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRN30}</div>
      );
    },
  },
  {
    field: "totalRN30",
    headerName: "Total Sum",
    width: 90,
    groupId: 2,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRN30}</div>;
    },
  },
  {
    field: "customerRN45",
    headerName: "Number of customers",
    width: 150,
    groupId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRN45}</div>
      );
    },
  },
  {
    field: "totalRN45",
    headerName: "Total Sum",
    width: 90,
    groupId: 3,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRN45}</div>;
    },
  },
  {
    field: "customerRN60",
    headerName: "Number of customers",
    width: 150,
    groupId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRN60}</div>
      );
    },
  },
  {
    field: "totalRN60",
    headerName: "Total Sum",
    width: 90,
    groupId: 4,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRN60}</div>;
    },
  },
  {
    field: "customerRN90",
    headerName: "Number of customers",
    width: 150,
    groupId: 5,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRN90}</div>
      );
    },
  },
  {
    field: "totalRN90",
    headerName: "Total Sum",
    width: 90,
    groupId: 5,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRN90}</div>;
    },
  },
  {
    field: "customerRN120",
    headerName: "Number of customers",
    width: 150,
    groupId: 6,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRN120}</div>
      );
    },
  },
  {
    field: "totalRN120",
    headerName: "Total Sum",
    width: 90,
    groupId: 6,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRN120}</div>;
    },
  },
  {
    field: "customerRN180",
    headerName: "Number of customers",
    width: 150,
    groupId: 7,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerRN180}</div>
      );
    },
  },
  {
    field: "totalRN180",
    headerName: "Total Sum",
    width: 90,
    groupId: 7,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalRN180}</div>;
    },
  },
  {
    field: "customerOther",
    headerName: "Number of customers",
    width: 150,
    groupId: 8,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerOther}</div>
      );
    },
  },
  {
    field: "totalOther",
    headerName: "Total Sum",
    width: 90,
    groupId: 8,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalOther}</div>;
    },
  },
];

export const amountReceivableColumnGroupingForRN = [
  {
    groupId: 1,
    headerName: "Total",
    description: "",
    children: [{ field: "customer" }, { field: "total" }],
  },
  {
    groupId: 2,
    headerName: "30",
    description: "",
    children: [{ field: "customerRN30" }, { field: "totalRN30" }],
  },
  {
    groupId: 3,
    headerName: "45",
    description: "",
    children: [{ field: "customerRN45" }, { field: "totalRN45" }],
  },
  {
    groupId: 4,
    headerName: "60",
    description: "",
    children: [{ field: "customerRN60" }, { field: "totalRN60" }],
  },
  {
    groupId: 5,
    headerName: "90",
    description: "",
    children: [{ field: "customerRN90" }, { field: "totalRN90" }],
  },
  {
    groupId: 6,
    headerName: "120",
    description: "",
    children: [{ field: "customerRN120" }, { field: "totalRN120" }],
  },
  {
    groupId: 7,
    headerName: "180",
    description: "",
    children: [{ field: "customerRN180" }, { field: "totalRN180" }],
  },
  {
    groupId: 8,
    headerName: "Other",
    description: "",
    children: [{ field: "customerOther" }, { field: "totalOther" }],
  },
];

export const colDefAmountReceivableScore = [
  {
    field: "metric",
    headerName: "Metric",
    width: 200,
  },
  {
    field: "customer",
    headerName: "Number of customers",
    width: 150,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer}</div>;
    },
  },
  {
    field: "total",
    headerName: "Total Sum",
    width: 90,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "customerScoreLTE1",
    headerName: "Number of customers",
    width: 150,
    groupId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerScoreLTE1}
        </div>
      );
    },
  },
  {
    field: "totalScoreLTE1",
    headerName: "Total Sum",
    width: 90,
    groupId: 2,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE1}</div>
      );
    },
  },
  {
    field: "customerScoreLTE2",
    headerName: "Number of customers",
    width: 150,
    groupId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerScoreLTE2}
        </div>
      );
    },
  },
  {
    field: "totalScoreLTE2",
    headerName: "Total Sum",
    width: 90,
    groupId: 3,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE2}</div>
      );
    },
  },
  {
    field: "customerScoreLTE3",
    headerName: "Number of customers",
    width: 150,
    groupId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerScoreLTE3}
        </div>
      );
    },
  },
  {
    field: "totalScoreLTE3",
    headerName: "Total Sum",
    width: 90,
    groupId: 4,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE3}</div>
      );
    },
  },
  {
    field: "customerScoreLTE4",
    headerName: "Number of customers",
    width: 150,
    groupId: 5,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerScoreLTE4}
        </div>
      );
    },
  },
  {
    field: "totalScoreLTE4",
    headerName: "Total Sum",
    width: 90,
    groupId: 5,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE4}</div>
      );
    },
  },
  {
    field: "customerScoreLTE5",
    headerName: "Number of customers",
    width: 150,
    groupId: 6,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerScoreLTE5}
        </div>
      );
    },
  },
  {
    field: "totalScoreLTE5",
    headerName: "Total Sum",
    width: 90,
    groupId: 6,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE5}</div>
      );
    },
  },
  {
    field: "customerScoreLTE6",
    headerName: "Number of customers",
    width: 150,
    groupId: 7,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerScoreLTE6}
        </div>
      );
    },
  },
  {
    field: "totalScoreLTE6",
    headerName: "Total Sum",
    width: 90,
    groupId: 7,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE6}</div>
      );
    },
  },
  {
    field: "customerScoreLTE7",
    headerName: "Number of customers",
    width: 150,
    groupId: 8,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>
          {params?.row?.customerScoreLTE7}
        </div>
      );
    },
  },
  {
    field: "totalScoreLTE7",
    headerName: "Total Sum",
    width: 90,
    groupId: 8,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreLTE7}</div>
      );
    },
  },
  {
    field: "customerScoreGT7",
    headerName: "Number of customers",
    width: 150,
    groupId: 9,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerScoreGT7}</div>
      );
    },
  },
  {
    field: "totalScoreGT7",
    headerName: "Total Sum",
    width: 90,
    groupId: 9,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.totalScoreGT7}</div>
      );
    },
  },
  {
    field: "customerOther",
    headerName: "Number of customers",
    width: 150,
    groupId: 10,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params?.row?.customerOther}</div>
      );
    },
  },
  {
    field: "totalOther",
    headerName: "Total Sum",
    width: 90,
    groupId: 10,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalOther}</div>;
    },
  },
];

export const amountReceivableColumnGroupingForScore = [
  {
    groupId: 1,
    headerName: "Total",
    description: "",
    children: [{ field: "customer" }, { field: "total" }],
  },
  {
    groupId: 2,
    headerName: "<=1",
    description: "",
    children: [{ field: "customerScoreLTE1" }, { field: "totalScoreLTE1" }],
  },
  {
    groupId: 3,
    headerName: "<=2",
    description: "",
    children: [{ field: "customerScoreLTE2" }, { field: "totalScoreLTE2" }],
  },
  {
    groupId: 4,
    headerName: "<=3",
    description: "",
    children: [{ field: "customerScoreLTE3" }, { field: "totalScoreLTE3" }],
  },
  {
    groupId: 5,
    headerName: "<=4",
    description: "",
    children: [{ field: "customerScoreLTE4" }, { field: "totalScoreLTE4" }],
  },
  {
    groupId: 6,
    headerName: "<=5",
    description: "",
    children: [{ field: "customerScoreLTE5" }, { field: "totalScoreLTE5" }],
  },
  {
    groupId: 7,
    headerName: "<=6",
    description: "",
    children: [{ field: "customerScoreLTE6" }, { field: "totalScoreLTE6" }],
  },
  {
    groupId: 8,
    headerName: "<=7",
    description: "",
    children: [{ field: "customerScoreLTE7" }, { field: "totalScoreLTE7" }],
  },
  {
    groupId: 9,
    headerName: ">7",
    description: "",
    children: [{ field: "customerScoreGT7" }, { field: "totalScoreGT7" }],
  },
  {
    groupId: 10,
    headerName: "Other",
    description: "",
    children: [{ field: "customerOther" }, { field: "totalOther" }],
  },
];

export const colDefAmountReceivableUWROrCS = [
  {
    field: "metric",
    headerName: "Metric",
    width: 200,
  },
  {
    field: "customer",
    headerName: "Number of customers",
    width: 150,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer}</div>;
    },
  },
  {
    field: "total",
    headerName: "Total Sum",
    width: 90,
    groupId: 1,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total}</div>;
    },
  },
  {
    field: "customer1",
    headerName: "Number of customers",
    width: 150,
    groupId: 2,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer1}</div>;
    },
  },
  {
    field: "total1",
    headerName: "Total Sum",
    width: 90,
    groupId: 2,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total1}</div>;
    },
  },
  {
    field: "customer2",
    headerName: "Number of customers",
    width: 150,
    groupId: 3,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer2}</div>;
    },
  },
  {
    field: "total2",
    headerName: "Total Sum",
    width: 90,
    groupId: 3,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total2}</div>;
    },
  },
  {
    field: "customer3",
    headerName: "Number of customers",
    width: 150,
    groupId: 4,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer3}</div>;
    },
  },
  {
    field: "total3",
    headerName: "Total Sum",
    width: 90,
    groupId: 4,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total3}</div>;
    },
  },
  {
    field: "customer4",
    headerName: "Number of customers",
    width: 150,
    groupId: 5,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer4}</div>;
    },
  },
  {
    field: "total4",
    headerName: "Total Sum",
    width: 90,
    groupId: 5,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total4}</div>;
    },
  },
  {
    field: "customer5",
    headerName: "Number of customers",
    width: 150,
    groupId: 6,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer5}</div>;
    },
  },
  {
    field: "total5",
    headerName: "Total Sum",
    width: 90,
    groupId: 6,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total5}</div>;
    },
  },
  {
    field: "customer6",
    headerName: "Number of customers",
    width: 150,
    groupId: 7,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer6}</div>;
    },
  },
  {
    field: "total6",
    headerName: "Total Sum",
    width: 90,
    groupId: 7,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total6}</div>;
    },
  },
  {
    field: "customer7",
    headerName: "Number of customers",
    width: 150,
    groupId: 8,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer7}</div>;
    },
  },
  {
    field: "total7",
    headerName: "Total Sum",
    width: 90,
    groupId: 8,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total7}</div>;
    },
  },
  {
    field: "customer8",
    headerName: "Number of customers",
    width: 150,
    groupId: 9,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params?.row?.customer8}</div>;
    },
  },
  {
    field: "total8",
    headerName: "Total Sum",
    width: 90,
    groupId: 9,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.total8}</div>;
    },
  },
];

export const amountReceivableColumnGroupingForUWR = [
  {
    groupId: 1,
    headerName: "Total",
    description: "",
    children: [{ field: "customer" }, { field: "total" }],
  },
  {
    groupId: 2,
    headerName: underwritingReasons.val1,
    description: "",
    children: [{ field: "customer1" }, { field: "total1" }],
  },
  {
    groupId: 3,
    headerName: underwritingReasons.val2,
    description: "",
    children: [{ field: "customer2" }, { field: "total2" }],
  },
  {
    groupId: 4,
    headerName: underwritingReasons.val3,
    description: "",
    children: [{ field: "customer3" }, { field: "total3" }],
  },
  {
    groupId: 5,
    headerName: underwritingReasons.val4,
    description: "",
    children: [{ field: "customer4" }, { field: "total4" }],
  },
  {
    groupId: 6,
    headerName: underwritingReasons.val5,
    description: "",
    children: [{ field: "customer5" }, { field: "total5" }],
  },
  {
    groupId: 7,
    headerName: underwritingReasons.val6,
    description: "",
    children: [{ field: "customer6" }, { field: "total6" }],
  },
  {
    groupId: 8,
    headerName: underwritingReasons.val7,
    description: "",
    children: [{ field: "customer7" }, { field: "total7" }],
  },
  {
    groupId: 9,
    headerName: "Other",
    description: "",
    children: [{ field: "customer8" }, { field: "total8" }],
  },
];

export const amountReceivableColumnGroupingForCS = [
  {
    groupId: 1,
    headerName: "Total",
    description: "",
    children: [{ field: "customer" }, { field: "total" }],
  },
  {
    groupId: 2,
    headerName: "1",
    description: "",
    children: [{ field: "customer1" }, { field: "total1" }],
  },
  {
    groupId: 3,
    headerName: "2",
    description: "",
    children: [{ field: "customer2" }, { field: "total2" }],
  },
  {
    groupId: 4,
    headerName: "3",
    description: "",
    children: [{ field: "customer3" }, { field: "total3" }],
  },
  {
    groupId: 5,
    headerName: "4",
    description: "",
    children: [{ field: "customer4" }, { field: "total4" }],
  },
  {
    groupId: 6,
    headerName: "5",
    description: "",
    children: [{ field: "customer5" }, { field: "total5" }],
  },
  {
    groupId: 7,
    headerName: "6",
    description: "",
    children: [{ field: "customer6" }, { field: "total6" }],
  },
  {
    groupId: 8,
    headerName: "7",
    description: "",
    children: [{ field: "customer7" }, { field: "total7" }],
  },
  {
    groupId: 9,
    headerName: ">7",
    description: "",
    children: [{ field: "customer8" }, { field: "total8" }],
  },
];

export const RiskOptions = [
  { label: "All", value: "all" },
  { label: "Less than 50001", value: "<50001" },
  { label: "50001 - 150001", value: "50001-150001" },
  { label: "150001 - 500001", value: "150001-500001" },
  { label: "Greater than 500001", value: ">500001" },
];

export const colDefRiskyCustomer = [
  {
    field: "Rank",
    headerName: "Rank",
    width: 90,
  },
  {
    field: "Company Name",
    headerName: "Name of Company",
    width: 90,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row["Company Name"]}</div>
      );
    },
  },
  {
    field: "Total AR Balance",
    headerName: "Total AR Balance",
    width: 90,
  },
  {
    field: "Current AR Balance",
    headerName: "Current AR Balance",
    width: 90,
  },
  {
    field: "Recommended Limit",
    headerName: "Recommended Limit",
    width: 90,
  },
  {
    field: "Requested Limit",
    headerName: "Requested Limit",
    width: 90,
  },
  {
    field: "AR Balance Aging 30",
    headerName: "AR Balance Aging 30",
    width: 90,
  },
  {
    field: "AR Balance Aging 60",
    headerName: "AR Balance Aging 60",
    width: 90,
  },
  {
    field: "AR Balance Aging 90",
    headerName: "AR Balance Aging 90",
    width: 90,
  },
  {
    field: "AR Balance Aging 90+",
    headerName: "AR Balance Aging 90+",
    width: 90,
  },
  {
    field: "Risk Segment",
    headerName: "Risk Segment",
    width: 90,
  },
  {
    field: "Failure Score",
    headerName: "Failure Score",
    width: 90,
  },
  {
    field: "Delinquency Score",
    headerName: "Delinquency Score",
    width: 90,
  },
  {
    field: "Alternative Score",
    headerName: "Alternative Score",
    width: 90,
  },
];
