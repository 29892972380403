import { API, graphqlOperation } from "aws-amplify";
import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction , useEffect} from "react";
import { getFeatureFlag } from "../graphql/queries";


const featureFlagContext = createContext(undefined);

export const FeatureFlagProvider= ({ children }) => {
  const [isFeatureFlag, setFeatureFlag] = useState({});

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      try {
        const result = await API.graphql(graphqlOperation(getFeatureFlag, { id:"d56a71d2-3c6e-4c7a-8a76-e027976452f2", updatedAt : "2024-04-09T15:07:18.970Z"}));
        console.log("FeatureFlag result:", JSON.stringify(result, null, 2));
        const featureFlags = JSON.parse(result.data.getFeatureFlag.features);
        setFeatureFlag(featureFlags);
      } catch (error) {
        console.error("Unable to read item. Error JSON:", JSON.stringify(error, null, 2));
      }
    };
  
    fetchFeatureFlags();
  }, []);
  

  return (
    <featureFlagContext.Provider value={{ isFeatureFlag, setFeatureFlag }}>
      {children}
    </featureFlagContext.Provider>
  );
};

export const useFeatureFlag = ()=> {
  const context = useContext(featureFlagContext);
  if (!context) {
    throw new Error("featureFlag must be used within a featureFlagProvider");
  }
  return context;
};


