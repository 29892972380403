import React, { useState } from "react";
import { Button, Modal, Box, TextField, Autocomplete } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { API, graphqlOperation } from "aws-amplify";
import { updateTenant } from "./graphql/mutations";

const frequencyOptions = [
  { id: 1, label: "Daily" },
  { id: 2, label: "Weekly" },
  { id: 3, label: "Monthly" },
];

const daysOfWeek = [
  { id: 1, label: "Monday" },
  { id: 2, label: "Tuesday" },
  { id: 3, label: "Wednesday" },
  { id: 4, label: "Thursday" },
  { id: 5, label: "Friday" },
  { id: 6, label: "Saturday" },
  { id: 7, label: "Sunday" },
];

const TenantSchedulerModal = ({ open, onClose, tenantId }) => {
  const [frequency, setFrequency] = useState(null);
  const [time, setTime] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);

  const handleClose = () => {
    setFrequency(null);
    setTime(null);
    setSelectedDay(null);
    onClose();
  };

  const handleSave = async () => {
    // Handle saving data
    const selectedHour = time ? time.format("hh") : null;
    const payload = {
      frequency: frequency.label,
      time: selectedHour,
    };

    if (frequency.label === "Weekly") {
      payload.day = selectedDay.label;
    }

    console.log(payload, "5050");
    const input = { id: tenantId, scheduleInfo: JSON.stringify(payload)}
    const res = await API.graphql(graphqlOperation(updateTenant, { input }));
    console.log(res, "7878")

    handleClose();
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            height: "100vh",
          }}
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              handleClose();
            }
          }}
        >
          <Box
            sx={{
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Autocomplete
              options={frequencyOptions}
              value={frequency}
              onChange={(event, newValue) => setFrequency(newValue)}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Frequency"
                  fullWidth
                  sx={{ mb: 2 }}
                />
              )}
            />

            {frequency?.label === "Weekly" && (
              <Box sx={{ mb: 2, width: "100%" }}>
                <Autocomplete
                  options={daysOfWeek}
                  value={selectedDay}
                  onChange={(event, newValue) => setSelectedDay(newValue)}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Day" />
                  )}
                />
              </Box>
            )}
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={time}
                  onChange={(newTime) => setTime(newTime)}
                  views={["hours"]}
                  ampm={false}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Box>
            <Button onClick={handleSave}>Save</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default TenantSchedulerModal;
