import { configureStore } from "@reduxjs/toolkit";
import userinfoReducer, { fetchAdminStatus, fetchEmail, fetchTenantId } from "./userInfoSlice";

const store = configureStore({
  reducer: {
    userInfo: userinfoReducer,
  },
});

store.dispatch(fetchAdminStatus());
store.dispatch(fetchTenantId());
store.dispatch(fetchEmail());

export { store };