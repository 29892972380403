import React, { useState } from 'react'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Typography, TextField } from '@mui/material'

const RequestManualEvaluationDialog = ({ isDialogOpen, handleCloseDialog, selectedOption, handleOptionChange, specificCreditLimit, handleSpecificCreditLimitValue, comment, handleCommentsChange, fullName, handleFullNameChange, title, handleTitleChange, email, handleEmailChange, phoneNumber, handlePhoneNumberChange, handleSubmit }) => {
    const[disabled , setDisabled] = useState(false);
    const handleOnClick = async() => {
        setDisabled(true);
        try {
            await handleSubmit();
        } finally {
            setDisabled(false);
        }
    }
    return (
        <React.Fragment>
            <Dialog open={isDialogOpen} PaperProps={{ style: { width: "600px" } }}>
                <DialogTitle>Request Manual Evaluation</DialogTitle>
                <DialogContent>
                    <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                        {/* Radio button for Specific Credit Limit */}
                        <FormControlLabel
                            value="Specific Credit Limit"
                            control={<Radio />}
                            label="Specific Credit Limit"
                        />
                        {/* Input field for Specific Credit Limit */}
                        {selectedOption === "Specific Credit Limit" && (
                            <TextField
                                label="Enter Specific Credit Limit"
                                type="number"
                                value={specificCreditLimit}
                                onChange={handleSpecificCreditLimitValue}
                                fullWidth
                                margin="normal"
                            />
                        )}
                        {/* Radio button for Max Credit Limit*/}
                        <FormControlLabel
                            value="Maximum Credit Limit"
                            control={<Radio />}
                            label="Maximum Credit Limit"
                        />
                    </RadioGroup>
                    {/* Text Field for aditional comment */}
                    <Box my={1}>
                        <Typography variant="body2" mb={1}>Please explain why you are asking for a manual evaluation. We will appreciate any additional details or context. Thanks</Typography>
                        <TextField
                            label="Comments"
                            type="text"
                            value={comment}
                            onChange={handleCommentsChange}
                            fullWidth
                        />
                    </Box>
                    {/* Form */}
                    <Box my={1}>
                        <Box my={1}>
                            <TextField label="Full Name" type="text" value={fullName} onChange={handleFullNameChange} fullWidth />
                        </Box>
                        <Box my={1}>
                            <TextField label="Title" type="text" value={title} onChange={handleTitleChange} fullWidth />
                        </Box>
                        <Box my={1}>
                            <TextField label="Email" type="email" value={email} onChange={handleEmailChange} fullWidth />
                        </Box>
                        <Box my={1}>
                            <TextField label="Phone Number" type="text" value={phoneNumber} onChange={handlePhoneNumberChange} fullWidth />
                        </Box>
                    </Box>
                    {/* Submit button */}
                    <Button variant="contained" color="primary" onClick={handleOnClick} disabled={disabled} >
                        Send Request
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default RequestManualEvaluationDialog