import { API, graphqlOperation } from "aws-amplify";
import { getTenant } from "../../graphql/queries";
import {  getTenantIdAuth0, isAdminAuth0 } from "../../authUtils";
import getTenantIdFromURL from "../getTenantIdFromURL";

export const getTenantInfo = async () => {
  try {
    const isAdmin = await isAdminAuth0();

    const selectedTenant = getTenantIdFromURL()

    const tenantId = isAdmin?selectedTenant: await getTenantIdAuth0();
    let response = await API.graphql(
      graphqlOperation(getTenant, {
        id: tenantId,
      })
    );
    const tenant = response.data?.getTenant || {};
    const name = tenant?.name || ''; 
    const isAREnabled = tenant?.isAREnabled || false; 
    const role = tenant?.role || ''; 
    const isPaid = tenant?.isPaid || false; 
    return { name, isAREnabled, role, isPaid};
  } catch (error) {
    console.error('Error fetching tenant info:', error);
    return { name: '', isAREnabled: false };
  }
};
