import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getEmailAuth0, getTenantIdAuth0, isAdminAuth0 } from "../authUtils";

const initialState = {
  subId: null,
  tenantId: null,
  isAdmin: false,
  email:null,
  status: 'idle', 
};

export const fetchAdminStatus = createAsyncThunk(
  'userInfo/fetchAdminStatus',
  async () => {
    const isAdmin = await isAdminAuth0();
    return isAdmin;
  }
);

export const fetchTenantId = createAsyncThunk(
  'userInfo/fetchTenantId',
  async () => {
    const tenantId = await getTenantIdAuth0();
    return tenantId;
  }
);
export const fetchEmail = createAsyncThunk(
  'userInfo/fetchEmail',
  async () => {
    const email = await getEmailAuth0();
    return email;
  }
);

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setSubId: (state, action) => {
      console.log("subid thunk");
      state.subId = action.payload;
    },
    setAdminStatus: (state, action) => { //
      console.log("is admin thunk");
      state.isAdmin = action.payload;
    },
    setTenantId: (state, action) => {
      state.tenantId = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdminStatus.fulfilled, (state, action) => {
        state.status = 'idle';
        state.isAdmin = action.payload;
      })
      .addCase(fetchTenantId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTenantId.fulfilled, (state, action) => {
        state.status = 'idle';
        state.tenantId = action.payload;
      })
      .addCase(fetchEmail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEmail.fulfilled, (state, action) => {
        state.status = 'idle';
        state.email = action.payload;
      })
  },
});

export const { setSubId, setAdminStatus, setTenantId,setEmail } = userInfoSlice.actions;
export default userInfoSlice.reducer;