import { createContext, useContext, useState } from "react";

const AddCompanyContext = createContext(null);

export const AddCompanyProvider = ({ children }) => {
  const [openAddCompanyModal, setOpenAddCompanyModal] = useState(false);

  return (
    <AddCompanyContext.Provider value={{ openAddCompanyModal, setOpenAddCompanyModal }}>
      {children}
    </AddCompanyContext.Provider>
  );
};

export const useAddCompanyModal = () => {
  const context = useContext(AddCompanyContext);
  if (!context) {
    throw new Error("useAddCompanyModal must be used within a AddCompanyProvider");
  }
  return context;
};