import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLoaderData } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { companiesByName, getDashboardPreferences, getTenant } from "./graphql/queries";
import { createCompany, updateCompany } from "./graphql/mutations";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import greenSvg from './Assets/Images/greenCheck.svg'
import FileUploadModal from "./FileUploadModal";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAddCompanyModal } from "./context/addCompanyModalContext";
import bgArc from "./Assets/Images/bg-arc.svg"
import RiskDistributionDiagram from "./RiskDistributionDiagram/RiskDistributionDiagram";
import RecommendationTable from "./RecommendationTable/RecommendationTable";
import { triggerSendEvaluationEmail } from "./graphql/queries";
import {
  DataGridPremium,
  GridToolbar,
  GridRowModes,
  GridRowEditStopReasons,
  GridActionsCellItem,
} from "@mui/x-data-grid-premium";
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Card,
  linearProgressClasses,
  LinearProgress,
  Button,
  Menu,
  MenuItem
} from "@mui/material";
import {
  agingTypes,
  countryScores,
  segmentationTypes,
  segmentationTypesEnum,
  underwritingReasons,
} from "./utils/SegmentationTypes";
import {
  colDefCreditLimitForCARBOrCL,
  colDefCreditLimitForCombinedAging,
  colDefCreditLimitForCountryScore,
  colDefCreditLimitForRN,
  colDefCreditLimitForRS,
  colDefCreditLimitForRecommendedLimit,
  colDefCreditLimitForScore,
  colDefCreditLimitForUnderwriting,
  colDefCreditLimitForUnderwritingReason,
  colDefRiskyCustomer,
} from "./utils/DataGridDef";
import {
  getTotalCombinedAgeCL,
  getTotalSegmentedOnCARBOrCL,
  getTotalSegmentedOnRNOrCN,
  getTotalSegmentedOnRS,
  getTotalSegmentedOnRecommendedLimit,
  getTotalSegmentedOnScore,
  getTotalSegmentedOnUWROrCS,
  getTotalSegmentedOnUnderwriting,
} from "./utils/Helper";
import { getRangeForRiskyCustomer } from "./utils/GetRangeForRIskyCustomer";
import {
  getRiskyCustomerAging,
  getRiskyCustomerCARBOrCL,
  getRiskyCustomerCombinedAging,
  getRiskyCustomerCountryScore,
  getRiskyCustomerRNOrCN,
  getRiskyCustomerRecommendedLimit,
  getRiskyCustomerRiskSegment,
  getRiskyCustomerScore,
  getRiskyCustomerUnderwriting,
  getRiskyCustomerUnderwritingReason,
} from "./utils/HelperRC";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import CreditLimitBarChartProspects from "./CreditLimitBarChartProspects"
import {
  CreateDashboardTablePreferences,
  updateDashboardTablePreferences,
} from "./utils/GraphQLHelper/DashboardTable";
import {
  CreditLimitColumnModal,
  RiskyCustomerColumnModal,
  TableEnum,
} from "./utils/GraphQLHelper/TableEnum";
import CreateCompanyModal from "./CreateCompanyModal";
import RequestManualEvaluationDialog from "./RequestManualEvaluationDialog";
import AWS from 'aws-sdk';
import awsExports from "./aws-exports";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useSelector } from "react-redux";

Amplify.configure(awsExports);

AWS.config.update(
  {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  }
);

// Configure Amplify
Amplify.configure(awsconfig);

const CreateColumnHeader = (headerTitle) => {
  return (
    <Typography variant="caption" fontWeight="bold">
      {headerTitle}
    </Typography>
  );
};

export default function ProspectsPage() {

  let { summary } = useLoaderData();

  const dataWithIds = summary?.map((obj, index) => {
    const { id, ...rest } = obj;

    return {
      id: index + 1,
      ...rest
    }
  })

  const bottomRef = useRef(null);
  const [tenantId,setTenantId] = useState("some")
  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenant  = useSelector(state => state.userInfo.tenantId);
  const selectTenantId =()=>{
    if(isAdmin) setTenantId(URLTenant)
    else setTenantId(currentTenant)
  }

  //Get rows without Aging data only
  let ProspectsRows = dataWithIds;
  ProspectsRows = ProspectsRows.filter((row) => (
    (row["AR Balance Aging 30"] === null || row["AR Balance Aging 30"] === 0 || row["AR Balance Aging 30"] === "") &&
    (row["AR Balance Aging 60"] === null || row["AR Balance Aging 60"] === 0 || row["AR Balance Aging 60"] === "") &&
    (row["AR Balance Aging 90"] === null || row["AR Balance Aging 90"] === 0 || row["AR Balance Aging 90"] === "") &&
    (row["AR Balance Aging 90+"] === null || row["AR Balance Aging 90+"] === 0 || row["AR Balance Aging 90+"] === "") &&
    (row["Current AR Balance"] === null || row["Current AR Balance"] === 0 || row["Current AR Balance"] === "") &&
    (row["Total AR Balance"] === null || row["Total AR Balance"] === 0 || row["Total AR Balance"] === "")
  ));
  let SummaryRows = dataWithIds;
  SummaryRows = SummaryRows.filter(
    (row) =>
      (row["AR Balance Aging 30"] !== null &&
        row["AR Balance Aging 30"] !== 0 &&
        row["AR Balance Aging 30"] !== "") ||
      (row["AR Balance Aging 60"] !== null &&
        row["AR Balance Aging 60"] !== 0 &&
        row["AR Balance Aging 60"] !== "") ||
      (row["AR Balance Aging 90"] !== null &&
        row["AR Balance Aging 90"] !== 0 &&
        row["AR Balance Aging 90"] !== "") ||
      (row["AR Balance Aging 90+"] !== null &&
        row["AR Balance Aging 90+"] !== 0 &&
        row["AR Balance Aging 90+"] !== "") ||
      (row["Current AR Balance"] !== null &&
        row["Current AR Balance"] !== 0 &&
        row["Current AR Balance"] !== "") ||
      (row["Total AR Balance"] !== null &&
        row["Total AR Balance"] !== 0 &&
        row["Total AR Balance"] !== "")
  );

  const [selectedOptionRC, setSelectedOptionRC] = useState({
    id: 8,
    label: "Recommended Limit",
  });
  const [rows, setRows] = useState(ProspectsRows);
  const [toggleS3, setToggleS3] = useState(undefined);
  const [selectedRangeRC, setSelectedRangeRC] = useState({
    id: 0,
    label: "All",
  });

  const [range, setRange] = useState([]);

  const [companyInfo, setCompanyInfo] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [fullName, setFullName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [comment, setComment] = useState("");
  const [specificCreditLimit, setSpecificCreditLimit] = useState("");
  let limit = "";

  const handleSetCompanyInfo = (value) => {
    setCompanyInfo(value);
  };

  const setCompanyInfoToNull = () => {
    setCompanyInfo(null);
  };

  function updateFields(data) {
    const fieldsToUpdate = [
      "Current Limit",
      "Current AR Balance",
      "AR Balance Aging 30",
      "AR Balance Aging 60",
      "AR Balance Aging 90",
      "AR Balance Aging 90+",
      "Requested Limit",
      "Recommended Limit",
      "Total AR Balance",
    ];

    const fieldsToModify = ["Recommended Net", "Current Net"];

    fieldsToUpdate.forEach((field) => {
      if (data[field] === "-" || data[field] === "") {
        data[field] = null;
      }
    });

    fieldsToModify.forEach((field) => {
      if (
        data[field] === "NET 30" ||
        data[field] === "NET 45" ||
        data[field] === "NET 60" ||
        data[field] === "NET 90" ||
        data[field] === "NET 120" ||
        data[field] === "NET 180"
      ) {
        let formattedValue = data[field].toLowerCase();
        formattedValue =
          formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
        data[field] = formattedValue;
      }
    });
  }

  rows?.forEach(updateFields);

  const [selectedOptionCL, setSelectedOptionCL] = useState({
    id: 8,
    label: "Recommended Limit",
  });

  const columnDefinition = [];
  const excludedFields = ["AR Balance Aging 30", "AR Balance Aging 60", "AR Balance Aging 90", "AR Balance Aging 90+", "Current AR Balance", "Total AR Balance"]
  const filteredColumns = colDefRiskyCustomer.filter(column => !excludedFields.includes(column.field));
  const colDefWithWidth = filteredColumns.map(column => ({
    ...column,
    width: column.field === 'rank' ? 80 : 140,// Set the desired width for each column
  }));

  const dateTimeSortComparator = (date1, date2) => {
    if (!date1 && !date2) return 0; // Both dates are null
    if (!date1) return 1; // 'date1' is null, 'date2' comes first
    if (!date2) return -1; // 'date2' is null, 'date1' comes first

    return new Date(date1) - new Date(date2);
  };

  const columns = getUniqueKeys(rows).filter(
    (column) => column !== "" && column !== "id" && column !== "AR Balance Aging 30" && column !== "AR Balance Aging 60" && column !== "AR Balance Aging 90" && column !== "AR Balance Aging 90+" && column !== "Current AR Balance" && column !== "Total AR Balance"
  );

  columns.forEach((column) => {
    const definition = {
      field: column,
      headerName: column,
      width: 120,
      hide: false,
      renderHeader: (params) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
    };

    if (typeof rows[0][column] === "number") {
      definition.type = "number";
    }
    if (column === "Requested Limit") {
      definition.editable = true;
    }
    if (column === "Current Net") {
      definition.editable = true;
    }
    if (column === "Current Limit") {
      definition.editable = true;
    }
    if (column === "Last Update Time") {
      definition.sortComparator = dateTimeSortComparator;
    }

    columnDefinition.push(definition);
  });
  const actionsColumnDefinition = {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    hide: false,
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  };

  const ManualEvaluationButton = {
    field: "toggle",
    headerName: "Add Manual Evaluation",
    width: 130,
    renderCell: (params) => {
      const buttonStyle = {
        backgroundColor: "#2196F3", // Blue color
        color: "#fff", // White text color
        borderRadius: "5px", // Rounded corners
        padding: "8px 16px", // Padding for better appearance
        cursor: "pointer", // Cursor style on hover
        border: "none", // No border
        outline: "none", // Remove outline on focus
      };
      return (
        <button
          style={buttonStyle}
          onClick={() => handleManualEvaluationButtonClick(params)}
        >
          Add
        </button>
      );
    },
  };

  columnDefinition.push(actionsColumnDefinition);
  columnDefinition.push(ManualEvaluationButton);

  const handleManualEvaluationButtonClick = async (params) => {
    setDialogOpen(true);
    setCompanyName(params.row["Company Name"]);
  };

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  }

  const handleCommentsChange = (event) => {
    setComment(event.target.value);
  };

  const handleSpecificCreditLimitValue = (event) => {
    setSpecificCreditLimit(event.target.value);
  };

  const handleOptionChange = (event) => {
    console.log("Limit", limit);
    setSelectedOption(event.target.value);
  };

  const handleCloseDialog = () => {
    limit = "";
    setSpecificCreditLimit("");
    setComment("");
    setSelectedOption("");
    setDialogOpen(false);
    setCompanyName("");
    setCompanyName("");
    setFullName("");
    setTitle("");
    setEmail("");
    setPhoneNumber("");
  };

  const handleSubmit = async (row) => {
    console.log("Selected Option:", selectedOption);

    if (selectedOption === "Specific Credit Limit") {
      console.log("Specific credit limit:", specificCreditLimit);
      limit = specificCreditLimit;
    } else {
      limit = "Max";
    }

    let result = await API.graphql(
      graphqlOperation(companiesByName, {
        name: companyName,
        limit: 1,
        filter: {
          tenantId: {
            eq: tenantId,
          },
        },
      })
    );

    const companyid = result.data.companiesByName.items[0].id;
    let tenant = await API.graphql(
      graphqlOperation(getTenant, {
        id: tenantId,
      })
    );

    const tenantName = tenant.data.getTenant.name;
    console.log("tenant id", tenantId);
    console.log("tenant name:", tenantName);
    console.log("Company id:", companyid);
    console.log("company name:", companyName);
    console.log("limit:", limit);
    console.log("comments:", comment);

    await API.graphql(
      graphqlOperation(triggerSendEvaluationEmail, {
        tenantId: tenantId,
        tenantName: tenantName,
        id: companyid,
        companyName: companyName,
        requestedAmount: limit,
        comments: comment,
        fullName: fullName,
        title: title,
        email: email,
        phoneNumber: phoneNumber
      })
    );

    handleCloseDialog();
  };

  const initialColumnVisibilityModel = Object.fromEntries(
    columnDefinition.map((column) => [column.field, !column.hide])
  );

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initialColumnVisibilityModel
  );

  const [creditLimitCVM, setCreditLimitCVM] = useState(CreditLimitColumnModal);
  const [riskyCustomerCVM, setRiskyCustomerCVM] = useState(
    RiskyCustomerColumnModal
  );
  const getRowId = (row) => {
    const timestamp = new Date().getTime();
    const randomNum = Math.random() * 10000; // Adjust the range as per your needs
    const uniqueId = `${timestamp}-${randomNum}`;
    return uniqueId;
  };

  const handleCellClick = async (params) => {
    if (params.field === "Company Name") {
      let nextToken = null;
      let items = []
      let result;
      do {
        result = await API.graphql(
          graphqlOperation(companiesByName, {
            name: params.row["Company Name"],
            limit: 1,
            filter: {
              tenantId: {
                eq: tenantId,
              },
            },
            nextToken: nextToken,
          })
        );

        items = result.data.companiesByName.items;
        nextToken = result.data.companiesByName.nextToken;

        if (items.length > 0) {
          break;
        }
      } while (nextToken != null);

      let company = null;
      if (items.length === 0) {
        try {
          result = await API.graphql(
            graphqlOperation(createCompany, {
              input: {
                name: params.row["Company Name"],
                tenantId: tenantId,
              },
            })
          );
        } catch (error) {
          console.log(error);
        }
        company = result.data.createCompany;
      } else {
        company = items[0];
      }
      window.open(`/company/${company.id}`, "_blank");
    }
  };

  const handleColumnVisibilityModelChange = async (model, details) => {
    console.log("Column Visibility Model:", model);
    setColumnVisibilityModel(model);
    const preferenceData = {
      feature: TableEnum.PROSPECT_SUMMARY_TABLE,
      preferences: JSON.stringify(model),
    };
    updateDashboardTablePreferences(preferenceData);
  };

  const handleCVMChangeForCreditLimit = async (model, details) => {
    console.log("Column Visibility Model:", model);
    const temp = { ...creditLimitCVM };
    temp[selectedOptionCL.label] = model;

    setCreditLimitCVM(temp);
    const preferenceData = {
      feature: TableEnum.PROSPECT_CREDIT_LIMIT,
      preferences: JSON.stringify(temp),
    };
    updateDashboardTablePreferences(preferenceData);
  };

  const handleCVMChangeForRiskyCustomer = async (model, details) => {
    console.log("Column Visibility Model:", model);
    const temp = { ...riskyCustomerCVM };
    temp[selectedOptionRC.label] = model;

    setRiskyCustomerCVM(temp);
    const preferenceData = {
      feature: TableEnum.PROSPECT_RISKY_CUSTOMER,
      preferences: JSON.stringify(temp),
    };
    updateDashboardTablePreferences(preferenceData);
  };

  function getUniqueKeys(arrayOfObjects) {
    let uniqueKeys = new Set();
    arrayOfObjects?.forEach((obj) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          uniqueKeys.add(key);
        }
      }
    });
    return Array.from(uniqueKeys);
  }

  const handleDropdownChange = (event, newValue) => {
    if (newValue) {
      setSelectedOptionCL(newValue);
    }
  };

  const handleDropdownChangeForRC = (event, newValue) => {
    if (newValue) {
      setSelectedOptionRC(newValue);
      const rangeArray = getRangeForRiskyCustomer(newValue.id);
      setRange(rangeArray);
      setSelectedRangeRC({ id: 0, label: "All" });
    }
  };

  const handleRangeChangeRC = (event, newValue) => {
    if (newValue) {
      setSelectedRangeRC(newValue);
    }
  };

  const [rowModesModel, setRowModesModel] = React.useState({});
  const [showAtRisk,setShowAtRisk] = useState(false)
  const scrollToTable = useCallback(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const uploadJsonToS3 = async () => {
    try {
      const mergedRows = rows.concat(SummaryRows);
      const jsonString = JSON.stringify(mergedRows);

      const key = "public/summary_" + tenantId + ".json";
      const s3 = new AWS.S3();

      const result = await s3.putObject({
        Bucket: 'crediarc-content01853-production', // Specify your bucket name
        Key: key,
        Body: jsonString,
        ContentType: 'application/json',
      }).promise();

      console.log("File uploaded successfully", result);
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleChange = async (row) => {
    try {
      let companyNameValue = row["Account ID"];
      let result = await API.graphql(
        graphqlOperation(companiesByName, {
          name: companyNameValue,
          limit: 1,
          filter: {
            tenantId: {
              eq: tenantId,
            },
          },
        })
      );
      let company = null;
      let requestedCreditLimit = row["Requested Limit"];
      company = result.data.companiesByName.items[0];
      await API.graphql(
        graphqlOperation(updateCompany, {
          input: {
            tenantId: tenantId,
            id: company.id,
            requestedCreditLimit: parseFloat(requestedCreditLimit),
            view: 1,
          },
        })
      );
      company = result.data.updateCompany;
      console.log("updated");
    } catch (error) {
      console.log("error while saving file", error);
    }
  };

  const handleSaveClick = (id) => () => {
    const foundItem = rows.find((item) => item.id === id);
    handleChange(foundItem);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    const isConfirmed = window.confirm("Are you sure you want to delete?");
    if (isConfirmed) {
      setRows(rows.filter((row) => row.id !== id));
      setToggleS3(!toggleS3);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
      setToggleS3(!toggleS3);
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    console.log("Process Row Update", updatedRow);
    setToggleS3(!toggleS3);
    handleChange(updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const checkAndFetchPreferencesForCreditLimit = async () => {
    try {
      const feature = TableEnum.PROSPECT_CREDIT_LIMIT;
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, { tenantId, feature })
      );

      if (result.data.getDashboardPreferences) {
        const existingPreferences =
          result.data.getDashboardPreferences.preferences;
        console.log("Item exists:", JSON.parse(existingPreferences));
        setCreditLimitCVM(JSON.parse(existingPreferences));
      } else {
        const payload = {
          feature: TableEnum.PROSPECT_CREDIT_LIMIT,
          preferences: JSON.stringify(creditLimitCVM),
        };
        CreateDashboardTablePreferences(payload);
      }
    } catch (error) {
      console.error("Error checking and fetching preferences:", error);
    }
  };

  const checkAndFetchPreferencesForRiskyCustomer = async () => {
    try {
      const feature = TableEnum.PROSPECT_RISKY_CUSTOMER;
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, { tenantId, feature })
      );

      if (result.data.getDashboardPreferences) {
        const existingPreferences =
          result.data.getDashboardPreferences.preferences;
        console.log("Item exists:", JSON.parse(existingPreferences));
        setRiskyCustomerCVM(JSON.parse(existingPreferences));
      } else {
        const payload = {
          feature: TableEnum.PROSPECT_RISKY_CUSTOMER,
          preferences: JSON.stringify(riskyCustomerCVM),
        };
        CreateDashboardTablePreferences(payload);
      }
    } catch (error) {
      console.error("Error checking and fetching preferences:", error);
    }
  };

  const checkAndFetchPreferencesForSummaryTable = async () => {
    try {
      const feature = TableEnum.PROSPECT_SUMMARY_TABLE;
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, { tenantId, feature })
      );

      if (result.data.getDashboardPreferences) {
        const existingPreferences =
          result.data.getDashboardPreferences.preferences;
        console.log("Item exists:", JSON.parse(existingPreferences));
        setColumnVisibilityModel(JSON.parse(existingPreferences));
      } else {
        const payload = {
          feature: TableEnum.PROSPECT_SUMMARY_TABLE,
          preferences: JSON.stringify(initialColumnVisibilityModel),
        };
        CreateDashboardTablePreferences(payload);
      }
    } catch (error) {
      console.error("Error checking and fetching preferences:", error);
    }
  };

  useEffect(() => {
    selectTenantId();
    checkAndFetchPreferencesForSummaryTable();
    checkAndFetchPreferencesForCreditLimit();
    checkAndFetchPreferencesForRiskyCustomer();
  }, []);

  useEffect(() => {
    if (toggleS3 !== undefined) {
      uploadJsonToS3();
    }
  }, [!toggleS3]);

  let gridRowsCL = [];
  let gridColumnsCL = [];

  const allowedScoreSegment = [
    "Alternative Score",
    "Legal Score",
    "General Risk Score",
    "Failure Score",
    "Delinquency Score",
  ];

  if (allowedScoreSegment.includes(selectedOptionCL?.label)) {
    gridRowsCL = getTotalSegmentedOnScore(rows, selectedOptionCL?.label);
    gridColumnsCL = colDefCreditLimitForScore;
  } else if (
    selectedOptionCL?.label === "Recommended Net" ||
    selectedOptionCL?.label === "Current Net"
  ) {
    gridRowsCL = getTotalSegmentedOnRNOrCN(rows, selectedOptionCL?.label);
    gridColumnsCL = colDefCreditLimitForRN;
  } else if (selectedOptionCL?.label === "Underwriting") {
    gridRowsCL = getTotalSegmentedOnUnderwriting(rows);
    gridColumnsCL = colDefCreditLimitForUnderwriting;
  } else if (selectedOptionCL?.label === "Recommended Limit") {
    gridRowsCL = getTotalSegmentedOnRecommendedLimit(rows);
    gridColumnsCL = colDefCreditLimitForRecommendedLimit;
  } else if (
    selectedOptionCL?.label === "Current AR Balance" ||
    selectedOptionCL?.label === "Current Limit"
  ) {
    gridRowsCL = getTotalSegmentedOnCARBOrCL(rows, selectedOptionCL?.label);
    gridColumnsCL = colDefCreditLimitForCARBOrCL;
  } else if (selectedOptionCL?.label === "Risk Segment") {
    gridRowsCL = getTotalSegmentedOnRS(rows);
    gridColumnsCL = colDefCreditLimitForRS;
  } else if (selectedOptionCL?.label === "Combined Aging") {
    gridRowsCL = getTotalCombinedAgeCL(rows, "combined");
    gridColumnsCL = colDefCreditLimitForCombinedAging;
  } else if (selectedOptionCL?.label === "Country Score") {
    gridRowsCL = getTotalSegmentedOnUWROrCS(
      rows,
      selectedOptionCL?.label,
      countryScores
    );
    gridColumnsCL = colDefCreditLimitForCountryScore;
  } else if (selectedOptionCL?.label === "Underwriting Reason") {
    gridRowsCL = getTotalSegmentedOnUWROrCS(
      rows,
      selectedOptionCL?.label,
      underwritingReasons
    );
    gridColumnsCL = colDefCreditLimitForUnderwritingReason;
  } else if (
    selectedOptionCL?.label === agingTypes.AGINNG30.label ||
    selectedOptionCL?.label === agingTypes.AGINNG60.label ||
    selectedOptionCL?.label === agingTypes.AGINNG90.label ||
    selectedOptionCL?.label === agingTypes.AGINNG90PLUS.label
  ) {
    gridRowsCL = getTotalCombinedAgeCL(rows, selectedOptionCL?.label);
    gridColumnsCL = colDefCreditLimitForCombinedAging;
  }

  const conditionToRemove = (row) => row.metric === "Utilization %" ||
    row.metric === "Total $ value of used credit limit" ||
    row.metric === "Average Used Limit";
  const filteredRows = gridRowsCL.filter((row) => !conditionToRemove(row));

  function calculateSortingScore(
    riskSegment,
    failureScore,
    alternativeScore,
    delinquencyScore
  ) {
    let sum = 0;
    let denominator = 0;

    if (riskSegment !== undefined) {
      sum += 5 * riskSegment;
      denominator += 5;
    }

    if (failureScore !== undefined) {
      sum += 3 * failureScore;
      denominator += 3;
    }

    if (alternativeScore !== undefined) {
      sum += 2 * alternativeScore;
      denominator += 2;
    }

    if (delinquencyScore !== undefined) {
      sum += 2 * delinquencyScore;
      denominator += 2;
    }

    if (denominator !== 0) {
      return sum / denominator;
    } else {
      // Handle the case where all scores are missing
      console.error(
        "All scores are missing. Unable to calculate sorting score."
      );
      return undefined;
    }
  }

  const getTop10RiskyCustomer = (data) => {
    const processedData = data.map((item) => {
      const riskSegment = parseFloat(item["Risk Segment"]);
      const failureScore = parseFloat(item["Failure Score"]);
      const alternativeScore = parseFloat(item["Alternative Score"]);
      const delinquencyScore = parseFloat(item["Delinquency Score"]);

      // Check for NaN and set SortingScore accordingly
      const sortingScore = calculateSortingScore(
        isNaN(riskSegment) ? undefined : riskSegment,
        isNaN(failureScore) ? undefined : failureScore,
        isNaN(alternativeScore) ? undefined : alternativeScore,
        isNaN(delinquencyScore) ? undefined : delinquencyScore
      );

      return { ...item, SortingScore: sortingScore };
    });

    const sortedData = processedData.slice().sort((a, b) => {
      const scoreA = a.SortingScore || 0;
      const scoreB = b.SortingScore || 0;

      return scoreB - scoreA;
    });

    const top10RiskyCompanies = sortedData.slice(0, 10).map((item, index) => ({
      ...item,
      Rank: index + 1,
    }));

    return top10RiskyCompanies;
  };

  let gridRowsRC = [];
  if (selectedOptionRC.id === segmentationTypesEnum.RECOMMENDED_LIMIT.id) {
    const dataArray = getRiskyCustomerRecommendedLimit(
      rows,
      selectedRangeRC.id
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (
    selectedOptionRC.id === segmentationTypesEnum.RECOMMENDED_NET.id ||
    selectedOptionRC.id === segmentationTypesEnum.CURRENT_NET.id
  ) {
    const dataArray = getRiskyCustomerRNOrCN(
      rows,
      selectedRangeRC.id,
      selectedOptionRC.label
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (selectedOptionRC.id === segmentationTypesEnum.UNDERWRITING.id) {
    const dataArray = getRiskyCustomerUnderwriting(rows, selectedRangeRC.id);
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (allowedScoreSegment.includes(selectedOptionRC?.label)) {
    const dataArray = getRiskyCustomerScore(
      rows,
      selectedRangeRC.id,
      selectedOptionRC?.label
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (
    selectedOptionRC.id === segmentationTypesEnum.ARB_AGING30.id ||
    selectedOptionRC.id === segmentationTypesEnum.ARB_AGING60.id ||
    selectedOptionRC.id === segmentationTypesEnum.ARB_AGING90.id ||
    selectedOptionRC.id === segmentationTypesEnum.ARB_AGING90PLUS.id
  ) {
    const dataArray = getRiskyCustomerAging(
      rows,
      selectedRangeRC.id,
      selectedOptionRC.label
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (selectedOptionRC.id === segmentationTypesEnum.COMBINED_AGING.id) {
    const dataArray = getRiskyCustomerCombinedAging(rows, selectedRangeRC.id);
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (
    selectedOptionRC.id === segmentationTypesEnum.CARB.id ||
    selectedOptionRC.id === segmentationTypesEnum.CURRENT_LIMIT.id
  ) {
    const dataArray = getRiskyCustomerCARBOrCL(
      rows,
      selectedRangeRC.id,
      selectedOptionRC.label
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (selectedOptionRC.id === segmentationTypesEnum.RISK_SEGMENT.id) {
    const dataArray = getRiskyCustomerRiskSegment(rows, selectedRangeRC.id);
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (selectedOptionRC.id === segmentationTypesEnum.COUNTRY_SCORE.id) {
    const dataArray = getRiskyCustomerCountryScore(rows, selectedRangeRC.id);
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (
    selectedOptionRC.id === segmentationTypesEnum.UNDERWRITING_REASON.id
  ) {
    const dataArray = getRiskyCustomerUnderwritingReason(
      rows,
      selectedRangeRC.id
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  }

  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between', // Ensures space between the boxes
    alignItems: 'center', // Centers items vertically
    gap: '16px',
    paddingTop: '20px',
    marginLeft : '0px',
    width : '100%'
  };

  const bgStyle={
    width:"100%",
    height:"100%",
    background: `transparent url(${bgArc}) 0% 0% no-repeat padding-box`,
    // opacity: "0.03",
    position:'absolute',
    top:0,
    left:0,
    zIndex:0
  }

  const getImage = (score) => {
    if (score >= 1 && score < 4) {
    return '/greenSmiley.svg';
    } else if (score >= 4 && score < 7) {
    return '/yellowSmiley.svg';
    } else {
    return '/redSmiley.svg';
    } 
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
      boxShadow: 'inset 0px 1px 3px grey, 0px 3px 6px white'
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#60AC50' : '#308fe8',
      boxShadow:"inset 0px 3px 6px #00000029"
    },
  }));

  const handleBottomClick = (e) => {
    e.preventDefault();
    setShowAtRisk(true);
    setTimeout(scrollToTable, 50);
  };

  const countRowsAtRisk = () => {
    return rows.filter(row => row['# Risk ind'] !== 0).length;
  };

  const formatNumber = (amount) => {
    return amount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }; 

  const calculateMoneyAtRisk = () => {
    return rows
      .filter(row => row['# Risk ind'] !== 0)
      .reduce((total, row) => total + row['Total AR Balance'], 0);
  };

  const existingCustomers = rows.length;
  const customersAtRisk = countRowsAtRisk();
  const moneyExposedToRisk = calculateMoneyAtRisk();
  const [fileModalOpen, setFileModalOpen] = useState(false)


  const handleLoadAgingFiles = () => {
    setFileModalOpen(true);
  };

  const DropdownMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isExpandDocuments, setExpandDocuments] = useState(false);
    const [isExpandMitigations, setExpandMitigation] = useState(false);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleExpandDocuments = () => {
      setExpandDocuments(!isExpandDocuments);
    };
  
    const handleExpandMitigations = () => {
      setExpandMitigation(!isExpandMitigations);
    };
  
    return (
      <div style={{ textAlign: 'center' }}>
      <Button
        variant="contained"
        onClick={handleClick}
        style={{
          textTransform: 'none',
          fontFamily: 'Rubik, sans-serif',
          backgroundColor: '#5186EC',
          height: '25px',
          padding: '0px 1px 0px 5px',
          borderRadius:"8px"
        }}
      >
        <Typography sx={{ fontSize: '12px' }}>How to lower the risk?</Typography>
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null} // Ensures menu is aligned to top
      >
        <Typography
          variant="subtitle1"
          onClick={handleExpandDocuments}
          style={{
            marginLeft: '15px',
            marginTop: '10px',
            marginRight: '10px',
            fontFamily: 'Rubik, sans-serif',
            fontWeight: 450,
            cursor: 'pointer'
          }}
        >
          Request Customer Documents
        </Typography>
        {isExpandDocuments && (
          <>
            <MenuItem onClick={handleClose} style={{ paddingLeft: '20px' }}>Financial statement</MenuItem>
            <hr/>
            <MenuItem onClick={handleClose} style={{ paddingLeft: '20px' }}>Current account statement</MenuItem>
            <hr/>
            <MenuItem onClick={handleClose} style={{ paddingLeft: '20px' }}>Deposit and Saving statement</MenuItem>
            <hr/>
          </>
        )}
        <Typography
          variant="subtitle1"
          onClick={handleExpandMitigations}
          style={{
            marginLeft: '15px',
            marginTop: '10px',
            marginRight: '10px',
            fontFamily: 'Rubik, sans-serif',
            fontWeight: 450,
            cursor: 'pointer'
          }}
        >
          Risk Mitigations
        </Typography>
        {isExpandMitigations && (
          <>
            <MenuItem onClick={handleClose} style={{ paddingLeft: '20px' }}>Comfort letter</MenuItem>
            <hr/>
            <MenuItem onClick={handleClose} style={{ paddingLeft: '20px' }}>Parent company guarantee</MenuItem>
            <hr/>
            <MenuItem onClick={handleClose} style={{ paddingLeft: '20px' }}>Owners' guarantee</MenuItem>
            <hr/>
            <MenuItem onClick={handleClose} style={{ paddingLeft: '20px' }}>Government guarantee</MenuItem>
            <hr/>
            <MenuItem onClick={handleClose} style={{ paddingLeft: '20px' }}>Insurance</MenuItem>
            <hr/>
            <MenuItem onClick={handleClose} style={{ paddingLeft: '20px' }}>Collateral</MenuItem>
          </>
        )}
      </Menu>
    </div>
    );
  };

  const totalAmount = ProspectsRows.reduce((sum, row) => sum + (row['Recommended Limit'] || 0), 0);
  const {openAddCompanyModal,setOpenAddCompanyModal}=useAddCompanyModal()


  return (
    <>
      <RiskDistributionDiagram rowData={rows} />
      <RecommendationTable rows={rows} showAtRisk={showAtRisk} setShowAtRisk={setShowAtRisk} riskyRows={gridRowsRC} />
      <Box ref={bottomRef} id="bottom" sx={{ height: '1px' }} /> 
      {/* <div
        style={{
          width: "100%",
          marginTop: 30,
          display: "flessasdx",
          flexDirection: 'row-reverse'
        }}
      >
      </div>
      <div
        style={{
          width: "100%",
          height: "85vh",
          marginTop: 80,
        }}
      >
        <Typography variant="h5" mb={5}>
          Summary & Recommendations
        </Typography>
        <DataGridPremium
          sx={{
            ".Mui-hovered": {
              cursor: "pointer",
            },
          }}
          onCellClick={handleCellClick}
          //getRowId={getRowId}
          rows={rows}
          columns={columnDefinition}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          columnVisibilityModel={columnVisibilityModel || {}}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          // autosizeOnMount={true}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[5, 10, 50]}
        />
      </div>
      <div style={{ width: "100%", height: "85vh", marginTop: 100 }}>
        <Typography variant="h5" mb={2}>
          10 Most Risky Customers
        </Typography>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Autocomplete
            size="small"
            sx={{ width: 250, my: 5 }}
            value={selectedOptionRC}
            onChange={handleDropdownChangeForRC}
            options={segmentationTypes}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Segmentation Type"
                variant="outlined"
              />
            )}
          />
          <Autocomplete
            size="small"
            sx={{ width: 250, my: 5 }}
            value={selectedRangeRC}
            onChange={handleRangeChangeRC}
            options={range}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Segmentation Range"
                variant="outlined"
              />
            )}
          />
        </Box>
        <DataGridPremium
          getRowId={getRowId}
          rows={gridRowsRC}
          autosizeOnMount={true}
          columnVisibilityModel={riskyCustomerCVM[selectedOptionRC.label] || {}}
          onColumnVisibilityModelChange={handleCVMChangeForRiskyCustomer}
          columns={colDefWithWidth}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[5, 10, 50]}
        />
      </div>
      <div style={{ width: "100%", height: "40vh", marginTop: 200 }}>
        <Typography variant="h5" mb={5}>
          Credit Limit
        </Typography>
        <Autocomplete
          size="small"
          sx={{ width: 250, my: 5 }}
          value={selectedOptionCL}
          onChange={handleDropdownChange}
          options={segmentationTypes}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Segmentation Type"
              variant="outlined"
            />
          )}
        />
        <DataGridPremium
          rows={filteredRows}
          columns={gridColumnsCL}
          // autosizeOnMount={true}
          columnVisibilityModel={creditLimitCVM[selectedOptionCL.label] || {}}
          onColumnVisibilityModelChange={handleCVMChangeForCreditLimit}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[5, 10, 50]}
        />
      </div>
      <div style={{ width: "100%", marginTop: 200 }}>
        <CreditLimitBarChartProspects summary={rows} />
      </div>
      <div>
        <RequestManualEvaluationDialog
          title={title}
          comment={comment}
          email={email}
          fullName={fullName}
          phoneNumber={phoneNumber}
          isDialogOpen={isDialogOpen}
          selectedOption={selectedOption}
          specificCreditLimit={specificCreditLimit}
          handleTitleChange={handleTitleChange}
          handleCloseDialog={handleCloseDialog}
          handleCommentsChange={handleCommentsChange}
          handleEmailChange={handleEmailChange}
          handleFullNameChange={handleFullNameChange}
          handleOptionChange={handleOptionChange}
          handlePhoneNumberChange={handlePhoneNumberChange}
          handleSpecificCreditLimitValue={handleSpecificCreditLimitValue}
          handleSubmit={handleSubmit}
        />
      </div> */}
    </>
  );
}
