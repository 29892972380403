import { Box, Typography } from "@mui/material";
import React from "react";

function CompanySummary({ company }) {
  var companyData = new Array(10).fill("");
  if (company && company.summary) {
    companyData = company.summary.split("\\n");
  }

  if (!company.summary) {
    return (
      <Box padding={'25px'}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {"Company Summary"}
        </Typography>
        <Typography>{"Company Summary Not Available!"}</Typography>
      </Box>
    );
  }

  const getSignificantEvents = (summary) => {
    if (!summary) return [];
    if (
      summary.includes("No significant events were reported at this time.") ||
      summary.includes("No significant events or news were reported at this time.") ||
      summary.includes("No news available.") ||
      summary.includes("No significant events found.")
    ) {
      return [];
    }
    const lines = summary.split("\\n");
    const significantEventsIndex = lines.findIndex(line => line.trim().toUpperCase().includes("3. SIGNIFICANT EVENTS"));
  
    if (significantEventsIndex === -1) return [];
  
    const events = [];
    for (let i = significantEventsIndex + 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line.startsWith("1.") || line.startsWith("2.") || line.startsWith("4.")) {
        break;
      }
      // Remove leading dashes and extra spaces
      const cleanedLine = line.replace(/^\s*-\s*/, '');
      if (cleanedLine) {
        events.push(cleanedLine.replace(/\"$/, ''));
      }
    }
  
    return events;
  };
  
  

  const significantEvents = getSignificantEvents(company?.summary);

  const getCompanyInfo = (summary) => {
    if (!summary) return "";

    const lines = summary.split("\\n");
    const companyInfoIndex = lines.findIndex(line => line.trim().toUpperCase().includes("1. COMPANY INFO"));

    if (companyInfoIndex === -1 || companyInfoIndex + 1 >= lines.length) return "";

    let infoLines = [];
    for (let i = companyInfoIndex + 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line.startsWith("1.") || line.startsWith("2.") || line.startsWith("3.") || line.startsWith("4.")) {
        break; 
      }
      infoLines.push(line);
    }

    return infoLines.join(" "); 
  };

  const getRecommendation = (summary) => {
    if (!summary) return "";
  
    const lines = summary.split("\\n");
    const recommendationIndex = lines.findIndex(line => line.trim().toUpperCase().includes("2. RECOMMENDATION"));
  
    if (recommendationIndex === -1 || recommendationIndex + 1 >= lines.length) return "";
  
    let recommendationLines = [];
    for (let i = recommendationIndex + 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line.startsWith("1.") || line.startsWith("3.") || line.startsWith("4.")) {
        break;
      }
      recommendationLines.push(line);
    }
  
    return recommendationLines.join(" ");
  };
  const companyInfo = getCompanyInfo(company?.summary);
  const recommendation = getRecommendation(company?.summary);

  return (
    <Box padding={'25px'}>
      <Typography style={{ textAlign:"left" , fontSize: "18px", color: "#1A2A56", fontWeight: "500" , paddingBottom: "20px"}}> 
        Company Info
      </Typography>
      <Typography style={{ color:"#777A82" , textAlign: "left" , fontSize: "16px",  paddingBottom: "20px" }} >
        {companyInfo}
      </Typography>
      <Typography style={{ textAlign:"left" ,   fontSize: "18px", color: "#1A2A56", fontWeight: "500" ,   paddingBottom: "20px"}}> 
        Recommendation
      </Typography>
      <Typography style={{ color:"#777A82" , textAlign: "left" ,fontSize: "16px",  paddingBottom: "20px" }} >
        {recommendation}
      </Typography>
      <Typography style={{ textAlign:"left" , fontSize: "18px", color: "#1A2A56", fontWeight: "500" , paddingBottom: "20px"}}> 
        Significant Events
      </Typography>
      {significantEvents.length > 0 ? (
        significantEvents.map((event, index) => (
          <Typography
            key={index}
            style={{
              color: "#777A82",
              textAlign: "left",
              fontSize: "16px",
            }}
          >
            {event}
          </Typography>
        ))
      ) : (
        <Typography
          style={{
            color: "#777A82",
            textAlign: "left",
            fontSize: "14px",
            paddingLeft: "17.6px",
          }}
        >
          No significant events found.
        </Typography>
      )}
    </Box>
  );
}

export default CompanySummary;